import { Container, Link, Chip, Box, Typography, Rating } from '@mui/material';
import { IContacts } from 'src/common/interfaces';
import { CardInfoItem, Page } from 'src/components';
import { getPrimaryEmail, getPrimaryPhoneNumber } from '../../functions';
import { EContactStatus, EContactType } from 'src/common/enums';

const getContactTypeColor = (type: EContactType) => {
  switch (type) {
    case EContactType.CLIENT:
      return 'primary';
    case EContactType.LEAD:
      return 'warning';
    case EContactType.OTHER:
      return 'info';

    default:
      return 'secondary';
  }
};

interface IProps {
  contactData: IContacts;
}

export default function ContactInfo({ contactData }: IProps) {
  return (
    <Page title="">
      <CardInfoItem title="First Name" value={contactData.firstName || 'N/A'} />

      <CardInfoItem title="Last Name" value={contactData.lastName || 'N/A'} />

      <CardInfoItem title="Age" value={contactData.age.toString() || 'N/A'} />

      <CardInfoItem title="Gender" value={contactData.gender || 'N/A'} />

      <CardInfoItem title="Email" value="">
        {contactData.emails && contactData.emails.length > 0 ? (
          <Link href={`mailto:${getPrimaryEmail(contactData.emails)}`} target="_blank">
            {getPrimaryEmail(contactData.emails)}
          </Link>
        ) : (
          <span>N/A</span>
        )}
      </CardInfoItem>

      <CardInfoItem
        title="Phone"
        value={getPrimaryPhoneNumber(contactData.phoneNumbers) || 'N/A'}
      />

      <CardInfoItem title="Status" value="">
        <Chip
          label={contactData.status || 'N/A'}
          color={contactData.status === EContactStatus.OPEN ? 'primary' : 'error'}
          size="medium"
        />
      </CardInfoItem>

      <CardInfoItem title="Type" value="">
        <Chip
          label={contactData.type || 'N/A'}
          color={getContactTypeColor(contactData.type as EContactType)}
          size="medium"
        />
      </CardInfoItem>

      <CardInfoItem title="Job Frequency" value="">
        {contactData.jobFrequency ? (
          <Chip label={contactData.jobFrequency} size="medium" />
        ) : (
          <span>N/A</span>
        )}
      </CardInfoItem>

      <CardInfoItem title="Expected Billing" value="">
        {contactData.expectedBillingAmount !== undefined ? (
          <Chip label={contactData.expectedBillingAmount.toString() + ' $'} size="medium" />
        ) : (
          <span>N/A</span>
        )}
      </CardInfoItem>

      <CardInfoItem title="Rating" value="">
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body1">{contactData.rating}</Typography>
          <Rating
            name="rating"
            value={contactData.rating || 0}
            readOnly
            size="small"
            precision={0.5}
          />
        </Box>
      </CardInfoItem>
    </Page>
  );
}
