const base = '/analytics';

const AnalyticsApi = {
  base,
  campaign: base + '/campaign',
  sentEmail: base + '/sent-email',
  sociallink: base + '/redirect-url',
  clickMail: base + '/click-mail',
  getSocial: base + '/get-social',
  campaignTimeline: base + '/campaigngroup-timeline',
  campaignABTesting: base + '/campaign-ab-testing',
  campaignGroupTimeline: (id: any) => base + `/campaign-group/${id}`,
};

export default AnalyticsApi;
