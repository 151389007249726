import { ReactNode, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import Iconify from '../Iconify';

interface CommonMoreMenuProps {
  onDelete?: () => void;
  // onUpdate?: () => void;
  editRootPath?: any;
  id?: string;
  otherOptions?: ReactNode;
}

export default function MoreMenu({
  editRootPath,
  onDelete,
  id,
  otherOptions,
}: CommonMoreMenuProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon={'eva:more-vertical-fill'} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{
          vertical: typeof editRootPath == 'string' ? 'top' : 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={onDelete} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon={'eva:trash-2-outline'} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {editRootPath && typeof editRootPath == 'string' && id ? (
          <MenuItem
            component={RouterLink}
            to={`${editRootPath}/${id}/edit`}
            sx={{ color: 'text.secondary' }}
          >
            <ListItemIcon>
              <Iconify icon={'eva:edit-outline'} />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        ) : (
          <MenuItem
            // component={RouterLink}
            onClick={editRootPath}
            sx={{ color: 'text.secondary' }}
          >
            <ListItemIcon>
              <Iconify icon={'eva:edit-outline'} />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {otherOptions && otherOptions}
      </Menu>
    </>
  );
}
