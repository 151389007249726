import { Link as RouterLink, useSearchParams } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import {
  Button,
  Typography,
  Container,
  Alert,
  TextField,
  Stack,
  MenuItem,
  Grid,
  Card,
  TableContainer,
  TableBody,
  TableCell,
  Table,
  TableRow,
  Divider,
} from '@mui/material';
// components
import { LoadingBox, Page, Scrollbar, TableHeadCustom, TableSkeleton } from 'src/components';
// assets
import { MailBoxIllustration } from 'src/assets';
import { useCallback, useEffect, useState } from 'react';
import { CampaignGroupsApi, MailApi, getApi } from 'src/common/apis';
import toast from 'react-hot-toast';
import AnalyticsApi from 'src/common/apis/analytics.api';
import { IParams } from 'src/@types/params';
import { sentenceCase } from 'change-case';
import { useSettings, useTable, useTableFilter } from 'src/hooks';
import { ICampaign } from 'src/common/interfaces';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { TABLE_HEAD } from './actions';
import { fDateTime } from 'src/utils/formatTime';
// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export const CampaignGroupTimeline = () => {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: 'createdAt',
    defaultOrder: 'desc',
  });
  const { themeStretch } = useSettings();
  const { tableFilter, getFinalParams, onClearFilter } = useTableFilter();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState('');

  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [getCampaign, setCampaign] = useState<ICampaign[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedTo, setSelectedTO] = useState('');
  const [fromLabel, setFromLabel] = useState('');
  console.log(getCampaign);

  console.log(tableData);

  const getCampaignGroups = useCallback(async (page: number, rowsPerPage: number) => {
    const params: IParams = {
      page: page + 1,
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      populates: ['campaign'],
    };
    const res = await getApi({ url: CampaignGroupsApi.base, showToast: false, params });

    if (res.error) return;

    setCampaign(res.data.campaignGroups);
  }, []);

  // const getCampaignGroupsTimeline = async (page: number, rowsPerPage: number) => {
  //   setLoading(true);

  //   const params: IParams = {
  //     page: page + 1,
  //     skip: page * rowsPerPage,
  //     limit: rowsPerPage,
  //     populates: ['campaign'],
  //   };
  //   const res = await getApi({ url: AnalyticsApi.campaignTimeline, showToast: false });

  //   setLoading(false);

  //   if (res.error) return;
  //   setTotalCount(res.data.count);
  //   setTableData(res.data.campaignTimelines);
  //   setIsLoading(false);
  // };

  useEffect(() => {
    // getCampaignGroupsTimeline(rowsPerPage, page);
    getCampaignGroups(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const onSelectAllRow = (checked: any, allIds: any) => {
    if (checked) {
      const selectedEmails = tableData
        .filter((row) => allIds.includes(row._id))
        .map((row) => row.email || '');

      const selectedEmailString = selectedEmails.join(',');

      setSelectedTO(selectedEmailString);
    } else {
      setSelectedTO('');
    }
  };
  const handleChange = async (campaignId: any) => {
    console.log(campaignId);
    try {
      // const response = await axios.get(`/campaign-timelines/campaign-group/${campaignId}`);
      const response = await getApi({
        url: AnalyticsApi.campaignGroupTimeline(campaignId),
        showToast: false,
      });
      setTableData(response.data.campaignTimelines);
      console.log(response); // Assuming response contains the campaign timelines data
    } catch (error) {
      console.error('Error fetching campaign timelines:', error);
    }
  };

  return (
    <Page title="Campaign-Timeline">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={2} margin={2}>
          <Grid item md={4} sm={12}>
            <Card sx={{ p: 3 }}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <TextField select fullWidth label="Campaign">
                  <MenuItem value="" />
                  {getCampaign.map((campaign) => (
                    <MenuItem
                      onClick={() => handleChange(campaign._id)}
                      key={campaign._id}
                      value={campaign._id}
                    >
                      {sentenceCase(campaign.name)}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </Card>
          </Grid>
        </Grid>
        <Divider />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 960, position: 'relative' }}>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  // order={order}
                  // orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  // onSort={onSort}
                  // onSelectAllRows={(checked) => {
                  //   onSelectAllRows(
                  //     checked,
                  //     tableData.map((row) => row._id)
                  //   );
                  //   onSelectAllRow(
                  //     checked,
                  //     tableData.map((row) => row._id)
                  //   );
                  // }}
                />
                <TableBody>
                  {isLoading ? (
                    <LoadingBox />
                  ) : (
                    tableData.map((row, index) => (
                      <TableRow hover key={index}>
                        <TableCell> {fDateTime(row?.createdAt)}</TableCell>
                        <TableCell>{row?.type}</TableCell>
                        <TableCell>{row?.email}</TableCell>

                        {/* Add other TableCell components as needed */}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
};

function setTotalCount(count: any) {
  throw new Error('Function not implemented.');
}

function setTableData(campaignGroups: any) {
  throw new Error('Function not implemented.');
}
export default CampaignGroupTimeline;
