import toast from 'react-hot-toast';
import { axiosInstance } from 'src/utils';
import { IAPIResponse, IDeleteAPI, IGetAPI, IPatchAPI, IPostAPI } from '../interfaces';
import { AxiosResponse } from 'axios';

const EmptyBadRequest: IAPIResponse = {
  data: null,
  error: 'Bad Request',
  message: 'Oops something went wrong',
  statusCode: 400,
};

const PermissionDenied = {
  data: null,
  error: 'Permission Denied',
  message: `You don't have permission to perform this action`,
  statusCode: 403,
};

const NotFound = {
  data: null,
  error: 'Not Found',
  message: `API end point not found!`,
  statusCode: 404,
};

// common get api
export async function getApi({
  url,
  showToast = false,
  message,
  params,
}: IGetAPI): Promise<IAPIResponse> {
  try {
    if (showToast) {
      toast.loading('Loading...', { id: 'loader', duration: 100000 });
    }
    const request = await axiosInstance.get(url, { params: params });
    const response = await handleAPIReturn(request, showToast, message);
    if (response && showToast) {
      toast.dismiss('loader');
    }
    return response;
  } catch (error) {
    return await handleAPIError(error);
  }
}

// common post api
export async function postApi({
  url,
  values,
  showToast = false,
  message,
}: IPostAPI): Promise<IAPIResponse> {
  try {
    const request = await axiosInstance.post(url, values);

    const response = await handleAPIReturn(request, showToast, message);
    return response;
  } catch (error) {
    return await handleAPIError(error);
  }
}

// common delete api
export async function deleteApi({
  url,
  values,
  showToast = false,
  message,
}: IDeleteAPI): Promise<IAPIResponse> {
  try {
    console.log('log: values', values);
    const request = await axiosInstance.delete(url, { data: values });

    const response = await handleAPIReturn(request, showToast, message);
    return response;
  } catch (error) {
    return await handleAPIError(error);
  }
}

// common patch api
export async function patchApi({
  url,
  values,
  showToast = false,
  message,
}: IPatchAPI): Promise<IAPIResponse | any> {
  try {
    const request = await axiosInstance.patch(url, values);

    console.log('log: request', request);

    const response = await handleAPIReturn(request, showToast, message);
    return response;
  } catch (error) {
    return await handleAPIError(error);
  }
}

// Function to submit the formData with multiple files using Axios
export async function postFormData({
  url,
  values,
  showToast = false,
  message,
}: IPatchAPI): Promise<IAPIResponse> {
  try {
    const request = await axiosInstance.post(url, values, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const response = await handleAPIReturn(request, showToast, message);
    return response;
  } catch (error) {
    return await handleAPIError(error);
  }
}

// Handle API data to component
const handleAPIReturn = async (
  request: AxiosResponse,
  showToast: boolean,
  message: string | undefined
): Promise<IAPIResponse> => {
  try {
    const result = await request;
    const { data } = result;

    if (showToast) {
      if (data?.error || !data?.data)
        toast.error(message || data?.message ? data?.message : EmptyBadRequest.message);
      else toast.success(message ? message : data?.message ? data?.message : 'Success');
    }

    return data;
  } catch (error) {
    const { data } = error.response;

    console.error('log: handleAPIReturn', error);

    if (showToast) {
      toast.error(message || data?.message ? data?.message : EmptyBadRequest.message);
    }
    return data || EmptyBadRequest;
  }
};

const handleAPIError = async (error: any): Promise<IAPIResponse> => {
  try {
    if (!error?.response?.data) {
      toast.error(EmptyBadRequest.message);
      return EmptyBadRequest;
    }

    const { data } = error.response;
    if (data.statusCode === 403) {
      toast.error(PermissionDenied.message);
      return PermissionDenied;
    } else if (data.statusCode === 404) {
      toast.error(data.message ? data.message : NotFound.message);
      return NotFound;
    }

    toast.error(EmptyBadRequest.message);
    return EmptyBadRequest;
  } catch (error) {}
  return EmptyBadRequest;
};
