import { useState, useEffect } from 'react';
// @mui
import {
  Box,
  Card,
  Table,
  Switch,
  Tooltip,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Checkbox,
  Chip,
  Typography,
  TableRow,
  TableCell,
  Rating,
  Link,
  Button,
} from '@mui/material';

// routes
import { PATH_DASHBOARD } from 'src/routes/paths';

// hooks
import { useSettings, useTable, getComparator, useTableFilter } from 'src/hooks';

// components
import {
  LinkButton,
  Page,
  Iconify,
  Scrollbar,
  HeaderBreadcrumbs,
  TableNoData,
  TableSkeleton,
  TableHeadCustom,
  TableSelectedActions,
  MoreMenu,
  AlertModal,
  AddToBookmark,
  TableFilter,
} from 'src/components';
// sections
import { IParams } from 'src/@types/params';
import { CPageTitles, CRowsPerPageOptions } from 'src/common/constants';

// apis
import { deleteApi, getApi, ClutchApi } from 'src/common/apis';

// constants
import { TABLE_HEAD, applySortFilter } from '../actions';
import { sentenceCase } from 'change-case';
import { fDateTime, fShortDate } from 'src/utils/formatTime';
import { EBookmarkType, ETableFilter } from 'src/common/enums';
import { ClutchTableFilter } from 'src/components/filters';
import CustomMailApi from 'src/common/apis/custom-mail.api';
import { useNavigate } from 'react-router-dom';
import ImapAccountsApi from 'src/common/apis/imap-accounts.api';
import { Link as RouterLink, useParams } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function ImapAccountsList() {
  const {
    dense,
    order,
    orderBy,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: 'time',
    defaultOrder: 'desc',
  });

  const { themeStretch } = useSettings();
  const { tableFilter, getFinalParams } = useTableFilter();

  const [tableData, setTableData] = useState<any[] | []>([]);

  const [filterName, setFilterName] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState({ open: false, id: '' });

  const [totalCount, setTotalCount] = useState<number>(0);

  const navigate = useNavigate();

  const handleClose = () =>
    setOpen({
      open: false,
      id: '',
    });

  const getAccounts = async (page: number, rowsPerPage: number) => {
    setIsLoading(true);

    const params: IParams = {
      page: page + 1,
      skip: page * rowsPerPage,
      limit: rowsPerPage,
    };

    const res = await getApi({
      url: ImapAccountsApi.base,
      showToast: false,
      params,
    });

    setIsLoading(false);

    if (res.error) return;

    setTotalCount(res.data.count);
    setTableData(res.data.imapAccounts);
  };

  useEffect(() => {
    getAccounts(tableFilter?.imapAccount.params.page, tableFilter.imapAccount.params.rowsPerPage);
  }, [tableFilter.imapAccount.params.page, tableFilter.imapAccount.params.rowsPerPage]);

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const onSearch = async () => {
    await getAccounts(
      tableFilter.imapAccount.params.page,
      tableFilter.imapAccount.params.rowsPerPage
    );
  };

  const handleDeleteRow = (rowId: any) => {
    const rowData = dataFiltered.find((row) => row._id === rowId);
    if (rowData) {
      navigate(`${PATH_DASHBOARD.imapAccounts.root}/${rowId}/delete`, { state: { data: rowData } });
    }
  };

  const denseHeight: number = dense ? 60 : 80;

  const isNotFound = (!dataFiltered.length && !!filterName) || (!isLoading && !dataFiltered.length);

  return (
    <Page title={CPageTitles.imapAccounts.list}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="IMAP Account"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Imap Accounts',
              href: PATH_DASHBOARD.imapAccounts.list,
            },
            { name: 'Imap Accounts List' },
          ]}
          action={
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              component={RouterLink}
              to={PATH_DASHBOARD.imapAccounts.create}
            >
              New IMAP-Account
            </Button>
          }
        />

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 960, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row._id)
                    )
                  }
                  // actions={
                  //   <>
                  //     <AddToBookmark members={selected} type={EBookmarkType.CLUTCH} />
                  //   </>
                  // }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row._id)
                    )
                  }
                />

                <TableBody>
                  {(isLoading
                    ? [...Array(tableFilter.imapAccount.params.rowsPerPage)]
                    : dataFiltered
                  )?.map((row, index) =>
                    row ? (
                      <TableRow hover selected={row?.selected} key={row._id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selected.includes(row._id)}
                            onClick={() => onSelectRow(row._id)}
                          />
                        </TableCell>

                        <TableCell>
                          <LinkButton to={`${PATH_DASHBOARD.imapAccounts.root}/${row?._id}/edit`}>
                            {row?.label || 'N/A'}
                          </LinkButton>
                        </TableCell>

                        <TableCell>{row?.description || 'N/A'}</TableCell>

                        <TableCell>{row.host}</TableCell>
                        <TableCell>{row.port}</TableCell>
                        <TableCell>{fDateTime(row.createdAt)}</TableCell>
                        <TableCell>{fDateTime(row.updatedAt)}</TableCell>

                        <TableCell align="right">
                          <MoreMenu
                            onDelete={() => handleDeleteRow(row?._id)}
                            id={row?._id}
                            editRootPath={PATH_DASHBOARD.imapAccounts.root}
                          />
                        </TableCell>
                      </TableRow>
                    ) : (
                      !isNotFound && <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={CRowsPerPageOptions}
              component="div"
              count={totalCount}
              rowsPerPage={tableFilter.customMailInbox.params.rowsPerPage}
              page={tableFilter.customMailInbox.params.page}
              onPageChange={(e, newPage) => onChangePage(e, newPage, ETableFilter.CUSTOM_MAIL)}
              onRowsPerPageChange={(e) => onChangeRowsPerPage(e, ETableFilter.CUSTOM_MAIL)}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
