import { useRoutes } from 'react-router-dom';

// components
import { ProtectedRoutes, PublicRoutes, SampleRoutes } from './widgets';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([...PublicRoutes, ...ProtectedRoutes, ...SampleRoutes]);
}
