import { Chip, Divider, Typography, Box, Stack } from '@mui/material';

interface IRowProps {
  title: string;
  value: string;
  chip?: boolean;
  chipColor?: any;
}
const BookmarkItem = ({ title, value, chip = false, chipColor = 'default' }: IRowProps) => {
  return (
    <Box sx={{ mb: 1 }}>
      <Stack
        direction={{ xs: 'row', sm: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        spacing={{ xs: 3, sm: 2 }}
        sx={{ mb: 1 }}
      >
        <Typography>{title}</Typography>
        {!chip && (
          <Typography align={value.length > 25 ? 'justify' : 'right'} maxWidth={300}>
            {value}
          </Typography>
        )}
        {chip && <Chip size="small" label={value} color={chipColor} />}
      </Stack>
      <Divider />
    </Box>
  );
};

export default BookmarkItem;
