import { ETableFilter } from 'src/common/enums';
import { useDispatch, useSelector } from 'src/redux/store';
import { toggleOpen, clear, handleParamChange, updateParams } from 'src/redux/slices/tableFilter';

// ----------------------------------------------------------------------

const useTableFilter = () => {
  const dispatch = useDispatch();
  const tableFilter = useSelector((state) => state.tableFilter);

  const onToggle = (key: ETableFilter) => {
    dispatch(toggleOpen({ key }));
  };

  const onClearFilter = (key: ETableFilter) => {
    dispatch(clear({ key }));
  };

  const onParamChange = (key: ETableFilter, e: any) => {
    dispatch(handleParamChange({ key, name: e.target.name, value: e.target.value }));
  };

  const onUpdateParams = (key: ETableFilter, params: any) => {
    dispatch(updateParams({ key, params }));
  };

  const getFinalParams = (key: ETableFilter) => {
    let items: any = {};
    const source: any = { ...tableFilter[key].params };
    Object.keys(source).forEach((keyItem: any) => {
      if (source?.[keyItem] !== undefined && source?.[keyItem] !== '') {
        items = { ...items, [keyItem]: source?.[keyItem] };
      }
    });

    return items;
  };

  return {
    dispatch,
    tableFilter,
    onToggle,
    onClearFilter,
    onParamChange,
    getFinalParams,
    onUpdateParams,
  };
};

export default useTableFilter;
