import { ECampaignTimelinesReplyType, ECampaignTimelinesStatus } from 'src/common/enums';

export const getReplyColor = (reply: ECampaignTimelinesReplyType) => {
  switch (reply) {
    case ECampaignTimelinesReplyType.POSITIVE:
      return '#54D62C';

    case ECampaignTimelinesReplyType.NEGATIVE:
      return '#FF4842';

    case ECampaignTimelinesReplyType.NEUTRAL:
      return '#FFC107';

    default:
      return '#919EAB';
  }
};

export const getStatusColor = (reply: ECampaignTimelinesStatus) => {
  switch (reply) {
    case ECampaignTimelinesStatus.REPLIED:
      return 'warning';

    case ECampaignTimelinesStatus.FINALIZED:
      return 'success';

    default:
      return 'default';
  }
};
