import { useEffect, useState } from 'react';
import { Button, Container } from '@mui/material';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Page from 'src/components/Page';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useSettings from 'src/hooks/useSettings';
import { SmtpAccountsApi, getApi } from 'src/common/apis';
import { CPageTitles } from 'src/common/constants';
// import SmtpAccountsForm from './ImapAccountsForm';
// import { CreateSmtpAccountSchema } from 'src/common/schemas';
// import { ISmtpAccount } from 'src/common/interfaces';
import ImapAccountsForm from './ImapAccountsForm';
import { CreateImapAccountSchema } from 'src/common/schemas/create-imap-account.schema';
import { IImapAccount } from 'src/common/interfaces/imap-accounts.interface';
import ImapAccountsApi from 'src/common/apis/imap-accounts.api';

const ImapAccountsCreate = () => {
  const { themeStretch } = useSettings();
  const params = useParams();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const createImapAccountSchema = new CreateImapAccountSchema();
  const [imapAccount, setImapAccount] = useState<IImapAccount>(
    createImapAccountSchema.initialValues
  );

  useEffect(() => {
    if (params.id) {
      getImapAccount(params.id);
    }
  }, [params]);

  const getImapAccount = async (id: string) => {
    const res = await getApi({ url: `${ImapAccountsApi.base}/${id}` });

    if (res.error) return;

    setIsEdit(true);
    const { imapAccount: tempUser } = res.data;

    // console.log('imapdata:', res.data)

    setImapAccount({ ...createImapAccountSchema.initialValues, ...tempUser });
  };

  const createNew = async () => {
    setImapAccount(createImapAccountSchema.initialValues);
    setIsEdit(false);
  };

  return (
    <Page title={isEdit ? CPageTitles.imapAccounts.create : CPageTitles.imapAccounts.edit}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading={isEdit ? imapAccount.username || 'Unknown' : 'New IMAP Account'}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'IMAP Accounts',
              href: PATH_DASHBOARD.imapAccounts.list,
            },
            { name: isEdit ? 'Update IMAP Account' : 'Create IMAP Account' },
          ]}
          action={
            isEdit && (
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                component={RouterLink}
                to={PATH_DASHBOARD.imapAccounts.create}
                onClick={createNew}
              >
                New IMAP Account
              </Button>
            )
          }
        />

        <ImapAccountsForm isEdit={isEdit} currentImapAccount={imapAccount} />
      </Container>
    </Page>
  );
};

export default ImapAccountsCreate;
