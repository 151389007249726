import { Card, Grid, TextField, Box, Stack, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { SEARCH_RESULT_WEBSITEINFO_FILTERS } from 'src/common/constants/filters.constant';
import {
  SEARCH_RESULT_EMAIL_VERIFIED_FILTERS,
  SEARCH_RESULT_RATING_FILTERS,
  TECHBEHEMOTH_HOURLY_RATE_FILTERS,
  TECHBEHEMOTH_TEAM_SIZE_FILTERS,
} from 'src/common/constants';
import { SEARCH_RESULT_STATUS_FILTERS } from 'src/common/constants/filters.constant';
import { ETableFilter } from 'src/common/enums';
import { useTableFilter } from 'src/hooks';

const SearchResultTableFilter = () => {
  const { tableFilter, onParamChange } = useTableFilter();

  const onChange = (e: any) => {
    onParamChange(ETableFilter.SEARCH_RESULT, e);
  };

  // if (tableFilter.searchResult.params.isBlocked === undefined) {
  //   tableFilter.searchResult.params.isBlocked = false;
  // }

  const getFieldProps = (name: string) => {
    return { name, onChange };
  };

  return (
    <Box sx={{ px: 2, pb: 2 }}>
      <Card variant="outlined" sx={{ p: 2 }}>
        <Grid container spacing={3}>
          <Grid item md={12} lg={12}>
            <Typography sx={{ mb: 1 }}>Filter Search Result Data</Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <TextField
                size="small"
                fullWidth
                label="Title"
                {...getFieldProps('title')}
                value={tableFilter.searchResult.params.title || ''}
              />

              <TextField
                size="small"
                fullWidth
                label="Country"
                {...getFieldProps('country')}
                value={tableFilter.searchResult.params.country || ''}
              />

              <TextField
                size="small"
                fullWidth
                label="Email"
                {...getFieldProps('email')}
                value={tableFilter.searchResult.params.email || ''}
              />

              {/**Team size */}
              <TextField
                size="small"
                select
                fullWidth
                label="WebsiteInfo"
                {...getFieldProps('websiteInfo')}
                value={tableFilter.searchResult.params.websiteInfo || ''}
              >
                <MenuItem value="" sx={{ height: 30 }}></MenuItem>
                {SEARCH_RESULT_WEBSITEINFO_FILTERS.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                size="small"
                select
                fullWidth
                label="Rating"
                {...getFieldProps('rating')}
                value={tableFilter.searchResult.params.rating || ''}
              >
                <MenuItem value="" sx={{ height: 30 }}></MenuItem>
                {SEARCH_RESULT_RATING_FILTERS.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>

              {/**Hourly Rate */}
              <TextField
                size="small"
                select
                fullWidth
                label="Email Verified"
                {...getFieldProps('emailVerified')}
                value={tableFilter.searchResult.params.emailVerified || ''}
              >
                <MenuItem value="" sx={{ height: 30 }}></MenuItem>
                {SEARCH_RESULT_EMAIL_VERIFIED_FILTERS.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                size="small"
                select
                fullWidth
                label="Status"
                {...getFieldProps('isBlocked')}
                value={tableFilter.searchResult.params.isBlocked || ''}
              >
                <MenuItem value="" sx={{ height: 30 }}></MenuItem>
                {SEARCH_RESULT_STATUS_FILTERS.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default SearchResultTableFilter;
