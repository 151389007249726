import * as Yup from 'yup';
import { IUser } from '../interfaces';
import { ERole } from '../enums/roles.enum';

export class CreateUserSchema {
  schema;

  initialValues: IUser;

  constructor(isEdit: boolean) {
    this.schema = Yup.object({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      username: Yup.string().email('Invalid email format').required('Email is required'),
      password: isEdit
        ? Yup.string().optional()
        : Yup.string()
            .required('Password is required')
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
              'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number'
            ),
      about: Yup.string().optional(),
      roles: Yup.array()
        .of(Yup.string().oneOf(Object.values(ERole), 'Invalid role'))
        .min(1, 'At least one role is required'),
    });

    this.initialValues = {
      username: '',
      password: '',
      firstName: '',
      lastName: '',
      about: '',
      roles: [ERole.EMPLOYEE],
    };
  }
}
