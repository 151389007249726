export enum EIndividualCampaignStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum EIndividualCampaignTypes {
  CONTACTS = 'contact',
  SEARCH_RESULTS = 'searchResult',
  CONTACT_FORMS = 'contactForm',
  LINKEDIN = 'linkedInData',
}

export enum EIndividualCampaignGroupPopulate {
  CONTACTS = 'contact',
  SEARCH_RESULTS = 'searchResult',
  CONTACT_FORMS = 'contactForm',
  CAMPAIGN = 'campaign',
  LINKEDIN_DATA = 'linkedInData',
}
