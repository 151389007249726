import { useState, useEffect } from 'react';
import {
  Card,
  Box,
  Typography,
  IconButton,
  CardHeader,
  TablePagination,
  Chip,
  Button,
} from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from '@mui/lab';
import {
  ECampaignGroupTypes,
  ECampaignTimelinesReplyType,
  ECampaignTimelinesStatus,
} from 'src/common/enums';
import { INotes } from 'src/common/interfaces';
import { Icon } from '@iconify/react';
import { IParams } from 'src/@types/params';
import { deleteApi, getApi, CampaignTimelinesApi } from 'src/common/apis';
import { fShortDate } from 'src/utils/formatTime';
import { useTable } from 'src/hooks';
import { Iconify, LoadingBox, NoContentBox } from 'src/components';
import { sentenceCase } from 'change-case';
import CampaignTimelineView from '../CampaignTimelineView';
import { getReplyColor, getStatusColor } from '../actions';

interface IProps {
  type: ECampaignGroupTypes;
  height?: number;
  categoryId?: string;
  setOpenReply:any
}

enum ELoading {
  NONE = 'none',
  LIST = 'list',
  CREATE = 'create',
}

enum EOpen {
  'NONE' = 'none',
  'DELETE' = 'delete',
  'NOTE' = 'note',
  'ATTACHMENT' = 'attachment',
}

const initialOpen = { modal: EOpen.NONE, id: '' };

interface IOpen {
  modal: EOpen;
  id: string;
}

// const getReplyColor = (reply: ECampaignTimelinesReplyType) => {
//   switch (reply) {
//     case ECampaignTimelinesReplyType.POSITIVE:
//       return '#54D62C';

//     case ECampaignTimelinesReplyType.NEGATIVE:
//       return '#FF4842';

//     case ECampaignTimelinesReplyType.NEUTRAL:
//       return '#FFC107';

//     default:
//       return '#919EAB';
//   }
// };

// const getStatusColor = (reply: ECampaignTimelinesStatus) => {
//   switch (reply) {
//     case ECampaignTimelinesStatus.REPLIED:
//       return 'warning';

//     case ECampaignTimelinesStatus.FINALIZED:
//       return 'success';

//     default:
//       return 'default';
//   }
// };

const CampaignTimelineList = ({ type, categoryId, height = 500, setOpenReply }: IProps) => {
  const [loading, setLoading] = useState<ELoading>(ELoading.NONE);
  const [campaignTimelines, setCampaignTimelines] = useState<any[] | []>([]);
  const [open, setOpen] = useState(false);
  const [currentCampaignTimeline, setCurrentCampaignTimeline] = useState<any>(null);
 

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: 'createdAt',
  });

  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (categoryId) {
      getCampaignTimelines(page, rowsPerPage, categoryId);
    }
  }, [categoryId, page, rowsPerPage]);

  const getCampaignTimelines = async (
    page: number,
    rowsPerPage: number,
    categoryId: string | undefined
  ) => {
    setLoading(ELoading.LIST);

    const params: IParams = {
      page: page + 1,
      skip: page * rowsPerPage,
      limit: rowsPerPage,
    };

    const finalParams = { ...params, [type]: categoryId };

    const res = await getApi({
      url: CampaignTimelinesApi.base,
      showToast: false,
      params: categoryId ? finalParams : { ...params, type },
    });

    setLoading(ELoading.NONE);

    if (res.error) return;

    console.log('log: res', res.data);

    setCampaignTimelines(res.data.campaignTimelines);
    setTotalCount(res.data.count);
  };

  const onView = async (_id: string) => {
    const res = await getApi({
      url: `${CampaignTimelinesApi.base}/${_id}`,
      showToast: false,
    });

    setLoading(ELoading.NONE);

    if (res.error) return;

    setOpen(true);
    setCurrentCampaignTimeline(res.data.campaignTimeline);
  };

  const onClose = () => {
    setOpen(false);
    setCurrentCampaignTimeline(null);
  };

  return (
    <Card>
      <CardHeader
        title="Campaign Timeline"
        action={
          <>
            {open && currentCampaignTimeline && (
              <Button
                variant="contained"
                onClick={onClose}
                startIcon={<Iconify icon="pajamas:go-back" />}
              >
                Back
              </Button>
            )}
          </>
        }
      />
      {loading !== ELoading.NONE ? (
        <LoadingBox />
      ) : (
        <>
          {currentCampaignTimeline && open ? (
            <CampaignTimelineView campaignTimeline={currentCampaignTimeline} setOpenReply={setOpenReply} type={type} />
          ) : (
            <>
              {campaignTimelines.length ? (
                <Box sx={{ px: 2, pt: 2 }}>
                  <Box height={height} sx={{ overflowY: 'auto', px: 2, pt: 2 }}>
                    <Timeline>
                      {campaignTimelines.map((campaignTimeline) => (
                        <TimelineItem key={campaignTimeline._id}>
                          {/** Left section */}
                          <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="text.secondary"
                          >
                            <IconButton color="primary">
                              <Icon icon="ic:baseline-reply" />
                            </IconButton>
                            <Typography
                              variant="body2"
                              component="span"
                              sx={{
                                cursor: 'pointer',
                                color: getReplyColor(campaignTimeline.replyType),
                              }}
                            >
                              {sentenceCase(campaignTimeline.replyType)}
                            </Typography>
                            <br />
                            Last Update :{' '}
                            {campaignTimeline.createdAt
                              ? fShortDate(campaignTimeline.createdAt)
                              : 'N/A'}
                            <br />
                            {/** Campaign Step name */}
                            <Typography
                              variant="body2"
                              component="span"
                              sx={{ cursor: 'pointer', mb: 3 }}
                              onClick={() => onView(campaignTimeline._id)}
                            >
                              Step :{' '}
                              {campaignTimeline.campaign?.steps?.find(
                                (item: any) => item.order === campaignTimeline.campaignStepOrder
                              )?.sequence || 'N/A'}
                            </Typography>
                          </TimelineOppositeContent>

                          {/** Centered Icon */}
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="primary">
                              <Icon icon="ic:round-history" />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>

                          {/** Right section */}
                          <TimelineContent sx={{ py: '12px', px: 2 }}>
                            {/** Campaign and Campaign Group */}
                            <Typography
                              variant="h6"
                              component="span"
                              sx={{ cursor: 'pointer', mb: 3 }}
                              onClick={() => onView(campaignTimeline._id)}
                            >
                              {sentenceCase(campaignTimeline.campaign.name)} |{' '}
                              {sentenceCase(campaignTimeline.campaignGroup.name)}
                            </Typography>
                            <br />

                            <Box sx={{ pt: 1 }}>
                              {/** Status */}
                              <Chip
                                label={sentenceCase(campaignTimeline.status)}
                                size="small"
                                color={getStatusColor(campaignTimeline.status)}
                              />
                            </Box>

                            {/** Campaign and Campaign Group */}
                            <Box sx={{ pt: 1 }}>
                              <Typography
                                variant="body2"
                                component="span"
                                sx={{ cursor: 'pointer', mb: 3 }}
                                color="dark"
                              >
                                Order : {campaignTimeline.campaignStepOrder}
                              </Typography>
                            </Box>
                          </TimelineContent>
                        </TimelineItem>
                      ))}
                    </Timeline>
                  </Box>

                  <Box sx={{ position: 'relative' }}>
                    <TablePagination
                      rowsPerPageOptions={[10, 50, 100]}
                      component="div"
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={onChangePage}
                      onRowsPerPageChange={onChangeRowsPerPage}
                    />
                  </Box>
                </Box>
              ) : (
                <NoContentBox>No record found! 😑</NoContentBox>
              )}
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default CampaignTimelineList;
