import { Tooltip, Button } from '@mui/material';
import { useState } from 'react';
import { EBookmarkType } from 'src/common/enums';
import { CustomModal } from 'src/components/modals';
import AddToBookmarkForm from './AddToBookmarkForm';

interface IProps {
  type: EBookmarkType;
  members: string[] | [];
}

const AddToBookmark = ({ type, members }: IProps) => {
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);

  const onClose = () => setOpen(false);

  return (
    <>
      <Tooltip title="Add To Bookmark">
        <Button onClick={onOpen}>Add to Bookmark</Button>
      </Tooltip>

      <CustomModal
        title="Add To Bookmark"
        open={open}
        onClose={onClose}
        maxWidth="sm"
        minHeight={200}
      >
        <AddToBookmarkForm type={type} members={members} />
      </CustomModal>
    </>
  );
};

export default AddToBookmark;
