import { ECampaignGroupTypes, EBookmarkType, EIndividualCampaignTypes } from 'src/common/enums';

export const COMMON_SEARCH_RESULTS_TABLE_HEAD = [
  { id: 'no', label: 'No.' },
  { id: 'title', label: 'Title', align: 'left', minWidth: 200 },
  { id: 'url', label: 'URL', align: 'left' },
  { id: 'phone', label: 'Phone', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'socialLinks', label: 'Social Links', align: 'left' },
  { id: 'Country', label: 'Country', align: 'left' },
  { id: 'rating', label: 'Rating', align: 'left' },
  { id: 'email_verified', label: 'Email Verified', align: 'left' },
  { id: '' },
];

export const COMMON_CONTACTS_TABLE_HEAD = [
  { id: 'no', label: 'No.' },
  { id: 'firstName', label: 'First name', align: 'left' },
  { id: 'lastName', label: 'Last name', align: 'left' },
  { id: 'age', label: 'Age', align: 'left' },
  { id: 'gender', label: 'Gender', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'phone', label: 'Phone', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'type', label: 'Type', align: 'left' },
  { id: 'jobFrequency', label: 'Job Frequency', align: 'left' },
  { id: 'expectedBillingAmount', label: 'Expected Billing', align: 'left' },
  { id: 'rating', label: 'Rating', align: 'left' },
  { id: '' },
];

export const COMMON_LINKEDIN_DATA_TABLE_HEAD = [
  { id: 'no', label: 'No.' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'email', label: 'Email', align: 'left', minWidth: 170 },
  { id: 'phone', label: 'Contact No.', align: 'left', minWidth: 170 },
  { id: 'url', label: 'Profile', align: 'left' },
  { id: 'about', label: 'About', align: 'left' },
  { id: '' },
];

export const COMMON_CLUTCH_TABLE_HEAD = [
  { id: 'no', label: 'No.' },
  { id: 'company_name', label: 'Company Name', align: 'left', minWidth: 150 },
  { id: 'designation', label: 'Designation', align: 'left', minWidth: 150 },
  { id: 'rating', label: 'Rating', align: 'left' },
  { id: 'minimum_budget', label: 'Minimum Budget', align: 'left', minWidth: 150 },
  { id: 'avg_hourly_rate', label: 'Avg Hourly Rate', align: 'left', minWidth: 150 },
  { id: 'employees', label: 'Employees', align: 'left' },
  { id: 'location', label: 'Location', align: 'left', minWidth: 150 },
  { id: 'scrap_url', label: 'Scrap URL', align: 'left' },
  { id: 'clutch_profile_url', label: 'Clutch Profile URL', align: 'left', minWidth: 150 },
  { id: 'services', label: 'Services', align: 'left', minWidth: 200 },
  { id: 'main_url', label: 'Main URL', align: 'left' },
  { id: 'description', label: 'Description', align: 'left', minWidth: 200 },
  { id: 'createdAt', label: 'Created At', align: 'left', minWidth: 150 },
  { id: 'updatedAt', label: 'Updated At', align: 'left', minWidth: 150 },
  { id: '' },
];

export const COMMON_TECHBEHEMOTH_TABLE_HEAD = [
  { id: 'no', label: 'No.' },
  { id: 'company_name', label: 'Company Name', align: 'left', minWidth: 150 },
  { id: 'hourly_rate', label: 'Hourly Rate', align: 'left', minWidth: 150 },
  { id: 'location', label: 'Location', align: 'left' },
  { id: 'team_size', label: 'Team Size', align: 'left' },
  { id: 'services', label: 'Services', align: 'left', minWidth: 100 },
  { id: 'type', label: 'Type', align: 'left', minWidth: 150 },
  { id: 'main_phone_number', label: 'Main Phone Number', align: 'left', minWidth: 170 },
  { id: 'local_url', label: 'Local URL', align: 'left' },
  { id: 'url', label: 'URL', align: 'left' },
  { id: 'description', label: 'Description', align: 'left', minWidth: 200 },
  { id: 'createdAt', label: 'Created At', align: 'left', minWidth: 150 },
  { id: 'updatedAt', label: 'Updated At', align: 'left', minWidth: 150 },
  { id: '' },
];

export const COMMON_CAREER_TABLE_HEAD = [
  { id: 'no', label: 'No.' },
  { id: 'job_title', label: 'Job Title', align: 'left' },
  { id: 'conpany_name', label: 'Company Name', align: 'left' },
  { id: 'job_type', label: 'Job Type', align: 'left' },
  { id: 'salary', label: 'Salary', align: 'left' },
  { id: 'job_link', label: 'Job Link', align: 'left' },
  { id: 'description', label: 'Job Description', align: 'left' },
  { id: 'original_url', label: 'Original Url', align: 'left' },
  { id: 'company_location', label: 'Company Location', align: 'left' },
  { id: 'rating', label: 'Rating', align: 'left' },
  { id: '' },
];

export const COMMON_CONTACT_FORM_TABLE_HEAD = [
  { id: 'no', label: 'No.' },
  { id: 'firstName', label: 'FirstName', align: 'left' },
  { id: 'lastName', label: 'LastName', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'number', label: 'Phone Number', align: 'left' },
  { id: 'subject', label: 'Subject', align: 'left' },
  { id: 'fromtype', label: 'FromType', align: 'left' },
  { id: 'createdAt', label: 'Date Created', align: 'left' },
  { id: 'dob', label: 'DOB', align: 'left' },
  { id: 'city', label: 'City', align: 'left' },
  { id: 'position', label: 'Position', align: 'left' },
  { id: 'workstatus', label: 'Work Status', align: 'left' },
  { id: 'lastworkingcompany', label: 'Last Working Company', align: 'left' },
  { id: 'experience', label: 'Experience', align: 'left' },
  { id: 'currentctc', label: 'Current CTC', align: 'left' },
  { id: 'expectedctc', label: 'Expected CTC', align: 'left' },
  { id: 'hearaboutposition', label: 'Hear About Position', align: 'left' },
  { id: 'resume', label: 'Resume', align: 'left' },
  { id: '' },
];

export const getCommonScrapedDataTableHead = (
  type: ECampaignGroupTypes | EBookmarkType | EIndividualCampaignTypes
) => {
  switch (type) {
    case ECampaignGroupTypes.CONTACTS:
    case EBookmarkType.CONTACT:
    case EIndividualCampaignTypes.CONTACTS:
      return COMMON_CONTACTS_TABLE_HEAD;

    case ECampaignGroupTypes.SEARCH_RESULTS:
    case EBookmarkType.SEARCH_RESULT:
    case EIndividualCampaignTypes.SEARCH_RESULTS:
      return COMMON_SEARCH_RESULTS_TABLE_HEAD;

    case ECampaignGroupTypes.LINKEDIN:
    case EBookmarkType.LINKEDIN:
    case EIndividualCampaignTypes.LINKEDIN:
      return COMMON_LINKEDIN_DATA_TABLE_HEAD;

    case EBookmarkType.CLUTCH:
      return COMMON_CLUTCH_TABLE_HEAD;

    case EBookmarkType.TECHBEHEMOTH:
      return COMMON_TECHBEHEMOTH_TABLE_HEAD;

    case EBookmarkType.CAREER:
      return COMMON_CAREER_TABLE_HEAD;

    case EBookmarkType.CONTACT_FORM:
    case EIndividualCampaignTypes.CONTACT_FORMS:
      return COMMON_CONTACT_FORM_TABLE_HEAD;

    default:
      return COMMON_CONTACTS_TABLE_HEAD;
  }
};
