export enum ECampaignGroupTypes {
  CONTACTS = 'contacts',
  SEARCH_RESULTS = 'search-results',
  CONTACT_FORMS = 'contact-forms',
  LINKEDIN = 'linkedin',
  CLUTCH = 'CLUTCH',
  TECHBEHEMOTH = 'TECHBEHEMOTH',
  CAREER = 'CAREER',
}

export enum ECampaignGroupStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum ECampaignGroupPopulate {
  CONTACTS = 'contacts',
  SEARCH_RESULTS = 'searchResults',
  CONTACT_FORMS = 'contactForms',
  CAMPAIGN = 'campaign',
  LINKEDIN_DATA = 'linkedInData',
  MEMBERS = 'members',
}
