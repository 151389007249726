const EmptyBadRequest = {
  error: 'Bad Request',
  message: 'Oops something went wrong',
  statusCode: 400,
};

const ROOTS_TITLE = 'ByteScrum CRM';

function title(root: string, sublink: string) {
  return `${sublink} | ${root}`;
}

const CPageTitles = {
  root: ROOTS_TITLE,

  contacts: {
    root: title('Contacts', ROOTS_TITLE),
    list: title('Contacts List', ROOTS_TITLE),
    create: title('Create Contact', ROOTS_TITLE),
    edit: title('Edit Contact', ROOTS_TITLE),
  },
  project: {
    root: title('Project', ROOTS_TITLE),
    list: title('Project List', ROOTS_TITLE),
    create: title('Create Project', ROOTS_TITLE),
    edit: title('Edit Project', ROOTS_TITLE),
  },
  task: {
    root: title('Task', ROOTS_TITLE),
    list: title('Task List', ROOTS_TITLE),
    create: title('Create Task', ROOTS_TITLE),
    edit: title('Edit Task', ROOTS_TITLE),
  },
  projectcredentials: {
    root: title('Project Credentials', ROOTS_TITLE),
    list: title('Project Credentials List', ROOTS_TITLE),
    create: title('Create Project Credentials', ROOTS_TITLE),
    edit: title('Edit Project Credentials', ROOTS_TITLE),
    view: title('View Project Credentials', ROOTS_TITLE),
  },
  mainDashboard: {
    root: title('Main Dashboard', ROOTS_TITLE),
    expenseDashboard: title('CRM Dashboard', ROOTS_TITLE),
  },
  blog: {
    root: title('Blogs', ROOTS_TITLE),
    create: title('Blog: New Post', ROOTS_TITLE),
  },
  campaign: {
    root: title('Campaign', ROOTS_TITLE),
    list: title('Campaign List', ROOTS_TITLE),
    create: title('Create Campaign', ROOTS_TITLE),
    edit: title('Edit Campaign', ROOTS_TITLE),
  },

  deals: {
    root: title('Deals', ROOTS_TITLE),
    list: title('Deals List', ROOTS_TITLE),
    create: title('Create Deals', ROOTS_TITLE),
    edit: title('Edit Deals', ROOTS_TITLE),
  },

  technologies: {
    root: title('Technologies', ROOTS_TITLE),
    list: title('Technologies List', ROOTS_TITLE),
    create: title('Create Technologies', ROOTS_TITLE),
    edit: title('Edit Technologies', ROOTS_TITLE),
  },

  reminders: {
    root: title('Reminders', ROOTS_TITLE),
    list: title('Reminders List', ROOTS_TITLE),
    create: title('Create Reminders', ROOTS_TITLE),
    edit: title('Edit Reminders', ROOTS_TITLE),
  },

  campaignGroups: {
    root: title('Campaign Group', ROOTS_TITLE),
    list: title('Campaign Group List', ROOTS_TITLE),
    create: title('Create Campaign Group', ROOTS_TITLE),
    edit: title('Edit Campaign Group', ROOTS_TITLE),
    view: title('View Campaign Group', ROOTS_TITLE),
  },

  individualCampaign: {
    root: title('Individual Campaign Group', ROOTS_TITLE),
    list: title('Individual Campaign Group List', ROOTS_TITLE),
    create: title('Create Individual Campaign Group', ROOTS_TITLE),
    edit: title('Edit Individual Campaign Group', ROOTS_TITLE),
    view: title('View Individual Campaign Group', ROOTS_TITLE),
  },

  emailTemplates: {
    root: title('Email Templates', ROOTS_TITLE),
    list: title('Email Templates List', ROOTS_TITLE),
    create: title('Create Email Templates', ROOTS_TITLE),
    edit: title('Edit Email Templates', ROOTS_TITLE),
  },
  emailTemplateGroups: {
    root: title('Email Template Groups', ROOTS_TITLE),
    list: title('Email Template Groups List', ROOTS_TITLE),
    create: title('Create Email Template Groups', ROOTS_TITLE),
    edit: title('Edit Email Template Groups', ROOTS_TITLE),
  },
  searchResults: {
    root: title('Search Results', ROOTS_TITLE),
    list: title('Search Results List', ROOTS_TITLE),
    create: title('Create Search Results', ROOTS_TITLE),
    edit: title('Edit Search Results', ROOTS_TITLE),
  },
  users: {
    root: title('Users', ROOTS_TITLE),
    list: title('Users List', ROOTS_TITLE),
    create: title('Create User', ROOTS_TITLE),
    edit: title('Edit User', ROOTS_TITLE),
  },
  clutch: {
    root: title('Clutch', ROOTS_TITLE),
    list: title('Clutch List', ROOTS_TITLE),
    create: title('Create Clutch', ROOTS_TITLE),
    edit: title('Edit Clutch', ROOTS_TITLE),
  },
  techbehemoth: {
    root: title('Techbehemoth', ROOTS_TITLE),
    list: title('Techbehemoth List', ROOTS_TITLE),
    create: title('Create Techbehemoth', ROOTS_TITLE),
    edit: title('Edit Techbehemoth', ROOTS_TITLE),
  },
  bookmarks: {
    root: title('Bookmarks', ROOTS_TITLE),
    list: title('Bookmarks List', ROOTS_TITLE),
    create: title('Create Bookmarks', ROOTS_TITLE),
    edit: title('Edit Bookmarks', ROOTS_TITLE),
    view: title('View Bookmarks', ROOTS_TITLE),
  },
  bookmarkGroups: {
    root: title('Bookmark Groups', ROOTS_TITLE),
    list: title('Bookmark Groups List', ROOTS_TITLE),
    create: title('Create Bookmark Groups', ROOTS_TITLE),
    edit: title('Edit Bookmark Groups', ROOTS_TITLE),
  },
  smtpAccounts: {
    root: title('Smtp Accounts', ROOTS_TITLE),
    list: title('Smtp Accounts List', ROOTS_TITLE),
    create: title('Create Smtp Account', ROOTS_TITLE),
    edit: title('Edit Smtp Account', ROOTS_TITLE),
  },
  imapAccounts: {
    root: title('Imap Accounts', ROOTS_TITLE),
    list: title('Imap Accounts List', ROOTS_TITLE),
    create: title('Create Imap Account', ROOTS_TITLE),
    edit: title('Edit Imap Account', ROOTS_TITLE),
  },
  notes: {
    root: title('Notes', ROOTS_TITLE),
    list: title('Notes List', ROOTS_TITLE),
  },
  tracker: {
    root: title('Tracker', ROOTS_TITLE),
  },
};

const CRowsPerPageOptions = [10, 25, 50, 100, 200, 500];

export { EmptyBadRequest, CPageTitles, CRowsPerPageOptions };

export const CPhoneRegExp = /^\+(?:[0-9]●?){6,14}[0-9]$/;
