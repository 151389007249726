import * as Yup from 'yup';
import { IBookmark } from '../interfaces';
import { EBookmarkType } from '../enums';

export class CreateBookmarkSchema {
  schema;

  initialValues: IBookmark;

  constructor() {
    this.schema = Yup.object({
      title: Yup.string().required('Bookmark title is required'),
      description: Yup.string().optional(),
    });

    this.initialValues = {
      title: '',
      description: '',
      type: EBookmarkType.CONTACT,
    };
  }
}
