import * as Yup from 'yup';
import { INotes } from '../interfaces';
import { ENotesType } from '../enums';

export class CreateNoteSchema {
  schema;

  initialValues: INotes;

  constructor() {
    this.schema = Yup.object({
      title: Yup.string().required('Template name is required'),
      description: Yup.string().required('Template description is required'),
    });

    this.initialValues = {
      title: '',
      description: '',
      type: ENotesType.OTHER,
      attachments: [],
    };
  }
}
