export enum ENotesType {
  CONTACT_FORM = 'contactForm',
  CONTACT = 'contact',
  SEARCH_RESULT = 'searchResult',
  LINKEDIN = 'linkedin',
  CAREER = 'career',
  CLUTCH = 'clutch',
  TECHBEHEMOTH = 'techbehemoth',
  OTHER = 'other',
}

export enum EPopulateNotes {
  USER = 'user',
  CONTACT = 'contact',
  SEARCH_RESULT = 'searchResult',
  LINKEDIN = 'linkedin',
  CAREER = 'career',
  CLUTCH = 'clutch',
  TECHBEHEMOTH = 'techbehemoth',
  CONTACT_FORM = 'contactForm',
}
