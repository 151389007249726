import { CustomModal } from 'src/components/modals';
import TestSmtpAccountForm from '../TestSmtpAccountForm';
import { IEmailTemplate, ISmtpAccount } from 'src/common/interfaces';
import { LoadingBox } from 'src/components/atoms';

interface IProps {
  open: boolean;
  onClose: any;
  emailTemplates: IEmailTemplate[] | [];
  smtpAccount?: ISmtpAccount | null;
  loading: boolean;
}
const TestSmtpAccountModal = ({
  open,
  onClose,
  emailTemplates = [],
  smtpAccount,
  loading = false,
}: IProps) => {
  return (
    <CustomModal title="Email Test" open={open} onClose={onClose} maxWidth="sm" minHeight={410}>
      {loading ? (
        <LoadingBox height={400} />
      ) : (
        <TestSmtpAccountForm emailTemplates={emailTemplates} smtpAccount={smtpAccount} />
      )}
    </CustomModal>
  );
};

export default TestSmtpAccountModal;
