import { useState, useEffect } from 'react';
import {
  Card,
  Box,
  CircularProgress,
  Typography,
  IconButton,
  CardHeader,
  Button,
  TablePagination,
  Divider,
} from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from '@mui/lab';
import { ENotesType } from 'src/common/enums';
import { INotes } from 'src/common/interfaces';
import { Icon } from '@iconify/react';
import { IParams } from 'src/@types/params';
import { NotesApi, deleteApi, getApi } from 'src/common/apis';
import { fShortDate } from 'src/utils/formatTime';
import { CustomModal, Iconify, MoreMenu, NotesCreate } from 'src/components';
import { useAuth, useTable } from 'src/hooks';
import NotesMediaForm from '../NotesCreate/NotesMediaForm';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import NotesDescription from './NotesDescription';
import { tr } from 'date-fns/locale';

interface IProps {
  type: ENotesType;
  height?: number;
  categoryId?: string;
}

enum ELoading {
  NONE = 'none',
  LIST = 'list',
  CREATE = 'create',
}

enum EOpen {
  'NONE' = 'none',
  'DELETE' = 'delete',
  'NOTE' = 'note',
  'ATTACHMENT' = 'attachment',
}

const initialOpen = { modal: EOpen.NONE, id: '' };

interface IOpen {
  modal: EOpen;
  id: string;
}

const getCreatedBy = (_id: string, user: any) => {
  if (!user) return 'Unknown';

  if (_id === user._id || _id === user) return 'You';

  return `${user?.firstName} ${user?.lastName}`;
};

const NotesTimeline = ({ type, categoryId, height = 600 }: IProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState<ELoading>(ELoading.NONE);
  const [notes, setNotes] = useState<INotes[] | []>([]);
  const [open, setOpen] = useState<IOpen>(initialOpen);
  const [currentNote, setCurrentNote] = useState<any>(null);
  const { user } = useAuth();

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: 'createdAt',
  });

  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (type === ENotesType.OTHER) getNotes(page, rowsPerPage, undefined);
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (type !== ENotesType.OTHER && categoryId) getNotes(page, rowsPerPage, categoryId);
  }, [categoryId, page, rowsPerPage]);

  const getNotes = async (page: number, rowsPerPage: number, categoryId: string | undefined) => {
    setLoading(ELoading.LIST);

    const params: IParams = {
      page: page + 1,
      skip: page * rowsPerPage,
      limit: rowsPerPage,
    };

    const finalParams = { ...params, [type]: categoryId, type };

    console.log('log:', finalParams);

    const res = await getApi({
      url: NotesApi.base,
      showToast: false,
      params: categoryId ? finalParams : { ...params, type },
    });

    setLoading(ELoading.NONE);

    if (res.error) return;

    setNotes(res.data.notes);
    setTotalCount(res.data.count);
  };

  const onRemove = async (_id: string | undefined) => {
    const res = await deleteApi({
      url: `${NotesApi.base}/${_id}`,
      showToast: true,
    });

    if (!res.error) {
      const updatedNotes = notes.filter((note) => note._id !== _id);
      setNotes(updatedNotes);
    }
  };

  const onOpen = (modal: EOpen) => setOpen({ ...open, modal });

  const onClose = () => {
    setOpen(initialOpen);
    setCurrentNote(null);
  };

  const onUpdate = (note: any) => {
    setCurrentNote(note);
    onOpen(EOpen.NOTE);
  };

  const onViewAttachments = (note: any) => {
    setCurrentNote(note);
    onOpen(EOpen.ATTACHMENT);
  };

  const setNewNotes = (note: INotes) => {
    let temp = [...notes];
    if (currentNote) {
      const index = notes.findIndex((item) => item._id === currentNote._id);
      temp[index] = { ...currentNote, ...note };
    } else {
      temp = [note, ...temp];
    }

    setNotes(temp);
    setTotalCount(temp.length);
    onClose();
  };

  return (
    <Card>
      <CardHeader
        action={
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => onOpen(EOpen.NOTE)}
          >
            Add Note
          </Button>
        }
        title="Notes"
      />

      {loading !== ELoading.NONE ? (
        <LoadingBox />
      ) : (
        <>
          {notes.length ? (
           <Box
           sx={{
             px: 2,
             pt: 2,
             [theme.breakpoints.down('sm')]: {
               paddingLeft: 0, // Set padding-left to 0 on mobile
               paddingRight: 0, // Set padding-right to 0 on mobile
             },
           }}
         >
              <Box height={height} sx={{ pt: 2, overflow:'auto',
                                      scrollbarWidth: 'none' /* Firefox */,
                                      '&::-webkit-scrollbar': {
                                        width: '0.4em',
                                      },
            }}>
                {/* <Timeline style={{flexDirection:'row'}}> */}
                <Timeline
                  style={{ display: 'grid', gridTemplateColumns: isMobile ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)', gap: '15px', 
                  alignItems: isMobile ? 'center' : 'flex-start', // Center vertically on mobile
                  justifyContent: isMobile ? 'center' : 'flex-start', 
                  }}
                >
                  {notes.map((note) => (
                    <div
                      key={note._id}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        // justifyContent: 'space-around',
                      }}
                    >
                      <Card
                        sx={{
                          width: '100%',
                          height: '420px',
                          minWidth: '330px',
                          overflowY: 'auto', 
                          // paddingLeft:'28px',  
                          scrollbarWidth: 'none' /* Firefox */,
                          '&::-webkit-scrollbar': {
                            width: '0.4em',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >

                        <TimelineContent
                          sx={{ py: '12px', px: 2, display: 'flex', alignItems: 'center'}}
                        >
                          {/* Icon aligned to the left */}
                          <div style={{ marginRight: '16px' }}>
                            <TimelineSeparator>
                              <TimelineConnector />
                              <TimelineDot color="primary">
                                <Icon icon="tabler:notes" />
                              </TimelineDot>
                              <TimelineConnector />
                            </TimelineSeparator>
                          </div>

                          <Typography
                            variant="h6"
                            component="span"
                            sx={{ flex: 1, cursor: 'pointer', textAlign: 'center',  }}
                            onClick={() => onUpdate(note)}
                            
                            
                          >
                            {note.title}
                          </Typography>

                          <div style={{ marginLeft: '16px' }}>
                            <MoreMenu
                              onDelete={() => onRemove(note._id)}
                              id={note._id}
                              editRootPath={() => onUpdate(note)}
                              
                            />
                          </div>
                        </TimelineContent>

                        <Box sx={{ pl: 0,height:'200px', wordWrap: 'wrap', py: '12px', px: 2, overflowY: 'auto', paddingLeft:'30px', paddingRight:'10px',  scrollbarWidth: 'none',
                          '&::-webkit-scrollbar': {
                            width: '0.4em',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'transparent',
                          } }}>
                          <div dangerouslySetInnerHTML={{ __html: note.description }} />
                        </Box>

                        <hr style={{marginTop:'47px'}}/>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop:'20px'}}>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Typography
      variant="body2"
      component="span"
      sx={{ cursor: 'pointer' }}
      onClick={() => onViewAttachments(note)}
      align='left'
    >
      <IconButton color="primary" onClick={() => onViewAttachments(note)}>
        <Icon icon="iconamoon:attachment-light" />
      </IconButton>
      {note?.attachments?.length > 0 ? 'Attachments' : 'Add Files'}
    </Typography>
  </div>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
    <Typography
      variant="body2"
      component="span"
      sx={{ textAlign: 'center', }}
      align='left'
    >
      {note.createdAt ? fShortDate(note.createdAt) : 'N/A'}
    </Typography>
  </div>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ cursor: 'pointer', }}>
      <Typography
        variant="body2"
        component="span"
        color="dark"
      >
        Added By : {getCreatedBy(user?._id, note.user)}
      </Typography>
    </Box>
  </div>
</div>


                      </Card>
                    </div>
                  ))}
                </Timeline>
              </Box>

              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[10, 50, 100]}
                  component="div"
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />
              </Box>
            </Box>
          ) : (
            <NoTimelineBox />
          )}
        </>
      )}

      <CustomModal
        title={currentNote ? 'Update note' : 'Add Note'}
        open={open.modal === EOpen.NOTE}
        onClose={onClose}
        maxWidth="sm"
        minHeight={200}
      >
        <Box>
          <NotesCreate
            isEdit={currentNote ? true : false}
            currentNote={currentNote}
            type={type}
            categoryId={categoryId}
            callback={setNewNotes}
          />
        </Box>
      </CustomModal>

      <CustomModal
        title={`Note's attachments`}
        open={open.modal === EOpen.ATTACHMENT}
        onClose={onClose}
        maxWidth="sm"
        minHeight={200}
      >
        <Box>
          {currentNote && (
            <Box sx={{ pt: 2 }}>
              <NotesMediaForm
                currentNote={currentNote}
                onClose={onClose}
                setNewNotes={setNewNotes}
              />
            </Box>
          )}
        </Box>
      </CustomModal>
    </Card>
  );
};

export default NotesTimeline;

const LoadingBox = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
      <CircularProgress size={28} />
    </Box>
  );
};

const NoTimelineBox = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
      <Typography>Notes not added yet! 😑</Typography>
    </Box>
  );
};
