import * as Yup from 'yup';

import { IEmailTemplate } from '../interfaces';
import { EEmailTemplateCategory, EEmailTemplateStatus, EEmailTemplateType } from '../enums';

export class CreateEmailTemplateSchema {
  schema;

  initialValues: IEmailTemplate;

  updateValues: IEmailTemplate;

  constructor() {
    this.schema = Yup.object({
      name: Yup.string().required('Template name is required'),
      description: Yup.string().required('Template description is required'),
      status: Yup.string().required('Status required'),
      type: Yup.string().required('Type required'),
      category: Yup.string().required('Category required'),
      tag: Yup.string().optional(),
      subject: Yup.string().required('Template subject is required'),
    });

    this.initialValues = {
      name: '',
      description: '',
      status: EEmailTemplateStatus.INACTIVE,
      type: EEmailTemplateType.OTHER,
      category: EEmailTemplateCategory.OTHER,
      subject: '',
      content: '',
      tag: '',
      design: null,
      attachments: [],
    };

    this.updateValues = {
      ...this.initialValues,
      _id: '',
    };
  }
}
