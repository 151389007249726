// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
// import SvgIconStyle from '../../../components/SvgIconStyle';
import { GetIcon } from 'src/components';

// ----------------------------------------------------------------------

const ICONS = {
  blog: GetIcon('ic_blog'),
  cart: GetIcon('ic_cart'),
  chat: GetIcon('ic_chat'),
  mail: GetIcon('ic_mail'),
  user: GetIcon('ic_user'),
  kanban: GetIcon('ic_kanban'),
  banking: GetIcon('ic_banking'),
  booking: GetIcon('ic_booking'),
  invoice: GetIcon('ic_invoice'),
  calendar: GetIcon('ic_calendar'),
  ecommerce: GetIcon('ic_ecommerce'),
  analytics: GetIcon('ic_analytics'),
  dashboard: GetIcon('ic_dashboard'),
  menuItem: GetIcon('ic_menu_item'),
  stop: GetIcon('icons8-stop'),
  search: GetIcon('search-sort'),
  contacts: GetIcon('contacts'),
  projects: GetIcon('project'),
  credentials: GetIcon('credentials'),
  campaign: GetIcon('campaign'),
  campaignGroups: GetIcon('campaign-groups'),
  individualCampaign: GetIcon('campaign'),
  technologies: GetIcon('technology'),
  link: GetIcon('link'),
  query: GetIcon('query'),
  industry: GetIcon('industry'),
  settings: GetIcon('settings'),
  offer: GetIcon('offer'),
  emails: GetIcon('emails'),
  objectTypes: GetIcon('object-types'),
  users: GetIcon('users'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [{ title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard }],
  },

  // SCRAPED DATA
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'Users',
        path: PATH_DASHBOARD.users.root,
        icon: ICONS.users,
        children: [
          { title: 'list', path: PATH_DASHBOARD.users.list },
          { title: 'create', path: PATH_DASHBOARD.users.create },
        ],
      },

      // Campaign
      {
        title: 'Campaign',
        path: PATH_DASHBOARD.campaign.root,
        icon: ICONS.campaign,
        children: [
          { title: 'list', path: PATH_DASHBOARD.campaign.list },
          { title: 'create', path: PATH_DASHBOARD.campaign.create },
        ],
      },

      // Campaign Groups
      {
        title: 'Campagin Groups',
        path: PATH_DASHBOARD.campaignGroups.root,
        icon: ICONS.campaignGroups,
        children: [
          { title: 'list', path: PATH_DASHBOARD.campaignGroups.list },
          { title: 'create', path: PATH_DASHBOARD.campaignGroups.create },
        ],
      },

      // Email Templates
      {
        title: 'Email Templates',
        path: PATH_DASHBOARD.emailTemplates.root,
        icon: ICONS.mail,
        children: [
          { title: 'list', path: PATH_DASHBOARD.emailTemplates.list },
          { title: 'create', path: PATH_DASHBOARD.emailTemplates.create },
        ],
      },

      // Email Template Groups
      {
        title: 'Email Template Groups',
        path: PATH_DASHBOARD.emailTemplateGroups.root,
        icon: ICONS.emails,
        children: [
          { title: 'list', path: PATH_DASHBOARD.emailTemplateGroups.list },
          { title: 'create', path: PATH_DASHBOARD.emailTemplateGroups.create },
        ],
      },

      //Search Results
      {
        title: 'Search Results',
        path: PATH_DASHBOARD.searchResults.root,
        icon: ICONS.search,
        children: [
          { title: 'list', path: PATH_DASHBOARD.searchResults.list },
          { title: 'create', path: PATH_DASHBOARD.searchResults.create },
        ],
      },

      //Contacts
      {
        title: 'Contacts',
        path: PATH_DASHBOARD.contacts.root,
        icon: ICONS.contacts,
        children: [
          { title: 'list', path: PATH_DASHBOARD.contacts.list },
          { title: 'create', path: PATH_DASHBOARD.contacts.create },
        ],
      },
    ],
  },

  // Employees
  // ----------------------------------------------------------------------
  {
    subheader: 'manage employees',
    items: [
      // Projects
      {
        title: 'Employees',
        path: PATH_DASHBOARD.employee.root,
        icon: ICONS.projects,
        children: [
          { title: 'list', path: PATH_DASHBOARD.employee.list },
          { title: 'create', path: PATH_DASHBOARD.employee.create },
        ],
      },
    ],
  },
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'Users',
        path: PATH_DASHBOARD.users.root,
        icon: ICONS.users,
        children: [
          { title: 'list', path: PATH_DASHBOARD.users.list },
          { title: 'create', path: PATH_DASHBOARD.users.create },
        ],
      },

      // Campaign
      {
        title: 'Campaign',
        path: PATH_DASHBOARD.campaign.root,
        icon: ICONS.campaign,
        children: [
          { title: 'list', path: PATH_DASHBOARD.campaign.list },
          { title: 'create', path: PATH_DASHBOARD.campaign.create },
        ],
      },

      // Campaign Groups
      {
        title: 'Campagin Groups',
        path: PATH_DASHBOARD.campaignGroups.root,
        icon: ICONS.campaignGroups,
        children: [
          { title: 'list', path: PATH_DASHBOARD.campaignGroups.list },
          { title: 'create', path: PATH_DASHBOARD.campaignGroups.create },
        ],
      },

      //Individual-Campaign
      {
        title: 'Individual Campaign',
        path: PATH_DASHBOARD.individualCampaign.root,
        icon: ICONS.individualCampaign,
        children: [
          { title: 'list', path: PATH_DASHBOARD.individualCampaign.list },
          { title: 'create', path: PATH_DASHBOARD.individualCampaign.create },
        ],
      },

      // Email Templates
      {
        title: 'Email Templates',
        path: PATH_DASHBOARD.emailTemplates.root,
        icon: ICONS.mail,
        children: [
          { title: 'list', path: PATH_DASHBOARD.emailTemplates.list },
          { title: 'create', path: PATH_DASHBOARD.emailTemplates.create },
        ],
      },

      // Email Template Groups
      {
        title: 'Email Template Groups',
        path: PATH_DASHBOARD.emailTemplateGroups.root,
        icon: ICONS.emails,
        children: [
          { title: 'list', path: PATH_DASHBOARD.emailTemplateGroups.list },
          { title: 'create', path: PATH_DASHBOARD.emailTemplateGroups.create },
        ],
      },

      //Search Results
      {
        title: 'Search Results',
        path: PATH_DASHBOARD.searchResults.root,
        icon: ICONS.search,
        children: [
          { title: 'list', path: PATH_DASHBOARD.searchResults.list },
          { title: 'create', path: PATH_DASHBOARD.searchResults.create },
        ],
      },

      //Contacts
      {
        title: 'Contacts',
        path: PATH_DASHBOARD.contacts.root,
        icon: ICONS.contacts,
        children: [
          { title: 'list', path: PATH_DASHBOARD.contacts.list },
          { title: 'create', path: PATH_DASHBOARD.contacts.create },
        ],
      },

      //deals
      {
        title: 'Deals',
        path: PATH_DASHBOARD.deals.root,
        icon: ICONS.campaign,
        children: [
          { title: 'list', path: PATH_DASHBOARD.deals.list },
          { title: 'create', path: PATH_DASHBOARD.deals.create },
        ],
      },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'Forms And Scraped',
    items: [
      { title: 'LinkedIn Data', path: PATH_DASHBOARD.linkedInData, icon: ICONS.analytics },
      { title: 'Careers', path: PATH_DASHBOARD.carrier, icon: ICONS.invoice },
      {
        title: 'Contact Forms',
        path: PATH_DASHBOARD.contactForms.list,
        icon: ICONS.chat,
      },
    ],
  },

  // MANAGE PROJECTS
  // ----------------------------------------------------------------------
  {
    subheader: 'manage projects',
    items: [
      // Projects
      {
        title: 'Projects',
        path: PATH_DASHBOARD.project.root,
        icon: ICONS.projects,
        children: [
          { title: 'list', path: PATH_DASHBOARD.project.list },
          { title: 'create', path: PATH_DASHBOARD.project.create },
        ],
      },
      {
        title: 'Task',
        path: PATH_DASHBOARD.task.root,
        icon: ICONS.projects,
        children: [
          { title: 'list', path: PATH_DASHBOARD.task.list },
          { title: 'create', path: PATH_DASHBOARD.task.create },
        ],
      },

      // Project Credentials
      {
        title: 'Project Credentials',
        path: PATH_DASHBOARD.projectcredentials.root,
        icon: ICONS.credentials,
        children: [
          { title: 'list', path: PATH_DASHBOARD.projectcredentials.list },
          { title: 'create', path: PATH_DASHBOARD.projectcredentials.create },
        ],
      },

      // Proposal Types
      {
        title: 'Proposal Types',
        path: PATH_DASHBOARD.proposalTypes.root,
        icon: ICONS.offer,
        children: [
          { title: 'list', path: PATH_DASHBOARD.proposalTypes.list },
          { title: 'create', path: PATH_DASHBOARD.proposalTypes.create },
        ],
      },
    ],
  },

  // Raw Information
  // ----------------------------------------------------------------------
  {
    subheader: 'Raw Information',
    items: [
      //Industries
      {
        title: 'Industries',
        path: PATH_DASHBOARD.industries.root,
        icon: ICONS.settings,
        children: [
          { title: 'list', path: PATH_DASHBOARD.industries.list },
          { title: 'create', path: PATH_DASHBOARD.industries.create },
        ],
      },

      //Subindustries
      {
        title: 'Sub Industries',
        path: PATH_DASHBOARD.subIndustries.root,
        icon: ICONS.industry,
        children: [
          { title: 'list', path: PATH_DASHBOARD.subIndustries.list },
          { title: 'create', path: PATH_DASHBOARD.subIndustries.create },
        ],
      },

      //Technologies
      {
        title: 'Technologies',
        path: PATH_DASHBOARD.technologies.root,
        icon: ICONS.technologies,
        children: [
          { title: 'list', path: PATH_DASHBOARD.technologies.list },
          { title: 'create', path: PATH_DASHBOARD.technologies.create },
        ],
      },

      //SearchQuerey
      {
        title: 'Search Query',
        path: PATH_DASHBOARD.searchQueries.root,
        icon: ICONS.query,
        children: [
          { title: 'list', path: PATH_DASHBOARD.searchQueries.list },
          { title: 'create', path: PATH_DASHBOARD.searchQueries.create },
        ],
      },

      //Scrap from Url
      {
        title: 'Scrap From URL',
        path: PATH_DASHBOARD.scrapFromUrl.root,
        icon: ICONS.link,
        children: [
          { title: 'list', path: PATH_DASHBOARD.scrapFromUrl.list },
          { title: 'create', path: PATH_DASHBOARD.scrapFromUrl.create },
        ],
      },

      //Blocked Contents
      {
        title: 'Blocked Contents',
        path: PATH_DASHBOARD.blockContents.root,
        icon: ICONS.stop,
        children: [
          { title: 'list', path: PATH_DASHBOARD.blockContents.list },
          { title: 'create', path: PATH_DASHBOARD.blockContents.create },
        ],
      },

      //Object Types
      {
        title: 'Object Types',
        path: PATH_DASHBOARD.objectTypes.root,
        icon: ICONS.objectTypes,
        children: [
          { title: 'list', path: PATH_DASHBOARD.objectTypes.list },
          { title: 'create', path: PATH_DASHBOARD.objectTypes.create },
        ],
      },

      // Blogs
      // {
      //   title: 'Blogs',
      //   path: PATH_DASHBOARD.proposalTypes.root,
      //   icon: ICONS.dashboard,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.proposalTypes.list },
      //     { title: 'create', path: PATH_DASHBOARD.blog.new },
      //   ],
      // },
    ],
  },

  // DEMO MENU STATES
  {
    subheader: 'Templates',
    items: [
      {
        title: 'mail',
        path: PATH_DASHBOARD.mail.root,
        icon: ICONS.mail,
        info: <Label color="error">+32</Label>,
      },
      { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
      { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
      { title: 'Blogs', path: PATH_DASHBOARD.blog.root, icon: ICONS.kanban },
      { title: 'Create Blog', path: PATH_DASHBOARD.blog.new, icon: ICONS.kanban },
      { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
      {
        // default roles : All roles can see this entry.
        // roles: ['user'] Only users can see this item.
        // roles: ['admin'] Only admin can see this item.
        // roles: ['admin', 'manager'] Only admin/manager can see this item.
        // Reference from 'src/guards/RoleBasedGuard'.
        title: 'item_by_roles',
        path: PATH_DASHBOARD.permissionDenied,
        icon: ICONS.menuItem,
        roles: ['admin'],
        caption: 'only_admin_can_see_this_item',
      },
      {
        title: 'menu_level_1',
        path: '#1',
        icon: ICONS.menuItem,
        children: [
          { title: 'menu_level_2', path: '#2', disabled: true },
          {
            title: 'menu_level_2',
            path: '#3',
            children: [
              { title: 'menu_level_3', path: '#4' },
              { title: 'menu_level_3', path: '#5' },
            ],
          },
        ],
      },
      { title: 'item_disabled', path: '#disabled', icon: ICONS.menuItem, disabled: true },
      {
        title: 'item_label',
        path: '#label',
        icon: ICONS.menuItem,
        info: (
          <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
            NEW
          </Label>
        ),
      },
      { title: 'item_caption', path: '#caption', icon: ICONS.menuItem, caption: 'description' },
    ],
  },
];

export default navConfig;
