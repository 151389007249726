import { EContentType } from 'src/common/enums';

export const getPrimaryEmail = (emails: any[]): string => {
  if (!emails || !emails?.length) return 'N/A';

  return emails.find((item) => item.type === EContentType.PRIMARY.toString()).email || 'N/A';
};

export const getPrimaryPhoneNumber = (phoneNumbers: any[]): string => {
  if (!phoneNumbers || !phoneNumbers?.length) return 'N/A';

  return (
    phoneNumbers.find((item) => item.type === EContentType.PRIMARY.toString()).phoneNumber || 'N/A'
  );
};
