// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
import { ICONS } from 'src/common/constants';

const BDENavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [{ title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard }],
  },

  // SCRAPED DATA
  // ----------------------------------------------------------------------
  {
    subheader: 'scraped data',
    items: [
      //Search Results
      {
        title: 'Search Results',
        path: PATH_DASHBOARD.searchResults.root,
        icon: ICONS.search,
        children: [
          { title: 'list', path: PATH_DASHBOARD.searchResults.list },
          { title: 'create', path: PATH_DASHBOARD.searchResults.create },
        ],
      },
      //LinkedIn Data
      {
        title: 'LinkedIn Data',
        path: PATH_DASHBOARD.linkedInData1.root,
        icon: ICONS.analytics,
        children: [
          { title: 'list', path: PATH_DASHBOARD.linkedInData1.list },
          { title: 'create', path: PATH_DASHBOARD.linkedInData1.create },
        ],
      },

      { title: 'Careers', path: PATH_DASHBOARD.carrier, icon: ICONS.invoice },
      {
        title: 'Contact Forms',
        path: PATH_DASHBOARD.contactForms.list,
        icon: ICONS.chat,
      },
      {
        title: 'Clutch',
        path: PATH_DASHBOARD.clutch.list,
        icon: ICONS.clutch,
      },
      {
        title: 'techbehemoth',
        path: PATH_DASHBOARD.techbehemoth.list,
        icon: ICONS.techbehemoth,
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      //NOTES
      {
        title: 'Notes',
        path: PATH_DASHBOARD.notes.root,
        icon: ICONS.notes,
        // children: [
        //   { title: 'list', path: PATH_DASHBOARD.bookmarks.list },
        //   { title: 'create', path: PATH_DASHBOARD.bookmarks.create },
        // ],
      },
      // Campaign
      {
        title: 'Campaign',
        path: PATH_DASHBOARD.campaign.root,
        icon: ICONS.campaign,
        children: [
          { title: 'list', path: PATH_DASHBOARD.campaign.list },
          { title: 'create', path: PATH_DASHBOARD.campaign.create },
        ],
      },

      // Campaign Groups
      {
        title: 'Campagin Groups',
        path: PATH_DASHBOARD.campaignGroups.root,
        icon: ICONS.campaignGroups,
        children: [
          { title: 'list', path: PATH_DASHBOARD.campaignGroups.list },
          { title: 'create', path: PATH_DASHBOARD.campaignGroups.create },
        ],
      },

      // Email Templates
      {
        title: 'Email Templates',
        path: PATH_DASHBOARD.emailTemplates.root,
        icon: ICONS.mail,
        children: [
          { title: 'list', path: PATH_DASHBOARD.emailTemplates.list },
          { title: 'create', path: PATH_DASHBOARD.emailTemplates.create },
        ],
      },

      // Email Template Groups
      {
        title: 'Email Template Groups',
        path: PATH_DASHBOARD.emailTemplateGroups.root,
        icon: ICONS.emails,
        children: [
          { title: 'list', path: PATH_DASHBOARD.emailTemplateGroups.list },
          { title: 'create', path: PATH_DASHBOARD.emailTemplateGroups.create },
        ],
      },

      //Contacts
      {
        title: 'Contacts',
        path: PATH_DASHBOARD.contacts.root,
        icon: ICONS.contacts,
        children: [
          { title: 'list', path: PATH_DASHBOARD.contacts.list },
          { title: 'create', path: PATH_DASHBOARD.contacts.create },
        ],
      },

      //deals
      {
        title: 'Deals',
        path: PATH_DASHBOARD.deals.root,
        icon: ICONS.campaign,
        children: [
          { title: 'list', path: PATH_DASHBOARD.deals.list },
          { title: 'create', path: PATH_DASHBOARD.deals.create },
        ],
      },
    ],
  },
];

export default BDENavConfig;
