export enum EEmailTemplateStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum EEmailTemplateCategory {
  CAMPAIGN = 'campaign',
  CONTACT_FORMS_AUTO_REPLY = 'contact-forms-auto-reply',
  OTHER = 'other',
}

export enum EEmailTemplateType {
  CONSULTANCY_FORM_REPLY = 'consultancy-form-reply',
  NEWSLETTER_FORM_REPLY = 'newsletter-form-reply',
  CAREER_FORM_REPLY = 'career-form-reply',
  CONTACT_US_FORM_REPLY = 'contact-us-form-reply',
  CONTACT_FORM_ADMIN_NOTIFY = 'contact-form-admin-notify',
  OTHER = 'other',
  TEST = 'test',
}
