export enum EIndividualCampaignTimelinesStatus {
  'CREATED' = 'created',
  'UNSEEN' = 'unseen',
  'SEEN' = 'seen',
  'REPLIED' = 'replied',
  'FINALIZED' = 'finalized',
}

export enum EIndividualCampaignTimelinesReplyType {
  'POSITIVE' = 'positive',
  'NEGATIVE' = 'negative',
  'NEUTRAL' = 'neutral',
  'NO_REPLY' = 'no-reply',
}
