// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
import { ICONS } from 'src/common/constants';
// import DEVNavConfig from './DEVNavConfig';
// import { Label } from 'src/components';

// const temp = process.env.NODE_ENV === 'development' ? DEVNavConfig : [];
const temp = process.env.NODE_ENV === 'development' ? [] : [];

const ROOTNavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Analytics',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      {
        title: 'Campaign A/B Testing',
        path: PATH_DASHBOARD.general.campaignABTesting,
        icon: ICONS.compaignABTesting,
      },
    ],
  },

  // SCRAPED DATA
  // ----------------------------------------------------------------------
  {
    subheader: 'scraped data',
    items: [
      //Search Results
      {
        title: 'Search Results',
        path: PATH_DASHBOARD.searchResults.root,
        icon: ICONS.search,
        children: [
          { title: 'list', path: PATH_DASHBOARD.searchResults.list },
          { title: 'create', path: PATH_DASHBOARD.searchResults.create },
        ],
      },
      //Linkedin Data
      {
        title: 'LinkedIn Data',
        path: PATH_DASHBOARD.linkedInData1.list,
        icon: ICONS.analytics,
        children: [
          { title: 'list', path: PATH_DASHBOARD.linkedInData1.list },
          { title: 'create', path: PATH_DASHBOARD.linkedInData1.create },
        ],
      },
      { title: 'Careers', path: PATH_DASHBOARD.carrier, icon: ICONS.invoice },
      {
        title: 'Contact Forms',
        path: PATH_DASHBOARD.contactForms.list,
        icon: ICONS.chat,
      },
      { title: 'Tracker', path: PATH_DASHBOARD.tracker, icon: ICONS.invoice },
      {
        title: 'Clutch',
        path: PATH_DASHBOARD.clutch.list,
        icon: ICONS.clutch,
      },
      {
        title: 'techbehemoth',
        path: PATH_DASHBOARD.techbehemoth.list,
        icon: ICONS.techbehemoth,
      },
    ],
  },

  {
    subheader: 'Scrapper Settings',
    items: [
      //SearchQuerey
      {
        title: 'Search Query',
        path: PATH_DASHBOARD.searchQueries.root,
        icon: ICONS.query,
        children: [
          { title: 'list', path: PATH_DASHBOARD.searchQueries.list },
          { title: 'create', path: PATH_DASHBOARD.searchQueries.create },
        ],
      },
      //Industries
      {
        title: 'Industries',
        path: PATH_DASHBOARD.industries.root,
        icon: ICONS.settings,
        children: [
          { title: 'list', path: PATH_DASHBOARD.industries.list },
          { title: 'create', path: PATH_DASHBOARD.industries.create },
        ],
      },

      //Subindustries
      {
        title: 'Sub Industries',
        path: PATH_DASHBOARD.subIndustries.root,
        icon: ICONS.industry,
        children: [
          { title: 'list', path: PATH_DASHBOARD.subIndustries.list },
          { title: 'create', path: PATH_DASHBOARD.subIndustries.create },
        ],
      },

      //Technologies
      {
        title: 'Technologies',
        path: PATH_DASHBOARD.technologies.root,
        icon: ICONS.technologies,
        children: [
          { title: 'list', path: PATH_DASHBOARD.technologies.list },
          { title: 'create', path: PATH_DASHBOARD.technologies.create },
        ],
      },

      //Scrap from Url
      {
        title: 'Scrap From URL',
        path: PATH_DASHBOARD.scrapFromUrl.root,
        icon: ICONS.link,
        children: [
          { title: 'list', path: PATH_DASHBOARD.scrapFromUrl.list },
          { title: 'create', path: PATH_DASHBOARD.scrapFromUrl.create },
        ],
      },

      //Blocked Contents
      {
        title: 'Blocked Contents',
        path: PATH_DASHBOARD.blockContents.root,
        icon: ICONS.stop,
        children: [
          { title: 'list', path: PATH_DASHBOARD.blockContents.list },
          { title: 'create', path: PATH_DASHBOARD.blockContents.create },
        ],
      },

      //Object Types
      {
        title: 'Object Types',
        path: PATH_DASHBOARD.objectTypes.root,
        icon: ICONS.objectTypes,
        children: [
          { title: 'list', path: PATH_DASHBOARD.objectTypes.list },
          { title: 'create', path: PATH_DASHBOARD.objectTypes.create },
        ],
      },

      // Blogs
      // {
      //   title: 'Blogs',
      //   path: PATH_DASHBOARD.proposalTypes.root,
      //   icon: ICONS.dashboard,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.proposalTypes.list },
      //     { title: 'create', path: PATH_DASHBOARD.blog.new },
      //   ],
      // },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'Users',
        path: PATH_DASHBOARD.users.root,
        icon: ICONS.users,
        children: [
          { title: 'list', path: PATH_DASHBOARD.users.list },
          { title: 'create', path: PATH_DASHBOARD.users.create },
        ],
      },

      //Contacts
      {
        title: 'Contacts',
        path: PATH_DASHBOARD.contacts.root,
        icon: ICONS.contacts,
        children: [
          { title: 'list', path: PATH_DASHBOARD.contacts.list },
          { title: 'create', path: PATH_DASHBOARD.contacts.create },
        ],
      },
      //Bookmarks
      {
        title: 'Bookmarks',
        path: PATH_DASHBOARD.bookmarks.root,
        icon: ICONS.bookmarks,
        children: [
          { title: 'list', path: PATH_DASHBOARD.bookmarks.list },
          { title: 'create', path: PATH_DASHBOARD.bookmarks.create },
        ],
      },
      //NOTES
      {
        title: 'Notes',
        path: PATH_DASHBOARD.notes.root,
        icon: ICONS.notes,
        // children: [
        //   { title: 'list', path: PATH_DASHBOARD.bookmarks.list },
        //   { title: 'create', path: PATH_DASHBOARD.bookmarks.create },
        // ],
      },
      //SMTP accounts
      {
        title: 'SMTP Accounts',
        path: PATH_DASHBOARD.smtpAccounts.root,
        icon: ICONS.smtpAccounts,
        children: [
          { title: 'list', path: PATH_DASHBOARD.smtpAccounts.list },
          { title: 'create', path: PATH_DASHBOARD.smtpAccounts.create },
        ],
      },

      //IMAP accounts
      {
        title: 'IMAP Accounts',
        path: PATH_DASHBOARD.imapAccounts.root,
        icon: ICONS.imapAccounts,
        children: [
          { title: 'list', path: PATH_DASHBOARD.imapAccounts.list },
          { title: 'create', path: PATH_DASHBOARD.imapAccounts.create },
        ],
      },

      //Reminders
      {
        title: 'Reminders',
        path: PATH_DASHBOARD.reminders.root,
        icon: ICONS.reminders,
        children: [
          { title: 'list', path: PATH_DASHBOARD.reminders.list },
          { title: 'create', path: PATH_DASHBOARD.reminders.create },
        ],
      },

      //Custom Mail
      {
        title: 'custom mail',
        path: PATH_DASHBOARD.customMail.root,
        icon: ICONS.mail,
        children: [
          { title: 'compose', path: PATH_DASHBOARD.customMail.compose },
          { title: 'inbox', path: PATH_DASHBOARD.customMail.inbox },
        ],
        // info: <Label color="error">+32</Label>,
      },

      {
        title: 'Deals',
        path: PATH_DASHBOARD.deals.root,
        icon: ICONS.campaign,
        children: [
          { title: 'list', path: PATH_DASHBOARD.deals.list },
          { title: 'create', path: PATH_DASHBOARD.deals.create },
        ],
      },
    ],
  },

  // CAMPAIGN MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Manage Campaign',
    items: [
      // Campaign
      {
        title: 'Campaign',
        path: PATH_DASHBOARD.campaign.root,
        icon: ICONS.campaign,
        children: [
          { title: 'list', path: PATH_DASHBOARD.campaign.list },
          { title: 'create', path: PATH_DASHBOARD.campaign.create },
        ],
      },

      // Campaign Groups
      {
        title: 'Campagin Groups',
        path: PATH_DASHBOARD.campaignGroups.root,
        icon: ICONS.campaignGroups,
        children: [
          { title: 'list', path: PATH_DASHBOARD.campaignGroups.list },
          { title: 'create', path: PATH_DASHBOARD.campaignGroups.create },
        ],
      },

      //Individual campaign
      {
        title: 'Individual Campaign',
        path: PATH_DASHBOARD.individualCampaign.root,
        icon: ICONS.campaign,
        // children: [
        //   { title: 'list', path: PATH_DASHBOARD.individualCampaign.list },
        // { title: 'create', path: PATH_DASHBOARD.individualCampaign.create },
        // ],
      },
      {
        title: 'Timeline',
        path: PATH_DASHBOARD.timeline.list,
        icon: ICONS.campaign,
        children: [{ title: 'list', path: PATH_DASHBOARD.timeline.list }],
      },

      // Email Templates
      {
        title: 'Email Templates',
        path: PATH_DASHBOARD.emailTemplates.root,
        icon: ICONS.mail,
        children: [
          { title: 'list', path: PATH_DASHBOARD.emailTemplates.list },
          { title: 'create', path: PATH_DASHBOARD.emailTemplates.create },
        ],
      },

      // Email Template Groups
      {
        title: 'Email Template Groups',
        path: PATH_DASHBOARD.emailTemplateGroups.root,
        icon: ICONS.emails,
        children: [
          { title: 'list', path: PATH_DASHBOARD.emailTemplateGroups.list },
          { title: 'create', path: PATH_DASHBOARD.emailTemplateGroups.create },
        ],
      },

      {
        title: 'External Contacts',
        path: PATH_DASHBOARD.externalContacts.root,
        icon: ICONS.emails,
        children: [
          { title: 'list', path: PATH_DASHBOARD.externalContacts.list },
          { title: 'create', path: PATH_DASHBOARD.externalContacts.create },
        ],
      },
    ],
  },

  // MANAGE PROJECTS
  // ----------------------------------------------------------------------
  {
    subheader: 'manage projects',
    items: [
      // Projects
      {
        title: 'Projects',
        path: PATH_DASHBOARD.project.root,
        icon: ICONS.projects,
        children: [
          { title: 'list', path: PATH_DASHBOARD.project.list },
          { title: 'create', path: PATH_DASHBOARD.project.create },
        ],
      },
      {
        title: 'Task',
        path: PATH_DASHBOARD.task.root,
        icon: ICONS.projects,
        children: [
          { title: 'list', path: PATH_DASHBOARD.task.list },
          { title: 'create', path: PATH_DASHBOARD.task.create },
        ],
      },

      // Project Credentials
      {
        title: 'Project Credentials',
        path: PATH_DASHBOARD.projectcredentials.root,
        icon: ICONS.credentials,
        children: [
          { title: 'list', path: PATH_DASHBOARD.projectcredentials.list },
          { title: 'create', path: PATH_DASHBOARD.projectcredentials.create },
        ],
      },

      // Proposal Types
      {
        title: 'Proposal Types',
        path: PATH_DASHBOARD.proposalTypes.root,
        icon: ICONS.offer,
        children: [
          { title: 'list', path: PATH_DASHBOARD.proposalTypes.list },
          { title: 'create', path: PATH_DASHBOARD.proposalTypes.create },
        ],
      },
    ],
  },

  // Employees
  // ----------------------------------------------------------------------
  {
    subheader: 'manage employees',
    items: [
      // Projects
      {
        title: 'Employees',
        path: PATH_DASHBOARD.employee.root,
        icon: ICONS.projects,
        children: [
          { title: 'list', path: PATH_DASHBOARD.employee.list },
          { title: 'create', path: PATH_DASHBOARD.employee.create },
        ],
      },
    ],
  },

  // Scrapper Settings
  // ----------------------------------------------------------------------

  ...temp,
];

export default ROOTNavConfig;
