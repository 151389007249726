import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { toast } from 'react-hot-toast';
import TwoFAQRCodeInput from './TwoFACodeInput';

// ----------------------------------------------------------------------

const CTabs = {
  CREDENTIALS: 'credentials',
  AUTH_CODE: 'auth_code',
};

type FormValuesProps = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm() {
  const { login, twoFAAuthenticate } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);
  const [tab, setTab] = useState(CTabs.CREDENTIALS);
  const [isLoading, setIsLoading] = useState(false);
  const [authData, setAuthData] = useState({
    username: '',
    twoFAKey: '',
  });

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const handleOnVerify = async (code: string) => {
    if (authData.username === '' || code === '' || authData.twoFAKey === '')
      return toast.error('Username, auth key or code incorrect!');
    setIsLoading(true);
    await twoFAAuthenticate(authData.username, code, authData.twoFAKey);
    setIsLoading(false);
  };

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const twoFAKey: any = await login(data.email, data.password);

      if (twoFAKey) {
        setTab(CTabs.AUTH_CODE);
        setAuthData({ ...authData, twoFAKey, username: data.email });
      }
    } catch (error) {
      console.error(error);

      reset();

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {CTabs.CREDENTIALS === tab ? (
        <>
          <Stack spacing={3}>
            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

            <RHFTextField name="email" label="Email address" />

            <RHFTextField
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <RHFCheckbox name="remember" label="Remember me" />
            <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
              Forgot password?
            </Link>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Login
          </LoadingButton>
        </>
      ) : (
        <TwoFAQRCodeInput
          onVerify={handleOnVerify}
          loading={isLoading}
          goBack={() => setTab(CTabs.CREDENTIALS)}
        />
      )}
    </FormProvider>
  );
}
