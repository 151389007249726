import * as Yup from 'yup';
import { ISmtpAccount } from '../interfaces';
import { ESmtpAccountsStatus } from '../enums';

export class CreateSmtpAccountSchema {
  schema;

  initialValues: ISmtpAccount;

  constructor() {
    this.schema = Yup.object({
      label: Yup.string().required('Label is required'),
      description: Yup.string().optional(),
      status: Yup.string()
        .oneOf(Object.values(ESmtpAccountsStatus), 'Invalid status')
        .required('Status is required'),
      username: Yup.string().required('Username is required'),
      password: Yup.string().required('Password is required'),
      from: Yup.string().email('Must be a valid email id!').required('From email is required'),
      server: Yup.string().required('Server is required'),
      port: Yup.number().integer().required('Port is required'),
      secure: Yup.boolean().required('Secure field is required'),
      bcc: Yup.array().of(Yup.string().email('Must be a valid email id!')).optional(),
      dailyLimit: Yup.object().shape({
        messageCount: Yup.number()
          .integer()
          .min(1, 'Message count must be at least 1')
          .required('Message count is required'),
        fixedDelay: Yup.number()
          .integer()
          .min(0, 'Fixed delay must be non-negative')
          .required('Fixed delay is required'),
      }),
      imap_accounts: Yup.string()
      // .oneOf(Object.values(ESmtpAccountsStatus), 'Please select IMAP account')
      .required('IMAP account is required'),
    });

    this.initialValues = {
      label: '',
      description: '',
      status: ESmtpAccountsStatus.ACTIVE,
      username: '',
      password: '',
      from: '',
      server: '',
      port: 0,
      secure: false,
      bcc: [],
      signature: '',
      dailyLimit: {
        messageCount: 200,
        fixedDelay: 30,
      },
      imap_accounts:''
    };
  }
}
