import { lazy } from 'react';
import Loadable from './Loadable';
import { GuestGuard } from 'src/guards';

const Login = Loadable(lazy(() => import('src/pages/auth/Login')));
const Register = Loadable(lazy(() => import('src/pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('src/pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('src/pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('src/pages/auth/VerifyCode')));

const AuthRoutes = {
  path: 'auth',
  children: [
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      ),
    },
    { path: 'login-unprotected', element: <Login /> },
    { path: 'register-unprotected', element: <Register /> },
    { path: 'reset-password', element: <ResetPassword /> },
    { path: 'new-password', element: <NewPassword /> },
    { path: 'verify', element: <VerifyCode /> },
  ],
};

export default AuthRoutes;
