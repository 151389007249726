import { EIndividualCampaignTypes } from '../enums';

export const getIndividualCampaignGroupKey = (type: EIndividualCampaignTypes) => {
  switch (type) {
    case EIndividualCampaignTypes.CONTACTS:
      return 'contact';
    case EIndividualCampaignTypes.CONTACT_FORMS:
      return 'contactForm';
    case EIndividualCampaignTypes.SEARCH_RESULTS:
      return 'searchResult';
    case EIndividualCampaignTypes.LINKEDIN:
      return 'linkedInData';
    default:
      return 'searchResult';
  }
};
