import { TextField, Box, Stack, MenuItem, Divider, Button, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SmtpAccountsApi, postApi } from 'src/common/apis';
import { TestSmtpAccountSchema } from 'src/common/schemas';
import { useFormik, Form, FormikProvider } from 'formik';
import { IEmailTemplate, ISmtpAccount } from 'src/common/interfaces';
import { useState } from 'react';

interface IProps {
  emailTemplates: IEmailTemplate[] | [];
  smtpAccount?: ISmtpAccount | null;
}

interface IResStatus {
  message: string;
  status: 'error' | 'success';
}

const TestSmtpAccountForm = ({ emailTemplates, smtpAccount }: IProps) => {
  const [resStatus, setResStatus] = useState<IResStatus | null>(null);
  const testSmtpAccountSchema = new TestSmtpAccountSchema();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: smtpAccount
      ? { ...testSmtpAccountSchema.initialValues, ...smtpAccount }
      : testSmtpAccountSchema.initialValues,
    validationSchema: testSmtpAccountSchema.schema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        await _handleSmtpSubmit(values);
      } catch (error) {
        setErrors(error);
      }
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, resetForm } = formik;

  const _handleSmtpSubmit = async (values: any) => {
    const res = await postApi({ url: SmtpAccountsApi.test, values, showToast: false });

    if (!res || res.error) return setResStatus({ status: 'error', message: res.message });

    setResStatus({ status: 'success', message: res.message });
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
            <TextField
              fullWidth
              label="Username"
              {...getFieldProps('username')}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
            />

            <TextField
              fullWidth
              label="Password"
              {...getFieldProps('password')}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
            <TextField
              type="email"
              fullWidth
              label="From"
              {...getFieldProps('from')}
              error={Boolean(touched.from && errors.from)}
              helperText={touched.from && errors.from}
            />

            <TextField
              fullWidth
              label="Server"
              {...getFieldProps('server')}
              error={Boolean(touched.server && errors.server)}
              helperText={touched.server && errors.server}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
            <TextField
              fullWidth
              type="number"
              label="Port"
              {...getFieldProps('port')}
              error={Boolean(touched.port && errors.port)}
              helperText={touched.port && errors.port}
            />

            <TextField
              select
              fullWidth
              label="Is Secure"
              {...getFieldProps('secure')}
              error={Boolean(touched.secure && errors.secure)}
              helperText={touched.secure && errors.secure}
            >
              <MenuItem value=""></MenuItem>
              <MenuItem key="yes" value={'true'}>
                Yes
              </MenuItem>
              <MenuItem key="no" value="false">
                No
              </MenuItem>
            </TextField>
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
            <TextField
              select
              fullWidth
              label="Email Template"
              {...getFieldProps('emailTemplate')}
              error={Boolean(touched.emailTemplate && errors.emailTemplate)}
              helperText={touched.emailTemplate && errors.emailTemplate}
            >
              <MenuItem value=""></MenuItem>
              {emailTemplates.map((emailTemplate) => (
                <MenuItem value={emailTemplate._id} key={emailTemplate._id}>
                  {emailTemplate.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              type="email"
              fullWidth
              label="To"
              {...getFieldProps('to')}
              error={Boolean(touched.to && errors.to)}
              helperText={touched.to && errors.to}
            />
          </Stack>

          {resStatus && (
            <Alert onClose={() => setResStatus(null)} color={resStatus.status}>
              {resStatus.message}
            </Alert>
          )}

          <Divider />
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => {
                resetForm();
              }}
              variant="outlined"
              sx={{ mr: 2 }}
            >
              Reset
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Test Now
            </LoadingButton>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default TestSmtpAccountForm;
