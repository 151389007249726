export const TABLE_HEAD = [
  { id: 'label', label: 'Label', align: 'left' },
  { id: 'description', label: 'Description', align: 'left' },
  { id: 'server', label: 'Server', align: 'left' },
  { id: 'port', label: 'Port', align: 'left' },
  { id: 'createdAt', label: 'Created At', align: 'left', minWidth: 150 },
  { id: 'updatedAt', label: 'Updated At', align: 'left', minWidth: 150 },
  { id: '' },
];

export function applySortFilter({
  tableData,
  comparator,
  filterName,
}: {
  tableData: any[];
  comparator: (a: any, b: any) => number;
  filterName: string;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item: Record<string, any>) =>
        item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return tableData;
}
