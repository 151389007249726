export enum ERole {
  ROOT = 'ROOT',
  MANAGER = 'MANAGER',
  EMPLOYEE = 'EMPLOYEE',
  BDM = 'BDM',
  BDE = 'BDE',
  DEVELOPER = 'DEVELOPER',
  INTERN = 'INTERN',
  HR = 'HR',
}
