import { PATH_DASHBOARD } from 'src/routes/paths';
import { ICONS } from 'src/common/constants';
import { Label, Iconify } from 'src/components';

const DEVNavConfig = [
  {
    subheader: 'Templates',
    items: [
      {
        title: 'mail',
        path: PATH_DASHBOARD.mail.root,
        icon: ICONS.mail,
        info: <Label color="error">+32</Label>,
      },
      { title: 'ecommerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
      { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
      { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
      { title: 'Blogs', path: PATH_DASHBOARD.blog.root, icon: ICONS.kanban },
      { title: 'Create Blog', path: PATH_DASHBOARD.blog.new, icon: ICONS.kanban },
      { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
      {
        // default roles : All roles can see this entry.
        // roles: ['user'] Only users can see this item.
        // roles: ['admin'] Only admin can see this item.r
        // roles: ['admin', 'manager'] Only admin/manager can see this item.
        // Reference from 'src/guards/RoleBasedGuard'.
        title: 'item_by_roles',
        path: PATH_DASHBOARD.permissionDenied,
        icon: ICONS.menuItem,
        roles: ['admin'],
        caption: 'only_admin_can_see_this_item',
      },
      // {
      //   title: 'menu_level_1',
      //   path: '#1',
      //   icon: ICONS.menuItem,
      //   children: [
      //     { title: 'menu_level_2', path: '#2', disabled: true },
      //     {
      //       title: 'menu_level_2',
      //       path: '#3',
      //       children: [
      //         { title: 'menu_level_3', path: '#4' },
      //         { title: 'menu_level_3', path: '#5' },
      //       ],
      //     },
      //   ],
      // },
      // { title: 'item_disabled', path: '#disabled', icon: ICONS.menuItem, disabled: true },
      // {
      //   title: 'item_label',
      //   path: '#label',
      //   icon: ICONS.menuItem,
      //   info: (
      //     <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
      //       NEW
      //     </Label>
      //   ),
      // },
      // { title: 'item_caption', path: '#caption', icon: ICONS.menuItem, caption: 'description' },
    ],
  },
];

export default DEVNavConfig;
