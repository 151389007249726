import { Chip, Divider, Typography, Box, Stack } from '@mui/material';

interface IRowProps {
  title: string;
  value: string;
  chip?: boolean;
  chipColor?: any;
  children?: React.ReactNode;
}
const CardInfoItem = ({
  title,
  value,
  chip = false,
  chipColor = 'default',
  children,
}: IRowProps) => {
  return (
    <Box sx={{ mb: 1 }}>
      <Stack
        direction={{ xs: 'row', sm: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        spacing={{ xs: 3, sm: 2 }}
        sx={{ mb: 1 }}
      >
        <Typography>{title}</Typography>

        {children ? (
          <Box>{children}</Box>
        ) : (
          <>
            {!chip && (
              <Typography align={value?.length > 25 ? 'justify' : 'right'} maxWidth={300}>
                {value || 'N/A'}
              </Typography>
            )}
            {chip && <Chip size="small" label={value || 'N/A'} color={chipColor} />}
          </>
        )}
      </Stack>
      <Divider />
    </Box>
  );
};

export default CardInfoItem;
