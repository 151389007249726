import * as Yup from 'yup';
import { IImapAccount } from '../interfaces/imap-accounts.interface';

export class CreateImapAccountSchema {
  schema;

  initialValues: IImapAccount;

  constructor() {
    this.schema = Yup.object({
      label: Yup.string(),
      description: Yup.string(),
      username: Yup.string().required('Username is required'),
      password: Yup.string().required('Password is required'),
      host: Yup.string().required('Server is required'),
      port: Yup.number().integer().required('Port is required'),
    });

    this.initialValues = {
      label: '',
      description: '',
      username: '',
      password: '',
      host: '',
      port: 0,
    };
  }
}
