import { ReactNode } from 'react';
import { Button, Box, Stack, useTheme, Tooltip, IconButton } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { useTableFilter } from 'src/hooks';

import { ETableFilter } from 'src/common/enums';

interface IProps {
  type: ETableFilter;
  children: ReactNode;
  onSearch: any;
  onClear?: any;
}

const TableFilter = ({ type, children, onSearch = () => {}, onClear }: IProps) => {
  const { tableFilter, onToggle, onClearFilter } = useTableFilter();

  const theme = useTheme();

  const open: boolean = tableFilter[type].settings.open;

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pt: 2.5, px: 3, pb: 1 }}
      >
        <Tooltip title="Apply Filters ">
          <IconButton onClick={() => onToggle(type)}>
            <Iconify
              icon={open ? 'solar:filter-bold' : 'solar:filter-outline'}
              color={open ? 'orange' : theme.palette.text.secondary}
            />
          </IconButton>
        </Tooltip>

        {open && (
          <Box>
            <Tooltip title="Search" sx={{ mr: 1 }}>
              <Button variant="contained" onClick={onSearch}>
                Search
              </Button>
            </Tooltip>

            <Tooltip title="Close Filter">
              <Button
                onClick={() => {
                  onClearFilter(type);
                  onClear();
                }}
                variant="outlined"
              >
                Clear Filters
              </Button>
            </Tooltip>

            <Tooltip title="Close Filter">
              <IconButton onClick={() => onToggle(type)}>
                <Iconify icon="pajamas:clear" color="red" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Stack>

      {open && children}
    </>
  );
};

export default TableFilter;
