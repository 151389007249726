const base = '/search-results';

const SearchResultsApi = {
  base,
  blockResults: base + '/block-results',
  unBlockResults: base + '/unblock-results',
  removeMany: base + '/remove/many',
  removebyQuery: base +'/remove-by-query'
};

export default SearchResultsApi;
