import * as Yup from 'yup';

interface IInitialValues {
  username: string;
  password: string;
  from: string;
  to: string;
  server: string;
  port: number;
  secure: boolean;
  emailTemplate: string;
}

export class TestSmtpAccountSchema {
  schema;

  initialValues: IInitialValues;

  constructor() {
    this.schema = Yup.object({
      username: Yup.string().required('Username is required'),
      password: Yup.string().required('Password is required'),
      from: Yup.string().email('Must be a valid email id!').required('From email is required'),
      to: Yup.string().email('Must be a valid email id!').required('To email is required'),
      server: Yup.string().required('Server is required'),
      port: Yup.number().integer().required('Port is required'),
      secure: Yup.boolean().required('Secure field is required'),
      emailTemplate: Yup.string().required('Please select an email template!'),
    });

    this.initialValues = {
      username: '',
      password: '',
      from: '',
      to: '',
      server: '',
      port: 0,
      secure: false,
      emailTemplate: '',
    };
  }
}
