import * as Yup from 'yup';

import { ICreateCampaign } from '../interfaces';
import { ECampaignStatus } from '../enums';

interface IUpdateCreateCampaign extends ICreateCampaign {
  _id: string;
}

export class CreateCampaignSchema {
  schema;

  initialValues: ICreateCampaign;

  updateValues: IUpdateCreateCampaign;

  constructor() {
    this.schema = Yup.object({
      name: Yup.string().required('Campaign name is required'),
      description: Yup.string().required('Campaign description is required'),
      steps: Yup.array()
        .of(
          Yup.object().shape({
            sequence: Yup.string().required('Sequence name required'),
            emailTemplate: Yup.string().required('Please select email template'),
            frequency: Yup.number()
              .required('Email frequency is required')
              .min(1, 'Must be greater than zero'),
            order: Yup.number()
              .required('Step order is required')
              .min(1, 'Must be greater than zero'),
            type: Yup.string().required('Type name required'),
            interval: Yup.string().required('Interval name required'),
          })
        )
        .required('Steps are required')
        .min(1, 'Please add at least 1 step'),
      status: Yup.string().required('Status required'),
    });

    this.initialValues = {
      name: '',
      description: '',
      steps: [],
      status: ECampaignStatus.INACTIVE,
    };

    this.updateValues = {
      ...this.initialValues,
      _id: '',
    };
  }
}
