import { lazy } from 'react';
import Loadable from './Loadable';
import { AuthGuard } from 'src/guards';
import { DashboardLayout } from 'src/layouts';
import { Navigate } from 'react-router';
import { PATH_DASHBOARD } from '../paths';
import { PATH_AFTER_LOGIN } from 'src/config';
import CarrierList from 'src/pages/dashboard/careers/CarriersList';
import ContactView from 'src/pages/dashboard/contacts/ContactView';

import ImapAccountsList from 'src/pages/dashboard/imap-accounts/ImapAccountsList';
import ImapAccountsCreate from 'src/pages/dashboard/imap-accounts/ImapAccountsCreate';
import DealsList from 'src/pages/dashboard/deals/DealsList';
import DealsCreateForm from 'src/pages/dashboard/deals/DealsCreateForm';
import CampaignGroupTimeline from 'src/pages/CampaignGroupTimeline';

// import IndividualCampaignCreateForm from 'src/pages/dashboard/individual-campaign/IndividualCampaignCreate/IndividualCampaignCreateForm';

// Analytics dashboard
const DashboardAnalytics = Loadable(
  lazy(() => import('src/pages/dashboard/analytics/DashboardAnalytics'))
);

const CampaignABTesting = Loadable(
  lazy(() => import('src/pages/dashboard/analytics/CampaignABTesting'))
);

const UnsubscribedEmailsList = Loadable(
  lazy(() => import('src/pages/dashboard/analytics/UnsubscribedEmailsList'))
);

// Main Content

//Industries List
const IndustryList = Loadable(lazy(() => import('src/pages/dashboard/industries/IndustryList')));
const CreateIndustry = Loadable(
  lazy(() => import('src/pages/dashboard/industries/CreateIndustry'))
);

//SubIndustries List
const SubIndustryList = Loadable(
  lazy(() => import('src/pages/dashboard/sub-industries/SubIndustryList'))
);
const CreateSubIndustry = Loadable(
  lazy(() => import('src/pages/dashboard/sub-industries/CreateSubIndustry'))
);

//QueryList
const QueryList = Loadable(lazy(() => import('src/pages/dashboard/search-queries/QueriesList')));
const CreateQuery = Loadable(lazy(() => import('src/pages/dashboard/search-queries/CreateQuery')));

//ScrapFromUrl
const ScrapUrlList = Loadable(
  lazy(() => import('src/pages/dashboard/scrap-from-url/ScrapUrlList'))
);
const CreateScrapUrl = Loadable(
  lazy(() => import('src/pages/dashboard/scrap-from-url/CreateScrapUrl'))
);

//BlockedContents
const BlockedContentList = Loadable(
  lazy(() => import('src/pages/dashboard/blocked-contents/BlockedContentList'))
);
const CreateBlockedContents = Loadable(
  lazy(() => import('src/pages/dashboard/blocked-contents/CreateBlockContents'))
);

//Search results
const SearchResultsList = Loadable(
  lazy(() => import('src/pages/dashboard/search-results/SearchResultsList'))
);
const CreateSearchResults = Loadable(
  lazy(() => import('src/pages/dashboard/search-results/CreateSearchResults'))
);
const SearcHResultsView = Loadable(
  lazy(() => import('src/pages/dashboard/search-results/SearchResultView'))
);
// Contacts
const ContactsList = Loadable(lazy(() => import('src/pages/dashboard/contacts/ContactsList')));
const ContactsCreate = Loadable(lazy(() => import('src/pages/dashboard/contacts/ContactsCreate')));

//Contact Forms
const ContactFromsList = Loadable(
  lazy(() => import('src/pages/dashboard/contact-froms/ContactFormList'))
);
const CreateContactFroms = Loadable(
  lazy(() => import('src/pages/dashboard/contact-froms/CreateContactFroms'))
);
const ContactFormsView = Loadable(
  lazy(() => import('src/pages/dashboard/contact-froms/contactFormView'))
);

//project
const CreateProjectForm = Loadable(lazy(() => import('src/pages/dashboard/project/CreateProject')));
const ProjectList = Loadable(lazy(() => import('src/pages/dashboard/project/ProjectList')));

//task
const CreateTaskForm = Loadable(lazy(() => import('src/pages/dashboard/task/CreateTask')));
const TaskList = Loadable(lazy(() => import('src/pages/dashboard/task/TaskList')));

//ProjecCredentials
const CreateProjectCredentialsForm = Loadable(
  lazy(() => import('src/pages/dashboard/project-credentials/CreateProjectCredentials'))
);
const ProjectCredentialsList = Loadable(
  lazy(() => import('src/pages/dashboard/project-credentials/ProjectCredentialsList'))
);
const ProjectCredentialsView = Loadable(
  lazy(() => import('src/pages/dashboard/project-credentials/ProjectCredentialsView'))
);

//Proposal Types
const ProposalTypeList = Loadable(
  lazy(() => import('src/pages/dashboard/proposal-type/ProposalTypeList'))
);
const CreateProposalType = Loadable(
  lazy(() => import('src/pages/dashboard/proposal-type/CreateProposalType'))
);

// Campaign
const CampaignList = Loadable(lazy(() => import('src/pages/dashboard/campaign/CampaignList')));
const CampaignCreate = Loadable(lazy(() => import('src/pages/dashboard/campaign/CampaignCreate')));
const CampaignView = Loadable(lazy(() => import('src/pages/dashboard/campaign/CampaignView')));

const CampaignGroupList = Loadable(
  lazy(() => import('src/pages/dashboard/campaign-groups/CampaignGroupList'))
);
const CampaignGroupCreate = Loadable(
  lazy(() => import('src/pages/dashboard/campaign-groups/CampaignGroupCreate'))
);
const CampaignGroupView = Loadable(
  lazy(() => import('src/pages/dashboard/campaign-groups/CampaignGroupView'))
);

//Individual-Campaign
const IndividualCampaignList = Loadable(
  lazy(() => import('src/pages/dashboard/individual-campaign/IndividualCampaignList'))
);
const IndividualCampaignCreate = Loadable(
  lazy(() => import('src/pages/dashboard/individual-campaign/IndividualCampaignCreate'))
);
const IndividualCampaignView = Loadable(
  lazy(() => import('src/pages/dashboard/individual-campaign/IndividualCampaignView'))
);

//Reminders
const RemindersList = Loadable(lazy(() => import('src/pages/dashboard/reminders/RemindersList')));
const RemindersCreate = Loadable(
  lazy(() => import('src/pages/dashboard/reminders/RemindersCreate'))
);

//Custom Mail
const CustomMailCompose = Loadable(lazy(() => import('src/pages/dashboard/custom-mail/index')));
const CustomMailInbox = Loadable(
  lazy(() => import('src/pages/dashboard/custom-mail/InboxList/newList'))
);
const CustomMailView = Loadable(lazy(() => import('src/pages/dashboard/custom-mail/InboxView')));
// const CustomMailView = Loadable(lazy(() => import('src/sections/@dashboard/mail/MailDetails')));

//Technologies
const TechnologiesList = Loadable(
  lazy(() => import('src/pages/dashboard/technologies/TechnologiesList'))
);
const TechnologiesCreate = Loadable(
  lazy(() => import('src/pages/dashboard/technologies/TechnologiesCreate'))
);

//Object types
const ObjectTypesList = Loadable(
  lazy(() => import('src/pages/dashboard/object-types/ObjectTypesList'))
);
const ObjectTypeCreate = Loadable(
  lazy(() => import('src/pages/dashboard/object-types/CreateObjectTypes'))
);

// Email Templates
const EmailTemplatesList = Loadable(
  lazy(() => import('src/pages/dashboard/email-templates/EmailTemplatesList'))
);
const EmailTemplatesCreate = Loadable(
  lazy(() => import('src/pages/dashboard/email-templates/EmailTemplatesCreate'))
);
const EmailTemplatesView = Loadable(
  lazy(() => import('src/pages/dashboard/email-templates/EmailTemplatesView'))
);

//External contact
const ExternalContacts = Loadable(
  lazy(() => import('src/pages/dashboard/externalContacts/ExternalContacts'))
);
const ExternalContactsList = Loadable(
  lazy(() => import('src/pages/dashboard/externalContacts/ExternalContactsList'))
);
const ExternalContactsNewEditForm = Loadable(
  lazy(() => import('src/pages/dashboard/externalContacts/ExternalContacts'))
);

// Email templates groups

const EmailTemplateGroupsList = Loadable(
  lazy(() => import('src/pages/dashboard/email-template-groups/EmailTemplateGroupsList'))
);
const EmailTemplateGroupsCreate = Loadable(
  lazy(() => import('src/pages/dashboard/email-template-groups/EmailTemplateGroupsCreate'))
);

const UsersList = Loadable(lazy(() => import('src/pages/dashboard/users/UsersList')));
const UsersCreate = Loadable(lazy(() => import('src/pages/dashboard/users/UsersCreate')));

// APP
const LinkedInDataList = Loadable(
  lazy(() => import('src/pages/dashboard/linkedIn-data/LinkedInDataList'))
);
const LinkedInDataView = Loadable(
  lazy(() => import('src/pages/dashboard/linkedIn-data/LinkedInDataView'))
);
const LinkedInDataCreate = Loadable(
  lazy(() => import('src/pages/dashboard/linkedIn-data/LinkedInDataCreate'))
);

const Careers = Loadable(lazy(() => import('src/pages/dashboard/careers/CarriersList')));
const CareersView = Loadable(lazy(() => import('src/pages/dashboard/careers/CareerView')));

const ClutchList = Loadable(lazy(() => import('src/pages/dashboard/clutch/ClutchList')));
const ClutchCreate = Loadable(lazy(() => import('src/pages/dashboard/clutch/ClutchCreate')));
const ClutchView = Loadable(lazy(() => import('src/pages/dashboard/clutch/ClutchView')));

const TechbehemothCreate = Loadable(
  lazy(() => import('src/pages/dashboard/techbehemoth/TechbehemothCreate'))
);
const TechbehemothList = Loadable(
  lazy(() => import('src/pages/dashboard/techbehemoth/TechbehemothList'))
);
const TechbehemothView = Loadable(
  lazy(() => import('src/pages/dashboard/techbehemoth/TechbehemothView'))
);

const BookmarksList = Loadable(lazy(() => import('src/pages/dashboard/bookmarks/BookmarksList')));
const BookmarksCreate = Loadable(
  lazy(() => import('src/pages/dashboard/bookmarks/BookmarksCreate'))
);
const BookmarksView = Loadable(lazy(() => import('src/pages/dashboard/bookmarks/BookmarksView')));

const SmtpAccountsList = Loadable(
  lazy(() => import('src/pages/dashboard/smtp-accounts/SmtpAccountsList'))
);
const SmtpAccountsCreate = Loadable(
  lazy(() => import('src/pages/dashboard/smtp-accounts/SmtpAccountsCreate'))
);

// Notes
const NotesList = Loadable(lazy(() => import('src/pages/dashboard/notes/NotesList')));

const Employees = Loadable(lazy(() => import('src/pages/dashboard/employees/')));
const CreateEmployeeForm = Loadable(
  lazy(() => import('src/pages/dashboard/employees/CreateEmployee'))
);

const Tracker = Loadable(lazy(() => import('src/pages/dashboard/tracker/')));
const ProtectedRoutes = [
  // Dashboard Routes
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      // NOTE: Remove these
      { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
      { path: 'app', element: <DashboardAnalytics /> },
      { path: 'campaign-ab-testing', element: <CampaignABTesting /> },
      { path: 'unsubscribed-emails', element: <UnsubscribedEmailsList /> },
      // -----------------------------------------------------------------------
      {
        path: 'Industries',
        children: [
          { element: <Navigate to="/dashboard/industries/list" replace />, index: true },
          { path: 'list', element: <IndustryList /> },
          { path: 'create', element: <CreateIndustry /> },
          { path: ':id/edit', element: <CreateIndustry /> },
        ],
      },

      {
        path: 'Sub-Industries',
        children: [
          { element: <Navigate to="/dashboard/sub-industries/list" replace />, index: true },
          { path: 'list', element: <SubIndustryList /> },
          { path: 'create', element: <CreateSubIndustry /> },
          { path: ':id/edit', element: <CreateSubIndustry /> },
        ],
      },
      {
        path: 'search-queries',
        children: [
          { element: <Navigate to="/dashboard/search-queries/list" replace />, index: true },
          { path: 'list', element: <QueryList /> },
          { path: 'create', element: <CreateQuery /> },
          { path: ':id/edit', element: <CreateQuery /> },
        ],
      },

      {
        path: 'scrap-from-url',
        children: [
          { element: <Navigate to="/dashboard/scrap-from-url/list" replace />, index: true },
          { path: 'list', element: <ScrapUrlList /> },
          { path: 'create', element: <CreateScrapUrl /> },
          { path: ':id/edit', element: <CreateScrapUrl /> },
        ],
      },

      {
        path: 'blocked-contents',
        children: [
          { element: <Navigate to="/dashboard/blocked-contents/list" replace />, index: true },
          { path: 'list', element: <BlockedContentList /> },
          { path: 'create', element: <CreateBlockedContents /> },
          { path: ':id/edit', element: <CreateBlockedContents /> },
        ],
      },
      {
        path: 'search-results',
        children: [
          { element: <Navigate to="/dashboard/search-results/list" replace />, index: true },
          { path: 'list', element: <SearchResultsList /> },
          { path: 'create', element: <CreateSearchResults /> },
          { path: ':id/edit', element: <CreateSearchResults /> },
          { path: ':id/view', element: <SearcHResultsView /> },
        ],
      },
      {
        path: 'contacts',
        children: [
          { element: <Navigate to="/dashboard/contacts/list" replace />, index: true },
          { path: 'list', element: <ContactsList /> },
          { path: 'create', element: <ContactsCreate /> },
          { path: ':id/edit', element: <ContactsCreate /> },
          { path: ':id/view', element: <ContactView /> },
        ],
      },
      {
        path: 'contact-forms',
        children: [
          { element: <Navigate to="/dashboard/contact-forms/list" replace />, index: true },
          { path: 'list', element: <ContactFromsList /> },
          { path: 'create', element: <CreateContactFroms /> },
          { path: ':id/edit', element: <CreateContactFroms /> },
          { path: ':id/view', element: <ContactFormsView /> },
        ],
      },

      {
        path: 'project',
        children: [
          { element: <Navigate to="/dashboard/project/list" replace />, index: true },
          { path: 'list', element: <ProjectList /> },
          { path: 'create', element: <CreateProjectForm /> },
          { path: ':id/edit', element: <CreateProjectForm /> },
        ],
      },
      {
        path: 'task',
        children: [
          { element: <Navigate to="/dashboard/task/list" replace />, index: true },
          { path: 'list', element: <TaskList /> },
          { path: 'create', element: <CreateTaskForm /> },
          { path: ':id/edit', element: <CreateTaskForm /> },
        ],
      },

      {
        path: PATH_DASHBOARD.projectcredentials.root,
        children: [
          {
            element: <Navigate to={PATH_DASHBOARD.projectcredentials.list} replace />,
            index: true,
          },
          { path: 'list', element: <ProjectCredentialsList /> },
          { path: 'create', element: <CreateProjectCredentialsForm /> },
          { path: ':id/edit', element: <CreateProjectCredentialsForm /> },
          { path: ':id/view', element: <ProjectCredentialsView /> },
        ],
      },
      {
        path: 'proposal-types',
        children: [
          { element: <Navigate to="/dashboard/proposal-types/list" replace />, index: true },
          { path: 'list', element: <ProposalTypeList /> },
          { path: 'create', element: <CreateProposalType /> },
          { path: ':id/edit', element: <CreateProposalType /> },
        ],
      },

      {
        path: PATH_DASHBOARD.campaign.root,
        children: [
          { element: <Navigate to={PATH_DASHBOARD.campaign.list} replace />, index: true },
          { path: 'list', element: <CampaignList /> },
          { path: 'create', element: <CampaignCreate /> },
          { path: ':id/edit', element: <CampaignCreate /> },
          { path: ':id/view', element: <CampaignView /> },
        ],
      },

      {
        path: PATH_DASHBOARD.objectTypes.root,
        children: [
          { element: <Navigate to={PATH_DASHBOARD.objectTypes.list} replace />, index: true },
          { path: 'list', element: <ObjectTypesList /> },
          { path: 'create', element: <ObjectTypeCreate /> },
          { path: ':id/edit', element: <ObjectTypeCreate /> },
        ],
      },

      //deals
      {
        path: PATH_DASHBOARD.deals.root,
        children: [
          { element: <Navigate to={PATH_DASHBOARD.deals.list} replace />, index: true },
          { path: 'list', element: <DealsList /> },
          { path: 'create', element: <DealsCreateForm /> },
          { path: ':id/edit', element: <DealsCreateForm /> },
          // { path: ':id/view', element: <CampaignView /> },
        ],
      },

      // Campaign Groups
      {
        path: PATH_DASHBOARD.campaignGroups.root,
        children: [
          { element: <Navigate to={PATH_DASHBOARD.campaignGroups.list} replace />, index: true },
          { path: 'list', element: <CampaignGroupList /> },
          { path: 'create', element: <CampaignGroupCreate /> },
          { path: ':id/edit', element: <CampaignGroupCreate /> },
          { path: ':id/view', element: <CampaignGroupView /> },
        ],
      },

      //Individual campaign
      {
        path: PATH_DASHBOARD.individualCampaign.root,
        children: [
          {
            element: <Navigate to={PATH_DASHBOARD.individualCampaign.list} replace />,
            index: true,
          },
          { path: 'list', element: <IndividualCampaignList /> },
          { path: 'create', element: <IndividualCampaignCreate /> },
          { path: ':id/edit', element: <IndividualCampaignCreate /> },
          { path: ':id/view', element: <IndividualCampaignView /> },
        ],
      },
      // timeline
      {
        path: PATH_DASHBOARD.timeline.root,
        children: [
          {
            element: <Navigate to={PATH_DASHBOARD.timeline.list} replace />,
            index: true,
          },
          { path: 'list', element: <CampaignGroupTimeline /> },
        ],
      },

      //Technologies
      {
        path: PATH_DASHBOARD.technologies.root,
        children: [
          { element: <Navigate to={PATH_DASHBOARD.technologies.list} replace />, index: true },
          { path: 'list', element: <TechnologiesList /> },
          { path: 'create', element: <TechnologiesCreate /> },
          { path: ':id/edit', element: <TechnologiesCreate /> },
        ],
      },

      //Reminders
      {
        path: PATH_DASHBOARD.reminders.root,
        children: [
          { element: <Navigate to={PATH_DASHBOARD.reminders.list} replace />, index: true },
          { path: 'list', element: <RemindersList /> },
          { path: 'create', element: <RemindersCreate /> },
          { path: ':id/edit', element: <RemindersCreate /> },
        ],
      },

      //Custom Mail
      {
        path: PATH_DASHBOARD.customMail.root,
        children: [
          { element: <Navigate to={PATH_DASHBOARD.customMail.compose} replace />, index: true },
          { path: 'label/:customMailLabel', element: <CustomMailCompose /> },
          { path: 'label/:customLabel/:customMailId', element: <CustomMailCompose /> },
          { path: ':systemMailLabel', element: <CustomMailCompose /> },
          { path: ':systemMailLabel/:customMailId', element: <CustomMailCompose /> },
          { path: 'inbox', element: <CustomMailInbox /> },
          { path: ':id/view', element: <CustomMailView /> },
        ],
      },

      // Email Templates
      {
        path: PATH_DASHBOARD.emailTemplates.root,
        children: [
          { element: <Navigate to={PATH_DASHBOARD.emailTemplates.list} replace />, index: true },
          { path: 'list', element: <EmailTemplatesList /> },
          { path: 'create', element: <EmailTemplatesCreate /> },
          { path: ':id/edit', element: <EmailTemplatesCreate /> },
          { path: ':id/view', element: <EmailTemplatesView /> },
        ],
      },

      //External contact
      {
        path: PATH_DASHBOARD.externalContacts.root,
        children: [
          { element: <Navigate to={PATH_DASHBOARD.externalContacts.list} replace />, index: true },
          { path: 'list', element: <ExternalContactsList /> },
          { path: 'create', element: <ExternalContacts /> },
          { path: ':id/edit', element: <ExternalContactsNewEditForm /> },
          // { path: ':id/view', element: <EmailTemplatesView /> },
        ],
      },

      // Email Template Groups
      {
        path: PATH_DASHBOARD.emailTemplateGroups.root,
        children: [
          {
            element: <Navigate to={PATH_DASHBOARD.emailTemplateGroups.list} replace />,
            index: true,
          },
          { path: 'list', element: <EmailTemplateGroupsList /> },
          { path: 'create', element: <EmailTemplateGroupsCreate /> },
          { path: ':id/edit', element: <EmailTemplateGroupsCreate /> },
        ],
      },

      // Users
      {
        path: PATH_DASHBOARD.users.root,
        children: [
          {
            element: <Navigate to={PATH_DASHBOARD.users.list} replace />,
            index: true,
          },
          { path: 'list', element: <UsersList /> },
          { path: 'create', element: <UsersCreate /> },
          { path: ':id/edit', element: <UsersCreate /> },
        ],
      },

      //LinkedIn Data
      {
        path: PATH_DASHBOARD.linkedInData1.root,
        children: [
          {
            element: <Navigate to={PATH_DASHBOARD.linkedInData1.list} replace />,
            index: true,
          },
          { path: 'list', element: <LinkedInDataList /> },
          { path: 'create', element: <LinkedInDataCreate /> },
          { path: ':id/edit', element: <LinkedInDataCreate /> },
          { path: ':id/view', element: <LinkedInDataView /> },
        ],
      },

      // carrier
      { path: 'carrier', element: <Careers /> },
      {
        path: PATH_DASHBOARD.career.root,
        children: [
          {
            element: <Navigate to={PATH_DASHBOARD.career.list} replace />,
            index: true,
          },
          { path: 'list', element: <CarrierList /> },
          { path: ':id/view', element: <CareersView /> },
        ],
      },

      // Clutch
      {
        path: PATH_DASHBOARD.clutch.root,
        children: [
          {
            element: <Navigate to={PATH_DASHBOARD.clutch.list} replace />,
            index: true,
          },
          { path: 'list', element: <ClutchList /> },
          { path: 'create', element: <ClutchCreate /> },
          { path: ':id/edit', element: <ClutchCreate /> },
          { path: ':id/view', element: <ClutchView /> },
        ],
      },

      // Techbehemoth
      {
        path: PATH_DASHBOARD.techbehemoth.root,
        children: [
          {
            element: <Navigate to={PATH_DASHBOARD.techbehemoth.list} replace />,
            index: true,
          },
          { path: 'list', element: <TechbehemothList /> },
          { path: 'create', element: <TechbehemothCreate /> },
          { path: ':id/edit', element: <TechbehemothCreate /> },
          { path: ':id/view', element: <TechbehemothView /> },
        ],
      },

      // Bookmarks
      {
        path: PATH_DASHBOARD.bookmarks.root,
        children: [
          {
            element: <Navigate to={PATH_DASHBOARD.bookmarks.list} replace />,
            index: true,
          },
          { path: 'list', element: <BookmarksList /> },
          { path: 'create', element: <BookmarksCreate /> },
          { path: ':id/edit', element: <BookmarksCreate /> },
          { path: ':id/view', element: <BookmarksView /> },
        ],
      },
      //SMTP ACCOUNT
      {
        path: PATH_DASHBOARD.smtpAccounts.root,
        children: [
          {
            element: <Navigate to={PATH_DASHBOARD.smtpAccounts.list} replace />,
            index: true,
          },
          { path: 'list', element: <SmtpAccountsList /> },
          { path: 'create', element: <SmtpAccountsCreate /> },
          { path: ':id/edit', element: <SmtpAccountsCreate /> },
        ],
      },

      //IMAP ACCOUNT
      {
        path: PATH_DASHBOARD.imapAccounts.root,
        children: [
          {
            element: <Navigate to={PATH_DASHBOARD.imapAccounts.list} replace />,
            index: true,
          },
          { path: 'list', element: <ImapAccountsList /> },
          { path: 'create', element: <ImapAccountsCreate /> },
          { path: ':id/edit', element: <ImapAccountsCreate /> },
        ],
      },

      //NOTES
      {
        path: PATH_DASHBOARD.notes.root,
        children: [
          {
            element: <Navigate to={PATH_DASHBOARD.notes.list} replace />,
            index: true,
          },
          { path: 'list', element: <NotesList /> },
          // { path: 'create', element: <SmtpAccountsCreate /> },
          // { path: ':id/edit', element: <SmtpAccountsCreate /> },
        ],
      },
      // employees
      {
        path: PATH_DASHBOARD.employee.root,
        children: [
          { element: <Navigate to={PATH_DASHBOARD.employee.list} replace />, index: true },
          { path: 'list', element: <Employees /> },
          { path: 'create', element: <CreateEmployeeForm /> },
        ],
      },
      {
        path: 'tracker',
        children: [
          { element: <Tracker />, index: true },
          // { path: 'list', element: <Employees /> },
        ],
      },
    ],
  },
];

export default ProtectedRoutes;
