// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    campaignABTesting: path(ROOTS_DASHBOARD, '/campaign-ab-testing'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/customMail/inbox'), //previous route:/dashboard/mail/all
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  linkedInData: path(ROOTS_DASHBOARD, '/linkedIn-data'),
  linkedInDataedit: (id: string) => path(ROOTS_DASHBOARD, `/linkedIn-data/${id}/edit`),
  linkedInView: (id: string) => path(ROOTS_DASHBOARD, `/linkedIn-data/${id}/view`),

  linkedInData1: {
    root: path(ROOTS_DASHBOARD, '/linkedIn-data'),
    list: path(ROOTS_DASHBOARD, '/linkedIn-data/list'),
    create: path(ROOTS_DASHBOARD, '/linkedIn-data/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/linkedIn-data/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/linkedIn-data/${id}/view`),
  },

  carrier: path(ROOTS_DASHBOARD, '/carrier'),
  tracker: path(ROOTS_DASHBOARD, '/tracker'),

  career: {
    root: path(ROOTS_DASHBOARD, '/career'),
    list: path(ROOTS_DASHBOARD, '/career/list'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/career/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/career/${id}/view`),
  },

  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },

  // Main Content
  industries: {
    root: path(ROOTS_DASHBOARD, '/industries'),
    list: path(ROOTS_DASHBOARD, '/industries/list'),
    create: path(ROOTS_DASHBOARD, '/industries/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/industries/${id}/edit`),
  },

  subIndustries: {
    root: path(ROOTS_DASHBOARD, '/sub-industries'),
    list: path(ROOTS_DASHBOARD, '/sub-industries/list'),
    create: path(ROOTS_DASHBOARD, '/sub-industries/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/sub-industries/${id}/edit`),
  },

  technologies: {
    root: path(ROOTS_DASHBOARD, '/technologies'),
    list: path(ROOTS_DASHBOARD, '/technologies/list'),
    create: path(ROOTS_DASHBOARD, '/technologies/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/technologies/${id}/edit`),
  },

  searchQueries: {
    root: path(ROOTS_DASHBOARD, '/search-queries'),
    list: path(ROOTS_DASHBOARD, '/search-queries/list'),
    create: path(ROOTS_DASHBOARD, '/search-queries/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/search-queries/${id}/edit`),
  },

  scrapFromUrl: {
    root: path(ROOTS_DASHBOARD, '/scrap-from-url'),
    list: path(ROOTS_DASHBOARD, '/scrap-from-url/list'),
    create: path(ROOTS_DASHBOARD, '/scrap-from-url/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/scrap-from-url/${id}/edit`),
  },

  blockContents: {
    root: path(ROOTS_DASHBOARD, '/blocked-contents'),
    list: path(ROOTS_DASHBOARD, '/blocked-contents/list'),
    create: path(ROOTS_DASHBOARD, '/blocked-contents/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/blocked-contents/${id}/edit`),
  },

  searchResults: {
    root: path(ROOTS_DASHBOARD, '/search-results'),
    list: path(ROOTS_DASHBOARD, '/search-results/list'),
    create: path(ROOTS_DASHBOARD, '/search-results/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/search-results/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/search-results/${id}/view`),
  },

  reminders: {
    root: path(ROOTS_DASHBOARD, '/reminders'),
    list: path(ROOTS_DASHBOARD, '/reminders/list'),
    create: path(ROOTS_DASHBOARD, '/reminders/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/reminders/${id}/edit`),
  },

  customMail: {
    root: path(ROOTS_DASHBOARD, '/customMail'),
    all: path(ROOTS_DASHBOARD, '/customMail/all'),
    compose: path(ROOTS_DASHBOARD, '/customMail/compose'),
    inbox: path(ROOTS_DASHBOARD, '/customMail/inbox'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/customMail/${id}/view`),
  },

  contacts: {
    root: path(ROOTS_DASHBOARD, '/contacts'),
    list: path(ROOTS_DASHBOARD, '/contacts/list'),
    create: path(ROOTS_DASHBOARD, '/contacts/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/contacts/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/contacts/${id}/view`),
  },

  contactForms: {
    root: path(ROOTS_DASHBOARD, '/contact-forms'),
    list: path(ROOTS_DASHBOARD, '/contact-forms/list'),
    create: path(ROOTS_DASHBOARD, '/contact-forms/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/contact-forms/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/contact-forms/${id}/view`),
  },

  project: {
    root: path(ROOTS_DASHBOARD, '/project'),
    list: path(ROOTS_DASHBOARD, '/project/list'),
    create: path(ROOTS_DASHBOARD, '/project/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/project/${id}/edit`),
  },
  task: {
    root: path(ROOTS_DASHBOARD, '/task'),
    list: path(ROOTS_DASHBOARD, '/task/list'),
    create: path(ROOTS_DASHBOARD, '/task/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/task/${id}/edit`),
  },

  projectcredentials: {
    root: path(ROOTS_DASHBOARD, '/project_credentials'),
    list: path(ROOTS_DASHBOARD, '/project_credentials/list'),
    create: path(ROOTS_DASHBOARD, '/project_credentials/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/project_credentials/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/project_credentials/${id}/view`),
  },

  proposalTypes: {
    root: path(ROOTS_DASHBOARD, '/proposal-types'),
    list: path(ROOTS_DASHBOARD, '/proposal-types/list'),
    create: path(ROOTS_DASHBOARD, '/proposal-types/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/proposal-types/${id}/edit`),
  },

  campaign: {
    root: path(ROOTS_DASHBOARD, '/campaign'),
    list: path(ROOTS_DASHBOARD, '/campaign/list'),
    create: path(ROOTS_DASHBOARD, '/campaign/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/campaign/${id}/edit`),
  },
  timeline: {
    root: path(ROOTS_DASHBOARD, '/campaigngroup-timeline'),
    list: path(ROOTS_DASHBOARD, '/campaigngroup-timeline/list'),
  },

  deals: {
    root: path(ROOTS_DASHBOARD, '/deals'),
    list: path(ROOTS_DASHBOARD, '/deals/list'),
    create: path(ROOTS_DASHBOARD, '/deals/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/deals/${id}/edit`),
  },

  campaignGroups: {
    root: path(ROOTS_DASHBOARD, '/campaign_groups'),
    list: path(ROOTS_DASHBOARD, '/campaign_groups/list'),
    create: path(ROOTS_DASHBOARD, '/campaign_groups/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/campaign_groups/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/campaign_groups/${id}/view`),
  },

  individualCampaign: {
    root: path(ROOTS_DASHBOARD, '/individual_campaign'),
    list: path(ROOTS_DASHBOARD, '/individual_campaign/list'),
    create: path(ROOTS_DASHBOARD, '/individual_campaign/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/individual_campaign/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/individual_campaign/${id}/view`),
  },

  emailTemplates: {
    root: path(ROOTS_DASHBOARD, '/email_templates'),
    list: path(ROOTS_DASHBOARD, '/email_templates/list'),
    create: path(ROOTS_DASHBOARD, '/email_templates/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/email_templates/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/email_templates/${id}/view`),
  },

  emailTemplateGroups: {
    root: path(ROOTS_DASHBOARD, '/email_template_groups'),
    list: path(ROOTS_DASHBOARD, '/email_template_groups/list'),
    create: path(ROOTS_DASHBOARD, '/email_template_groups/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/email_template_groups/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/email_template_groups/${id}/view`),
  },

  externalContacts: {
    root: path(ROOTS_DASHBOARD, '/externalContacts'),
    list: path(ROOTS_DASHBOARD, '/externalContacts/list'),
    create: path(ROOTS_DASHBOARD, '/externalContacts/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/externalContacts/${id}/edit`),
  },

  objectTypes: {
    root: path(ROOTS_DASHBOARD, '/object-types'),
    list: path(ROOTS_DASHBOARD, '/object-types/list'),
    create: path(ROOTS_DASHBOARD, '/object-types/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/object-types/${id}/edit`),
  },

  users: {
    root: path(ROOTS_DASHBOARD, '/users'),
    list: path(ROOTS_DASHBOARD, '/users/list'),
    create: path(ROOTS_DASHBOARD, '/users/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/users/${id}/edit`),
  },

  clutch: {
    root: path(ROOTS_DASHBOARD, '/clutch'),
    list: path(ROOTS_DASHBOARD, '/clutch/list'),
    create: path(ROOTS_DASHBOARD, '/clutch/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/clutch/${id}/edit`),
  },

  techbehemoth: {
    root: path(ROOTS_DASHBOARD, '/techbehemoth'),
    list: path(ROOTS_DASHBOARD, '/techbehemoth/list'),
    create: path(ROOTS_DASHBOARD, '/techbehemoth/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/techbehemoth/${id}/edit`),
  },

  bookmarks: {
    root: path(ROOTS_DASHBOARD, '/bookmarks'),
    list: path(ROOTS_DASHBOARD, '/bookmarks/list'),
    create: path(ROOTS_DASHBOARD, '/bookmarks/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/bookmarks/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/bookmarks/${id}/view`),
  },

  smtpAccounts: {
    root: path(ROOTS_DASHBOARD, '/smtp-accounts'),
    list: path(ROOTS_DASHBOARD, '/smtp-accounts/list'),
    create: path(ROOTS_DASHBOARD, '/smtp-accounts/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/smtp-accounts/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/smtp-accounts/${id}/view`),
  },

  imapAccounts: {
    root: path(ROOTS_DASHBOARD, '/imap-accounts'),
    list: path(ROOTS_DASHBOARD, '/imap-accounts/list'),
    create: path(ROOTS_DASHBOARD, '/imap-accounts/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/imap-accounts/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/imap-accounts/${id}/view`),
  },

  notes: {
    root: path(ROOTS_DASHBOARD, '/notes'),
    list: path(ROOTS_DASHBOARD, '/notes/list'),
    // create: path(ROOTS_DASHBOARD, '/smtp-accounts/create'),
    // edit: (id: string) => path(ROOTS_DASHBOARD, `/smtp-accounts/${id}/edit`),
    // view: (id: string) => path(ROOTS_DASHBOARD, `/smtp-accounts/${id}/view`),
  },

  // Main Content Ends ----------------------
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },

  employee: {
    root: path(ROOTS_DASHBOARD, '/employee'),
    list: path(ROOTS_DASHBOARD, '/employee/list'),
    create: path(ROOTS_DASHBOARD, '/employee/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/employee/${id}/edit`),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
