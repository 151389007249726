import { useState, useEffect, Fragment, useCallback } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// @mui
import {
  Box,
  Card,
  Table,
  Button,
  Switch,
  Tooltip,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Checkbox,
  Link,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../../hooks/useTable';
// @types
// components
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import {
  TableNoData,
  TableSkeleton,
  TableHeadCustom,
  TableSelectedActions,
} from '../../../components/table';
// sections
import { deleteApi, getApi, patchApi } from 'src/common/apis';
import { TableRow } from '@mui/material';
import { TableCell } from '@mui/material';
import MoreMenu from 'src/components/table/MoreMenu';
import CustomModal from 'src/components/modals/CustomModal';
import Label from 'src/components/Label';
import { useTheme } from '@mui/material';
import SearchResultsApi from 'src/common/apis/search-results.api';
import { SearchResults } from 'src/@types/searchResults';
import { IParams } from 'src/@types/params';
import SearchResultsToolbar from '../search-results/SearchResultsToolbar';
import { SearchResultsFilter } from 'src/@types/searchResultFilter';
import { CareersApi } from 'src/common/apis';
import { Carrier } from 'src/@types/carrier';
import { sentenceCase } from 'change-case';
import { AddToBookmark, LinkButton, TableFilter } from 'src/components';
import { EBookmarkType, ETableFilter } from 'src/common/enums';
import { useTableFilter } from 'src/hooks';
import { CareerTableFilter } from 'src/components/filters';
import { CRowsPerPageOptions } from 'src/common/constants';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'job_title', label: 'Job Title', align: 'left' },
  { id: 'conpany_name', label: 'Company Name', align: 'left' },
  { id: 'job_type', label: 'Job Type', align: 'left' },
  { id: 'salary', label: 'Salary', align: 'left' },
  { id: 'job_link', label: 'Job Link', align: 'left' },
  { id: 'description', label: 'Job Description', align: 'left' },
  { id: 'original_url', label: 'Original Url', align: 'left' },
  { id: 'company_location', label: 'Company Location', align: 'left' },
  { id: 'rating', label: 'Rating', align: 'left' },

  { id: '' },
];

// ----------------------------------------------------------------------

export default function CarrierList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: 'createdAt',
  });

  const { themeStretch } = useSettings();

  const theme = useTheme();
  const { tableFilter, getFinalParams } = useTableFilter();

  const [tableData, setTableData] = useState<Carrier[]>([]);

  const [filterName, setFilterName] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState({ open: false, id: '' });

  const [showAllLinks, setShowAllLinks] = useState(false);

  const [totalCount, setTotalCount] = useState(0);

  const [filterData, setFilterData] = useState<SearchResultsFilter>({
    country: '',
    rating: undefined,
    isBlocked: false,
    emailVerified: undefined,
  });

  const handleViewMoreLinks = () => {
    setShowAllLinks(true);
  };

  const handleViewLessLinks: any = () => {
    setShowAllLinks(false);
  };

  const handleClose = () => {
    setOpen({
      open: false,
      id: '',
    });
  };

  const getCarriersList = async (page: number, rowsPerPage: number) => {
    setIsLoading(true);

    const params: IParams = {
      page: page + 1,
      skip: page * rowsPerPage,
      limit: rowsPerPage,
    };

    const carriersList = await getApi({
      url: CareersApi.base,
      showToast: false,
      params: { ...params, ...getFinalParams(ETableFilter.CAREER) },
    });

    setTableData(carriersList.data.careers);
    setTotalCount(carriersList.data.count);
    setIsLoading(false);
  };

  useEffect(() => {
    getCarriersList(tableFilter?.career.params.page, tableFilter.career.params.rowsPerPage);
  }, [tableFilter.career.params.page, tableFilter.career.params.rowsPerPage]);

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteRow = (id: string) => {
    console.log(id);
    setOpen({
      open: true,
      id: id,
    });
  };

  const handleDeleteQuery = async () => {
    const res = await deleteApi({ url: `${SearchResultsApi.base}/${open.id}`, showToast: true });

    if (res.data) {
      const deleteRow = tableData.filter((row) => row._id !== open.id);
      setSelected([]);
      handleClose();
      setTotalCount(totalCount - 1);
      setTableData(deleteRow);
    }
  };

  const handleDeleteRows = async(_ids: string[]) => {
    // const deleteRows = tableData.filter((row) => !selected.includes(row._id));
    // setSelected([]);
    // setTableData(deleteRows);
    const res = await deleteApi({ url: CareersApi.removeMany, values: { _ids }, showToast:true });
    if (res.error) return

    setSelected([]);
    getCarriersList(
      tableFilter?.externalContact.params.page,
      tableFilter.externalContact.params.rowsPerPage
    );
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const onSearch = async () => {
    await getCarriersList(tableFilter.career.params.page, tableFilter.career.params.rowsPerPage);
  };

  const denseHeight = dense ? 60 : 80;

  const isNotFound = (!dataFiltered.length && !!filterName) || (!isLoading && !dataFiltered.length);

  return (
    <Page title="Carriers: Carriers List">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="Carriers"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Carrier',
              href: PATH_DASHBOARD.career.list,
            },
            { name: 'Carriers List' },
          ]}
        />

        <Card>
          <TableFilter type={ETableFilter.CAREER} onSearch={onSearch} onClear={onSearch}>
            <CareerTableFilter />
          </TableFilter>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 960, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row._id)
                    )
                  }
                  actions={
                    <>
                      <Tooltip title="Delete">
                        <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                      </Tooltip>
                      <AddToBookmark members={selected} type={EBookmarkType.CAREER} />
                    </>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row._id)
                    )
                  }
                />

                <TableBody>
                  {(isLoading
                    ? [...Array(tableFilter.career.params.rowsPerPage)]
                    : dataFiltered
                  )?.map((row, index) =>
                    row ? (
                      <TableRow hover selected={row?.selected} key={row._id}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selected.includes(row._id)} onClick={() => onSelectRow(row._id)} />
                        </TableCell>

                        <TableCell>
                          <LinkButton
                            // style={{ textDecoration: 'none', color: 'black' }}
                            to={`${PATH_DASHBOARD.career.root}/${row?._id}/view`}
                          >
                            {' '}
                            {sentenceCase(row?.job_title)}
                          </LinkButton>
                        </TableCell>
                        <TableCell>{row?.company_name}</TableCell>
                        <TableCell>{row?.job_type}</TableCell>

                        <TableCell>{row?.salary || 'n/a'}</TableCell>
                        <TableCell>
                          <Link href={row?.job_link} target="_blank">
                            {row?.job_link ? `${row?.job_link.slice(0, 10)}...` : 'n/a'}
                          </Link>
                        </TableCell>

                        <TableCell>{row?.job_description || 'n/a'}</TableCell>

                        <TableCell>
                          <Link href={row?.original_url} target="_blank">
                            {row?.original_url ? `${row?.original_url.slice(0, 10)}...` : 'n/a'}
                          </Link>
                        </TableCell>

                        <TableCell>{row?.company_location || 'n/a'}</TableCell>
                        <TableCell>{row?.rating || 'n/a'}</TableCell>

                        <TableCell align="right">
                          <MoreMenu
                            onDelete={() => handleDeleteRow(row?._id)}
                            id={row?._id}
                            // editRootPath={PATH_DASHBOARD.searchResults.root}
                          />
                        </TableCell>
                      </TableRow>
                    ) : (
                      !isNotFound && <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    )
                  )}

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={CRowsPerPageOptions}
              component="div"
              count={totalCount}
              rowsPerPage={tableFilter.career.params.rowsPerPage}
              page={tableFilter.career.params.page}
              onPageChange={(e, newPage) => onChangePage(e, newPage, ETableFilter.CAREER)}
              onRowsPerPageChange={(e) => onChangeRowsPerPage(e, ETableFilter.CAREER)}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>

      <CustomModal
        title="Are you sure.?"
        open={open.open}
        onClose={handleClose}
        maxWidth="xs"
        minHeight="xs"
      >
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" gap={5}>
          <Button variant="contained" onClick={handleDeleteQuery}>
            Yes
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            No
          </Button>
        </Box>
      </CustomModal>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
}: {
  tableData: Carrier[];
  comparator: (a: any, b: any) => number;
  filterName: string;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item: Record<string, any>) =>
        item.title.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        item.url.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        item.email.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return tableData;
}
