// @mui
import { Table, TableBody, TableContainer } from '@mui/material';
import { Scrollbar } from 'src/components';
import { IBookmarkGroup } from 'src/common/interfaces';
import {
  TableHeadCustom,
  TableSkeleton,
  TableNoData,
  ClutchRow,
  SearchResultRow,
  ContactsRow,
  LinkedinDataRow,
  TechbehemothRow,
  CareerRow,
  ContactFormRow,
} from 'src/components/table';
import useTable from 'src/hooks/useTable';
import { EBookmarkType } from 'src/common/enums';
import { getCommonScrapedDataTableHead } from 'src/common/table-heads';

interface IProps {
  type: EBookmarkType;
  bookmarkGroups: IBookmarkGroup[] | [];
  isLoading?: boolean;
  removeMember: (id: string) => Promise<void>; // Type definition for removeMember
}

const getTableRow = (row: any, index: number, removeMember: any, type: EBookmarkType) => {
  switch (type) {
    case EBookmarkType.CONTACT:
      return (
        <ContactsRow row={row[type]} index={index} removeMember={() => removeMember(row._id)} />
      );

    case EBookmarkType.SEARCH_RESULT:
      return (
        <SearchResultRow row={row[type]} index={index} removeMember={() => removeMember(row._id)} />
      );

    case EBookmarkType.LINKEDIN:
      return (
        <LinkedinDataRow row={row[type]} index={index} removeMember={() => removeMember(row._id)} />
      );

    case EBookmarkType.CLUTCH:
      return <ClutchRow row={row[type]} index={index} removeMember={() => removeMember(row._id)} />;

    case EBookmarkType.TECHBEHEMOTH:
      return (
        <TechbehemothRow row={row[type]} index={index} removeMember={() => removeMember(row._id)} />
      );

    case EBookmarkType.CAREER:
      return <CareerRow row={row[type]} index={index} removeMember={() => removeMember(row._id)} />;

    case EBookmarkType.CONTACT_FORM:
      return (
        <ContactFormRow row={row[type]} index={index} removeMember={() => removeMember(row._id)} />
      );

    default:
      return '';
  }
};

const BookmarkMembersList = ({ isLoading = false, removeMember, type, bookmarkGroups }: IProps) => {
  const { order, orderBy, rowsPerPage, selected, onSort } = useTable();

  const isNotFound = !isLoading && bookmarkGroups.length <= 0 ? true : false;

  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 960, position: 'relative' }}>
        <Table>
          <TableHeadCustom
            order={order}
            orderBy={orderBy}
            headLabel={getCommonScrapedDataTableHead(type)}
            rowCount={bookmarkGroups.length || 0}
            numSelected={selected.length}
            onSort={onSort}
          />

          <TableBody>
            {(isLoading ? [...Array(rowsPerPage)] : bookmarkGroups)?.map((row, index) =>
              row
                ? getTableRow(row, index, removeMember, type)
                : !isNotFound && <TableSkeleton key={index} sx={{ height: 80 }} />
            )}

            <TableNoData isNotFound={isNotFound} />
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
};

export default BookmarkMembersList;
