import { EContactType } from '../enums';

export const getContactTypeColor = (type: EContactType) => {
  switch (type) {
    case EContactType.CLIENT:
      return 'primary';
    case EContactType.LEAD:
      return 'warning';
    case EContactType.OTHER:
      return 'info';

    default:
      return 'secondary';
  }
};
