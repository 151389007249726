import { useNavigate } from 'react-router';

interface IProps {
  to: string;
  children: any;
}
const LinkButton = ({ to, children }: IProps) => {
  const navigate = useNavigate();
  return (
    <span style={{ cursor: 'pointer' }} onClick={() => navigate(to, { replace: true })}>
      {children}
    </span>
  );
};

export default LinkButton;
