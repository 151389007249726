import * as Yup from 'yup';

import { IEmailTemplateGroup } from '../interfaces';

export class CreateEmailTemplateGroupSchema {
  schema;

  initialValues: IEmailTemplateGroup;

  updateValues: IEmailTemplateGroup;

  constructor() {
    this.schema = Yup.object({
      name: Yup.string().required('Template group name is required'),
      description: Yup.string().required('Template group description is required'),
      templates: Yup.array().of(Yup.string()),
    });

    this.initialValues = {
      name: '',
      description: '',
      templates: [],
    };

    this.updateValues = {
      ...this.initialValues,
      _id: '',
    };
  }
}
