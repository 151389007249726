import { ERole } from 'src/common/enums';
import ROOTNavConfig from './ROOTNavConfig';
import BDENavConfig from './BDENavConfig';
import DEVNavConfig from './DEVNavConfig';

export const getNavConfig = (roles: ERole[]) => {
  if (roles?.includes(ERole.ROOT)) return ROOTNavConfig;

  if (roles?.includes(ERole.BDE) || roles?.includes(ERole.BDM)) return BDENavConfig;

  return DEVNavConfig;
};
