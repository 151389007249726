import { lazy } from 'react';
import Loadable from './Loadable';
import { GuestGuard } from 'src/guards';
import { AuthRoutes } from '.';
import { LogoOnlyLayout, MainLayout } from 'src/layouts';
import { Navigate } from 'react-router';
import CampaignGroupTimeline from 'src/pages/CampaignGroupTimeline';

// MAIN
const HomePage = Loadable(lazy(() => import('src/pages/Home')));
const About = Loadable(lazy(() => import('src/pages/About')));
const Contact = Loadable(lazy(() => import('src/pages/Contact')));
const Faqs = Loadable(lazy(() => import('src/pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('src/pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('src/pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('src/pages/Pricing')));
const Payment = Loadable(lazy(() => import('src/pages/Payment')));
const Page500 = Loadable(lazy(() => import('src/pages/Page500')));
const Page403 = Loadable(lazy(() => import('src/pages/Page403')));
const Page404 = Loadable(lazy(() => import('src/pages/Page404')));

// Unsubscribe email for campaign
const UnsubscribeEmail = Loadable(lazy(() => import('src/pages/UnsubscribeEmail')));
const Social = Loadable(lazy(() => import('src/pages/Social')));

const PublicRoutes = [
  AuthRoutes,
  // Main Routes
  {
    path: '*',
    element: <LogoOnlyLayout />,
    children: [
      { path: 'unsubscribe', element: <UnsubscribeEmail /> },
      // { path: 'campaigngroup-timeline', element: <CampaignGroupTimeline /> },
      { path: 'social', element: <Social /> },
      { path: 'coming-soon', element: <ComingSoon /> },
      { path: 'maintenance', element: <Maintenance /> },
      { path: 'pricing', element: <Pricing /> },
      { path: 'payment', element: <Payment /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      // { element: <HomePage />, index: true },
      { element: <Navigate to="/auth/login" />, index: true },
      { path: 'about-us', element: <About /> },
      { path: 'contact-us', element: <Contact /> },
      { path: 'faqs', element: <Faqs /> },
    ],
  },
];

export default PublicRoutes;
