export const test = () => {};

export const getSearchResultsSocialLinks = (links: string[]) => {
  if (!links.length) return [];

  return links.map((link) => {
    return { full: link, short: link.slice(0, 17) };
  });
};

export function serializeText(value: string | any): string {
  return typeof value === 'string' ? value : 'N/A';
}

export const getCreatedBy = (_id: string, user: any) => {
  if (!user) return 'Unknown';

  if (_id === user._id || _id === user) return 'You';

  return `${user?.firstName} ${user?.lastName}`;
};
