import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import { IBookmark, IBookmarkParams } from 'src/common/interfaces';
import { BookmarksApi, deleteApi, getApi, patchApi, postApi } from 'src/common/apis';

// ---------------------------------------------------------------------

type BookmarkState = {
  isLoading: boolean;
  error: Error | string | null;
  bookmarks: IBookmark[] | [];
  count: number;
};

const initialState: BookmarkState = {
  isLoading: false,
  error: null,
  bookmarks: [],
  count: 0,
};

const slice = createSlice({
  name: 'bookmark',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MAILS
    getBookmarksSuccess(state, action) {
      state.isLoading = false;
      state.bookmarks = action.payload.bookmarks;
      state.count = action.payload.count;
    },

    remove(state, action) {
      state.isLoading = false;
      state.bookmarks = state.bookmarks.filter((item) => item._id !== action.payload);
      state.count -= 1;
    },

    removeMany(state, action) {
      state.isLoading = false;
      state.bookmarks = state.bookmarks.filter((item) => !action.payload.includes(item._id));
      state.count -= action.payload.length;
    },

    add(state, action) {
      state.isLoading = false;
      state.bookmarks = [action.payload, ...state.bookmarks];
      state.count += 1;
    },

    updateOne(state, action) {
      state.isLoading = false;
      const index = state.bookmarks.findIndex((item) => item._id === action.payload._id);
      let tempBookmarks = [...state.bookmarks];
      tempBookmarks[index] = action.payload.values;
      state.bookmarks = tempBookmarks;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBookmarks(
  params: IBookmarkParams = {
    page: 1,
    skip: 0,
    limit: 1000,
  }
) {
  console.log('log: bookmarks');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await getApi({ url: BookmarksApi.base, showToast: false, params });

      if (res.error) return dispatch(slice.actions.hasError(res.error));

      dispatch(slice.actions.getBookmarksSuccess(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeBookmark(_id: string, showToast: boolean = true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await deleteApi({ url: `${BookmarksApi.base}/${_id}`, showToast });

      if (res.error) return dispatch(slice.actions.hasError(res.error));

      dispatch(slice.actions.remove(_id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeBookmarks(_ids: string[], showToast: boolean = true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await deleteApi({ url: BookmarksApi.removeMany, values: { _ids }, showToast });

      if (res.error) return dispatch(slice.actions.hasError(res.error));

      dispatch(slice.actions.removeMany(_ids));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createBookmark(values: any, showToast: boolean = true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await postApi({ url: BookmarksApi.base, values, showToast });

      if (res.error) return dispatch(slice.actions.hasError(res.error));

      dispatch(slice.actions.add(res.data.bookmark));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateBookmark(_id: string, values: any, showToast: boolean = true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await patchApi({
        url: `${BookmarksApi.base}/${_id}`,
        values: values,
        showToast,
      });

      if (res.error) return dispatch(slice.actions.hasError(res.error));

      dispatch(slice.actions.updateOne({ _id, values }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
