import { Card, Grid, TextField, Box, Stack, MenuItem, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { BookmarkGroupsApi, postApi } from 'src/common/apis';
import { AddToBookmarkSchema } from 'src/common/schemas';
import { useFormik, Form, FormikProvider } from 'formik';
import { EBookmarkType } from 'src/common/enums';
import { sentenceCase } from 'change-case';
import { useBookmark } from 'src/hooks';
import { toast } from 'react-hot-toast';

interface IProps {
  type: EBookmarkType;
  members: string[] | [];
}

const AddToBookmarkForm = ({ type, members }: IProps) => {
  const { bookmark } = useBookmark();

  const addToBookmarkSchema = new AddToBookmarkSchema(type);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: addToBookmarkSchema.initialValues,
    validationSchema: addToBookmarkSchema.schema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        await _handleAddToBookmark(values);

        resetForm();
      } catch (error) {
        setErrors(error);
      }
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik;

  const _handleAddToBookmark = async (values: any) => {
    if (!members.length) {
      toast.error('Please select at least 1 member');
      throw new Error('Please select at least 1 member');
    }

    let res = null;

    res = await postApi({
      url: BookmarkGroupsApi.base,
      values: { ...values, [type]: members },
      showToast: true,
    });

    if (!res || res.error) throw new Error(res.message);
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Card sx={{ py: 1 }}>
          <Stack spacing={2}>
            <TextField
              select
              fullWidth
              label="Select Bookmark"
              {...getFieldProps('bookmark')}
              error={Boolean(touched.bookmark && errors.bookmark)}
              helperText={touched.bookmark && errors.bookmark}
            >
              <MenuItem value="" />
              {bookmark.bookmarks.map((value) => {
                if (value.type === type)
                  return (
                    <MenuItem key={value._id} value={value._id}>
                      {sentenceCase(value.title)}
                    </MenuItem>
                  );
              })}
            </TextField>

            <Divider />

            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Add
              </LoadingButton>
            </Box>
          </Stack>
        </Card>
      </Form>
    </FormikProvider>
  );
};

export default AddToBookmarkForm;
