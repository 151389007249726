import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import { ETableFilter } from 'src/common/enums';
import { IPaginationParams, ITableFilterSettings, ITableFilters } from 'src/common/interfaces';

// ---------------------------------------------------------------------

const initialPagination: IPaginationParams = {
  page: 0,
  rowsPerPage: 10,
};

const initialSettings: ITableFilterSettings = {
  open: false,
  loading: false,
  error: '',
  isInitialized: false,
};

// Create a type for the valid keys of ITableFilters
type TableFilterKey = keyof ITableFilters;

const initialFilterItem = {
  settings: initialSettings,
  params: {
    ...initialPagination,
  },
};

export const initialTableFilterState: ITableFilters = {
  clutch: initialFilterItem,
  techbehemoth: initialFilterItem,
  contact: initialFilterItem,
  searchResult: initialFilterItem,
  linkedin: initialFilterItem,
  career: initialFilterItem,
  contactForm: initialFilterItem,
  externalContact: initialFilterItem,
  customMail: initialFilterItem,
  customMailInbox: initialFilterItem,
  imapAccount: initialFilterItem,
};

interface IKeyPayload {
  key: string | ETableFilter;
}

interface IHasErrorPayload extends IKeyPayload {
  error: string;
}

interface IHandleParamChangePayload extends IKeyPayload {
  name: string;
  value: string | number;
}

interface IUpdateParams extends IKeyPayload {
  params: any;
}

const slice = createSlice({
  name: 'tableFilter',
  initialState: initialTableFilterState,
  reducers: {
    // START LOADING
    startLoading(state, action: PayloadAction<IKeyPayload>) {
      return produce(state, (draftState) => {
        const key = action.payload.key as TableFilterKey;
        draftState[key].settings.loading = true;
      });
    },

    // HAS ERROR
    hasError(state, action: PayloadAction<IHasErrorPayload>) {
      return produce(state, (draftState) => {
        const key = action.payload.key as TableFilterKey;
        draftState[key].settings.loading = false;
        draftState[key].settings.error = action.payload.error;
      });
    },

    // TOGGLE OPEN
    toggleOpen(state, action: PayloadAction<IKeyPayload>) {
      return produce(state, (draftState) => {
        const key = action.payload.key as TableFilterKey;
        draftState[key].settings.open = !draftState[key].settings.open;
      });
    },

    // TOGGLE OPEN
    clear(state, action: PayloadAction<IKeyPayload>) {
      return produce(state, (draftState) => {
        const key = action.payload.key as TableFilterKey;
        draftState[key].params = initialFilterItem.params;
      });
    },

    updateParams(state, action: PayloadAction<IUpdateParams>) {
      return produce(state, (draftState) => {
        const key = action.payload.key as TableFilterKey;
        draftState[key].params = {
          ...draftState[key].params,
          ...action.payload.params,
        };
      });
    },

    handleParamChange(state, action: PayloadAction<IHandleParamChangePayload>) {
      const { name, value } = action.payload;
      return produce(state, (draftState) => {
        const key = action.payload.key as TableFilterKey;
        draftState[key].settings.loading = false;
        draftState[key].params = { ...draftState[key].params, [name]: value };
      });
    },
  },
});

// Reducer
export default slice.reducer;

export const { startLoading, toggleOpen, hasError, clear, handleParamChange, updateParams } =
  slice.actions;
