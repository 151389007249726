import { Card, TextField, Box, Stack, MenuItem, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CreateBookmarkSchema } from 'src/common/schemas';
import { useFormik, Form, FormikProvider } from 'formik';
import { EBookmarkType } from 'src/common/enums';
import { sentenceCase } from 'change-case';
import { useBookmark } from 'src/hooks';
import { createBookmark, updateBookmark } from 'src/redux/slices/bookmark';
import { useEffect } from 'react';

interface IProps {
  isEdit: boolean;
  currentBookmark: any | null;
}

const BookmarkForm = ({ isEdit, currentBookmark }: IProps) => {
  const createBookmarkSchema = new CreateBookmarkSchema();
  const { dispatch, bookmark } = useBookmark();

  const initialValues = isEdit ? currentBookmark : createBookmarkSchema.initialValues;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: createBookmarkSchema.schema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        await _handleBookmark(values);

        if (!isEdit && !bookmark.error) resetForm();
      } catch (error) {
        setErrors(error);
      }
    },
  });

  useEffect(() => {
    console.log('log: bookmark', bookmark);
  }, [bookmark]);

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const _handleBookmark = async (values: any) => {
    if (isEdit) {
      await dispatch(updateBookmark(currentBookmark._id, values));
    } else {
      await dispatch(createBookmark(values));
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Card sx={{ py: 1 }}>
          <Stack spacing={2}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <TextField
                fullWidth
                label="Title"
                {...getFieldProps('title')}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />

              <TextField
                select
                fullWidth
                label="Type"
                {...getFieldProps('type')}
                error={Boolean(touched.type && errors.type)}
                helperText={touched.type && errors.type}
              >
                <MenuItem value="" />
                {Object.values(EBookmarkType).map((value: string) => (
                  <MenuItem key={value} value={value}>
                    {sentenceCase(value)}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>

            <TextField
              multiline
              fullWidth
              label="Description"
              {...getFieldProps('description')}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
              rows={3}
            />

            <Divider />

            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton type="submit" variant="contained" loading={bookmark.isLoading}>
                {!isEdit ? 'Add' : 'Save Changes'}
              </LoadingButton>
            </Box>
          </Stack>
        </Card>
      </Form>
    </FormikProvider>
  );
};

export default BookmarkForm;
