export const RATING_FILTERS = [
  {
    label: '1. ⭐ and above',
    value: 1,
  },
  {
    label: '2. ⭐⭐ and above',
    value: 2,
  },
  {
    label: '3. ⭐⭐⭐ and above',
    value: 3,
  },
  {
    label: '4. ⭐⭐⭐⭐ and above',
    value: 4,
  },
  {
    label: '5. ⭐⭐⭐⭐⭐ only',
    value: 5,
  },
];

// TECHBEHEMOTH
export const TECHBEHEMOTH_TEAM_SIZE_FILTERS = [
  {
    label: '2-9',
    value: '2-9',
  },
  {
    label: '10-49',
    value: '10-49',
  },
  {
    label: '50-249',
    value: '50-249',
  },
  {
    label: '250-999',
    value: '250-999',
  },
  {
    label: '1000-9999',
    value: '1000-9999',
  },
  {
    label: '1000+',
    value: '1000+',
  },
];

export const TECHBEHEMOTH_HOURLY_RATE_FILTERS = [
  {
    label: '<$30/h',
    value: '<$30/h',
  },
  {
    label: '$30-70/h',
    value: '$30-70/h',
  },
  {
    label: '$70-150/h',
    value: '$70-150/h',
  },
  {
    label: '$150-250/h',
    value: '$150-250/h',
  },
  {
    label: '$250+/h',
    value: '$250+/h',
  },
];

// CLUTCH
export const CLUTCH_HOURLY_RATE_FILTERS = [
  {
    label: '<$25 / hr',
    value: '< $25 / hr',
  },
  {
    label: '$25 - $49 / hr',
    value: '$25 - $49 / hr',
  },
  {
    label: '$50 - $99 / hr',
    value: '$50 - $99 / hr',
  },
  {
    label: '$100 - $149 / hr',
    value: '$100 - $149 / hr',
  },
  {
    label: '$150 - $199 / hr',
    value: '$150 - $199 / hr',
  },
  {
    label: '$200 - $300 / hr',
    value: '$200 - $300 / hr',
  },
  {
    label: '$300+ / hr',
    value: '$300+ / hr',
  },
];

export const CLUTCH_EMPLOYEES_COUNT_FILTERS = [
  {
    label: '2 - 9',
    value: '2 - 9',
  },
  {
    label: '10 - 49',
    value: '10 - 49',
  },
  {
    label: '50 - 249',
    value: '50 - 249',
  },
  {
    label: '250 - 999',
    value: '250 - 999',
  },
  {
    label: '1,000 - 9,999',
    value: '1,000 - 9,999',
  },
  {
    label: '10,000+',
    value: '10,000+',
  },
];

// SEARCH RESULT

export const SEARCH_RESULT_EMAIL_VERIFIED_FILTERS = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];

export const SEARCH_RESULT_STATUS_FILTERS = [
  {
    label: 'Blocked',
    value: 'true',
  },
  {
    label: 'Unblocked',
    value: 'false',
  },
];

export const SEARCH_RESULT_RATING_FILTERS = [
  {
    label: '1. ⭐',
    value: 1,
  },
  {
    label: '2. ⭐⭐',
    value: 2,
  },
  {
    label: '3. ⭐⭐⭐',
    value: 3,
  },
  {
    label: '4. ⭐⭐⭐⭐',
    value: 4,
  },
  {
    label: '5. ⭐⭐⭐⭐⭐',
    value: 5,
  },
];
export const SEARCH_RESULT_WEBSITEINFO_FILTERS = [
  {
    label: 'Software Development',
    value: 'software_development',
  },
  {
    label: 'Web Development',
    value: 'web_development',
  },
  {
    label: 'Aiml Development',
    value: 'aiml_development',
  },
  { label: 'Seo Smo', value: 'seo_smo' },
  {
    label: 'Blockchain Technology',
    value: 'blockchain_technology',
  },
  {
    label: 'Mobile Development',
    value: 'mobile_development',
  },
  {
    label: 'Data Science',
    value: 'data_science',
  },
];
export enum EContactFormTypes {
  FREE_CONSULTANCY = 'Free consultancy',
  CAREERS = 'Careers',
  CONTACT_US = 'Contact Us',
  NEWSLETTER = 'Newsletter',
  ADMIN = 'admin',
  RECOVER_HACKED_WEBSITE = 'Recover Hacked Website',
}
export const CONTACT_FORM_FORM_TYPES_FILTERS = [
  {
    label: 'Free consultancy',
    value: 'Free consultancy',
  },
  {
    label: 'CAREERS',
    value: 'Careers',
  },
  {
    label: 'CONTACT US',
    value: 'CONTACT US',
  },
  {
    label: 'NEWSLETTER',
    value: 'Newsletter',
  },
  {
    label: 'ADMIN',
    value: 'admin',
  },
  {
    label: 'RECOVER HACKED WEBSITE',
    value: 'Recover Hacked Website',
  },
];

//contact
export const CONTACT_STATUS_FILTERS = [
  {
    label: 'Blocked',
    value: 'blocked',
  },
  {
    label: 'Open',
    value: 'Open',
  },
];

export const CONTACT_TYPE_FILTERS = [
  {
    label: 'Lead',
    value: 'lead',
  },
  {
    label: 'Client',
    value: 'client',
  },
  {
    label: 'Other',
    value: 'other',
  },
];
