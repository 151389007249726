import { TextField, Box, Stack, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { NotesApi, patchApi, postApi } from 'src/common/apis';
import { CreateNoteSchema } from 'src/common/schemas';
import { useFormik, Form, FormikProvider } from 'formik';
import { ENotesType } from 'src/common/enums';
import Editor from 'src/components/editor';

interface IProps {
  isEdit: boolean;
  currentNote: any | null;
  type: ENotesType;
  categoryId?: string;
  callback: any;
}

const NotesCreate = ({ isEdit, currentNote, type, categoryId, callback = () => {} }: IProps) => {
  const createNoteSchema = new CreateNoteSchema();

  const initialValues = isEdit ? currentNote : createNoteSchema.initialValues;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: createNoteSchema.schema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        await _handleCreateNote(values);

        if (!isEdit) resetForm();
      } catch (error) {
        setErrors(error);
      }
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } =
    formik;

  const _handleCreateNote = async (values: any) => {
    let res = null;

    let finalValues = { ...values, type };

    if (type !== ENotesType.OTHER) finalValues = { ...finalValues, [type]: categoryId };

    if (isEdit) {
      // patch api
      res = await patchApi({
        url: `${NotesApi.base}/${currentNote._id}`,
        values: finalValues,
        showToast: true,
      });
    } else {
      res = await postApi({ url: NotesApi.base, values: finalValues, showToast: true });
    }

    if (!res || res.error) throw new Error(res.message);

    callback(isEdit ? values : res.data.note);
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            label="Title"
            {...getFieldProps('title')}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
          />
          {/**
             * <TextField
            multiline
            fullWidth
            label="Description"
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            rows={3}
          />
             */}

          <Editor
            simple
            id="compose-mail"
            value={values.description}
            onChange={(value) => {
              setFieldValue('description', value);
            }}
            placeholder="Type a message"
            sx={{
              borderColor: 'transparent',
              flexGrow: 1,
            }}
          />

          <Divider />

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              {!isEdit ? 'Add' : 'Save Changes'}
            </LoadingButton>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default NotesCreate;
