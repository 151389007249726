const base = '/mail';

// const findAllUnsubscribedEmails = ({ candidate, page = 1, limit = 10 }: any) => {
//   let url = base + '/unsubscribe/all' + '?';

//   if (page) url += `page=${page}&`;

//   if (limit) url += `limit=${limit}&`;

//   return url.slice(0, -1);
// };

const MailApi = {
  base,
  unsubscribe: base + '/unsubscribe',
  unsubscribeStatus: base + '/unsubscribe-status',
  redirectUrl: base + 'redirect-url',
  findAllUnsubscribedEmails: base + '/unsubscribe/all',
};
export default MailApi;
