import { Button, Card, Container, Grid, TextField, Box } from '@mui/material';
import { Page } from '@react-pdf/renderer';
import { CPageTitles } from 'src/common/constants';
import { HeaderBreadcrumbs, Iconify } from 'src/components';
import { useSettings } from 'src/hooks';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import ReactTrello from 'react-trello';

const CustomLane = ( lane:any ) => {
  console.log(",lane", lane.cards)
  return (
    <Grid key={lane.id} style={{ backgroundColor: 'rgba(231, 231, 231, 0.12)', padding: '10px 0px 10px 0px', display:"flex", borderColor:"#E7E7E7", borderTop:"4px solid"}}>
      <div style={{ fontWeight: 'bold', color:"grey", fontSize:"14px", paddingLeft:"10px"}}>{lane.title}</div>
      <div style={{fontWeight:"500", color:"grey", fontSize:"14px", paddingLeft:"5px"}}>({lane.cards.length})</div>
      {/* {lane.cards.map((card:any) => (
        <CustomCard key={card.id} card={card} />

      ))} */}
    </Grid>
  );
};

// Custom Card component
const CustomCard = (card:any ) => {
  return (
    <Grid style={{ margin: '10px', padding: '10px', backgroundColor:"white", border:"0.5px solid", borderColor:"#E7E7E7" , borderRadius:"5px", borderLeft:"3px solid"}}>
      <div style={{ fontSize:"12px" }}>{card.title}</div>
      <Grid style={{ fontSize:"12px", display:"flex", gap:"5px" }}>
      <div style={{fontWeight:"bold", color:"grey"}}>Deal Amount:</div>
      <div>${card.amount}</div>
      </Grid>
      <Grid style={{ fontSize:"12px", display:"flex", gap:"5px" }}>
      <div style={{fontWeight:"bold", color:"grey"}}>Stage:</div>
      <div>${card.amount}</div>
      </Grid>
    </Grid>
  );
};

const Add = (card:any ) => {
  return (
    <div >
      +
    </div>
  );
};


const data = {
  lanes: [
    {
      id: 'PLANNED',
      title: 'Planned Tasks',
      label: '20/70',
      cards: [
        {
          id: 'Milk',
          title: '150 coffee mugs for Google',
          label: '15 mins',
          cardStyle: { width: 270, maxWidth: 270, margin: 'auto', marginBottom: 5 },
          amount: 1500,
        },
        {
          id: 'Plan2',
          title: 'Dispose Garbage',
          label: '10 mins',
          cardStyle: { width: 270, maxWidth: 270, margin: 'auto', marginBottom: 5 },
          amount: 'Sort out recyclable and waste as needed',
        },
        {
          id: 'Plan3',
          title: 'Write Blog',
          label: '30 mins',
          cardStyle: { width: 270, maxWidth: 270, margin: 'auto', marginBottom: 5 },
          amount: 'Can AI make memes?',
        },
        {
          id: 'Plan4',
          title: 'Pay Rent',
          label: '5 mins',
          cardStyle: { width: 270, maxWidth: 270, margin: 'auto', marginBottom: 5 },
          amount: 'Transfer to bank account',
        },
      ],
    },
    {
      id: 'WIP',
      title: 'Work In Progress',
      label: '10/20',
   
      cards: [
        {
          id: 'Wip1',
          title: 'Clean House',
          label: '30 mins',
          cardStyle: { width: 270, maxWidth: 270, margin: 'auto', marginBottom: 5 },
          amount:
            'Soap wash and polish floor. Polish windows and doors. Scrap all broken glasses',
        },
      ],
    },
    {
      id: 'COMPLETED',
      title: 'Completed',
      
      label: '2/5',
      cards: [
        {
          id: 'Completed1',
          title: 'Practice Meditation',
          label: '15 mins',
          cardStyle: { width: 270, maxWidth: 270, margin: 'auto', marginBottom: 5 },
          amount: 'Use Headspace app',
        },
        {
          id: 'Completed2',
          title: 'Maintain Daily Journal',
          label: '15 mins',
          cardStyle: { width: 270, maxWidth: 270, margin: 'auto', marginBottom: 5 },
          amount: 'Use Spreadsheet for now',
        },
      ],
    },

  ],
};



const handleDragStart = (cardId: any, laneId: any) => {
  console.log('drag started');
  console.log(`cardId: ${cardId}`);
  console.log(`laneId: ${laneId}`);
};

const handleDragEnd = (cardId: any, sourceLaneId: any, targetLaneId: any) => {
  console.log('drag ended');
  console.log(`cardId: ${cardId}`);
  console.log(`sourceLaneId: ${sourceLaneId}`);
  console.log(`targetLaneId: ${targetLaneId}`);
};

function DealsList() {
  const { themeStretch } = useSettings();

  const [boardData, setBoardData] = useState<any>({ lanes: [] });
  const [eventBus, setEventBus] = useState<any>(null);
  const [newCardTitle, setNewCardTitle] = useState<string>('');

  const getBoard = useCallback(async () => {
    return data;
  }, []);

  useEffect(() => {
    const fetchBoard = async () => {
      const response = await getBoard();
      setBoardData(response);
    };
    fetchBoard();
  }, [getBoard]);

  const completeCard = () => {
    eventBus.publish({
      type: 'ADD_CARD',
      laneId: 'COMPLETED',
      card: {
        id: 'Milk',
        title: 'Buy Milk',
        label: '15 mins',
        amount: 'Use Headspace app',
      },
    });
    eventBus.publish({
      type: 'REMOVE_CARD',
      laneId: 'PLANNED',
      cardId: 'Milk',
    });
  };

  const addCard = () => {
    eventBus.publish({
      type: 'ADD_CARD',
      laneId: 'newCardTitle',
      card: {
        id: 'Ec2Error',
        title: 'EC2 Instance Down',
        label: '30 mins',
        amount: 'Main EC2 instance down',
      },
    });
  };

  // const onLaneAdd = (params: any) => {
  //   console.log('New lane added:', params);
  //   // You can perform additional actions here if needed
  // };

  const addLane = () => {
    const newLaneId = newCardTitle.replace(/\s+/g, '').toLowerCase();
    const newLane = {
      id: newLaneId,
      title: newCardTitle,
      
      label: '0/0',
      cards: [],
    };
    setBoardData((prevData: any) => {
      const newData = { lanes: [...prevData.lanes, newLane] };
      return newData;
    });
  };

  const shouldReceiveNewData = (nextData: any) => {
    console.log('New card has been added');
    console.log(nextData);
  };

  const handleCardAdd = (card: any, laneId: any) => {
    console.log(`New card added to lane ${laneId}`);
    console.dir(card);
  };

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="Deals"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Deals',
              href: PATH_DASHBOARD.deals.list,
            },
            { name: 'Deals List' },
          ]}
          action={
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              component={RouterLink}
              to={PATH_DASHBOARD.deals.create}
            >
              New Deals
            </Button>
          }
        />
        <Card>
          <Grid className="App-intro">
            {/* <button onClick={completeCard} style={{ margin: 5 }}>
          Complete Buy Milk
        </button> */}
            {/* <button onClick={addCard} style={{ margin: 5 }}>
          Add Blocked
        </button> */}
            <Grid container justifyContent="left">
              <TextField
                label="New Lane"
                value={newCardTitle}
                onChange={(e) => setNewCardTitle(e.target.value)}
                variant="outlined"
                margin="normal"
              />
              <Button variant="contained" onClick={addLane} style={{ margin: '20px' }}>
                Add Lane
              </Button>
            </Grid>
            <ReactTrello
            components={{ LaneHeader: CustomLane, Card: CustomCard }}
              editable
              // onCardAdd={handleCardAdd}
              data={boardData}
              draggable
              onDataChange={shouldReceiveNewData}
              // eventBusHandle={setEventBus}
              // handleDragStart={handleDragStart}
              // handleDragEnd={handleDragEnd}
              // onLaneAdd={addLane}
              // canAddLanes
              laneStyle={{ backgroundColor:"white", width:"300px", border:"0.5px solid", borderColor:"#E7E7E7" , padding:"0px", borderRadius:"3px" }}
              style={{ backgroundColor: 'white', height: 'auto', padding: '20px' }}
            />
          </Grid>
        </Card>
      </Container>
    </Page>
  );
}

export default DealsList;
