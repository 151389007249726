import { Box, CircularProgress, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface INoContentBox {
  children?: ReactNode;
}

export const NoContentBox = ({ children }: INoContentBox) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
      <Typography>{children ? children : 'No content available 🚫'}</Typography>
    </Box>
  );
};

interface ILoadingBox {
  height?: string | number;
}

export const LoadingBox = ({ height = '500px' }: ILoadingBox) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height }}>
      <CircularProgress size={28} />
    </Box>
  );
};
