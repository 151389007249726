export enum ETableFilter {
  CLUTCH = 'clutch',
  TECHBEHEMOTH = 'techbehemoth',
  CONTACT = 'contact',
  SEARCH_RESULT = 'searchResult',
  LINKEDIN = 'linkedin',
  CAREER = 'career',
  CONTACT_FORM = 'contactForm',
  EXTERNAL_CONTACT = 'externalContact',
  CUSTOM_MAIL = 'customMail',
}
