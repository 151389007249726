import { Box, Grid, Link } from '@mui/material';
import { sentenceCase } from 'change-case';
import { ECampaignGroupTypes, ECampaignStatus, ESmtpAccountsStatus } from 'src/common/enums';
import { getCampaignGroupKey } from 'src/common/functions';
import { CardInfoItem, CardLayout } from 'src/components/cards';
import { getReplyColor } from '../actions';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';




interface IProps {
  campaignTimeline: any;
  type: ECampaignGroupTypes;
  setOpenReply:any

}
const CampaignTimelineView = ({ campaignTimeline, type, setOpenReply }: IProps) => {
  

  const onOpen = (item:any) => {
    setOpenReply(item?.content);
    if(item?.content){
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('EmailReply', 'true');
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
    }
  };
  return (
    <Box sx={{ p: 1 }}>
      <Grid container spacing={3}>
        <Grid item md={6} sm={12} xs={12}>
          <CardLayout title="Timeline">
            <Box>
              <CardInfoItem
                title="Reply Type"
                value={
                  campaignTimeline.replyType ? sentenceCase(campaignTimeline.replyType) : 'N/A'
                }
                chip={true}
              />

              <CardInfoItem
                title="Status"
                value={campaignTimeline.status ? sentenceCase(campaignTimeline.status) : 'N/A'}
                chip={true}
              />

              <CardInfoItem title="Email" value={campaignTimeline.email || 'N/A'} />

              <CardInfoItem
                title="Campaign Step"
                value={
                  campaignTimeline.campaign?.steps?.find(
                    (item: any) => item.order === campaignTimeline.campaignStepOrder
                  )?.sequence || 'N/A'
                }
                chip={true}
              />

              <CardInfoItem
                title="Campaign order No"
                value={campaignTimeline.campaignStepOrder || 'N/A'}
              />
            </Box>
          </CardLayout>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <CardLayout title="Email Template">
            <Box>
              <CardInfoItem title="Name" value={campaignTimeline.emailTemplate.name || 'N/A'} />

              <span onClick={()=>{onOpen(campaignTimeline.emailTemplate)}} style={{cursor:"pointer"}}>
              <CardInfoItem
                title="Subject"
                value={campaignTimeline.emailTemplate.subject || 'N/A'}
              />
              </span>

              <CardInfoItem
                title="Status"
                value={sentenceCase(campaignTimeline.campaign.status) || 'N/A'}
                chip={true}
                chipColor={
                  campaignTimeline.campaign.status === ECampaignStatus.ACTIVE ? 'success' : 'danger'
                }
              />

              <CardInfoItem
                title="Type"
                value={sentenceCase(campaignTimeline.emailTemplate.type) || 'N/A'}
                chip={true}
                chipColor={
                  campaignTimeline.campaign.status === ECampaignStatus.ACTIVE ? 'success' : 'danger'
                }
              />

              <CardInfoItem
                title="Description"
                value={campaignTimeline.emailTemplate.description || 'N/A'}
              />
            </Box>
          </CardLayout>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <CardLayout title="SMTP Account Used">
            <Box>
              <CardInfoItem title="label" value={campaignTimeline.smtpAccount.label || 'N/A'} />

              <CardInfoItem
                title="Description"
                value={campaignTimeline.smtpAccount.description || 'N/A'}
              />

              <CardInfoItem title="From" value={campaignTimeline.smtpAccount.from || 'N/A'} />

              <CardInfoItem title="Server" value={campaignTimeline.smtpAccount.server || 'N/A'} />

              <CardInfoItem
                title="Port"
                value={campaignTimeline.smtpAccount.port || 'N/A'}
                chip={true}
              />

              <CardInfoItem
                title="Status"
                value={sentenceCase(campaignTimeline.smtpAccount.status) || 'N/A'}
                chip={true}
                chipColor={
                  campaignTimeline.smtpAccount.status === ESmtpAccountsStatus.ACTIVE
                    ? 'success'
                    : 'danger'
                }
              />
            </Box>
          </CardLayout>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <CardLayout title="Selected Campaign">
            <Box>
              <CardInfoItem title="Name" value={campaignTimeline.campaign.name || 'N/A'} />

              <CardInfoItem
                title="Description"
                value={campaignTimeline.campaign.description || 'N/A'}
              />

              <CardInfoItem
                title="Status"
                value={sentenceCase(campaignTimeline.campaign.status) || 'N/A'}
                chip={true}
                chipColor={
                  campaignTimeline.campaign.status === ECampaignStatus.ACTIVE ? 'success' : 'danger'
                }
              />

              <CardInfoItem
                title="No of step"
                value={campaignTimeline.campaign.steps.length || 'N/A'}
              />
            </Box>
          </CardLayout>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <CardLayout title="In Campaign Group">
            <Box>
              <CardInfoItem title="Name" value={campaignTimeline.campaignGroup.name || 'N/A'} />

              <CardInfoItem
                title="Description"
                value={campaignTimeline.campaignGroup.description || 'N/A'}
              />

              <CardInfoItem
                title="Status"
                value={sentenceCase(campaignTimeline.campaign.status) || 'N/A'}
                chip={true}
                chipColor={
                  campaignTimeline.campaign.status === ECampaignStatus.ACTIVE ? 'success' : 'danger'
                }
              />

              <CardInfoItem
                title="No of members"
                value={campaignTimeline.campaignGroup?.[getCampaignGroupKey(type)].length || 'N/A'}
              />
            </Box>
          </CardLayout>
        </Grid>
        <Grid item md={6} sm={12} xs={12}  >
          <Swiper
            style={{padding:'5px'}}
            modules={[Pagination, A11y, Autoplay]}
            spaceBetween={10}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSlideChange={() => console.log('slide change')}
            speed={1100}

          >
            {campaignTimeline?.reply?.map((item: any, index: number) => {
              return (
                <SwiperSlide key={index} >
                  <CardLayout title="Email Reply">
                    <Box>
                      <CardInfoItem title="Email" value={item.email || 'N/A'} />

                      <CardInfoItem title="Sender" value={item.sender || 'N/A'}  />
                     
                      <span onClick={()=>{onOpen(item)}} style={{cursor:"pointer"}}>
                      <CardInfoItem title="Subject" value={item.subject || 'N/A'} />
                      </span>

                      <CardInfoItem title="Message" value={item.message || 'N/A'} />
                    </Box>
                  </CardLayout>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CampaignTimelineView;
