import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import { IBookmark, IBookmarkParams, IIndividualCampaign } from 'src/common/interfaces';
import { IndividualCampaignsApi, deleteApi, getApi, patchApi, postApi } from 'src/common/apis';
import { ICampaignParams } from 'src/common/interfaces/bookmarks.interface';

// ---------------------------------------------------------------------

type IndividualCampaignState = {
  isLoading: boolean;
  error: Error | string | null;
  individualCampaign: IIndividualCampaign[] | [];
  count: number;
};

const initialState: IndividualCampaignState = {
  isLoading: false,
  error: null,
  individualCampaign: [],
  count: 0,
};

const slice = createSlice({
  name: 'individualCampaign',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MAILS
    getCampaignSuccess(state, action) {
      state.isLoading = false;
      state.individualCampaign = action.payload.individualCampaign;
      state.count = action.payload.count;
    },

    remove(state, action) {
      state.isLoading = false;
      state.individualCampaign = state.individualCampaign.filter(
        (item) => item._id !== action.payload
      );
      state.count -= 1;
    },

    removeMany(state, action) {
      state.isLoading = false;
      state.individualCampaign = state.individualCampaign.filter(
        (item) => !action.payload.includes(item._id)
      );
      state.count -= action.payload.length;
    },

    add(state, action) {
      state.isLoading = false;
      state.individualCampaign = [action.payload, ...state.individualCampaign];
      state.count += 1;
    },

    updateOne(state, action) {
      state.isLoading = false;
      const index = state.individualCampaign.findIndex((item) => item._id === action.payload._id);
      let tempBookmarks = [...state.individualCampaign];
      tempBookmarks[index] = action.payload.values;
      state.individualCampaign = tempBookmarks;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCampaigns(
  params: ICampaignParams = {
    page: 1,
    skip: 0,
    limit: 1000,
  }
) {
  console.log('log: individual campaign');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await getApi({ url: IndividualCampaignsApi.base, showToast: false, params });

      if (res.error) return dispatch(slice.actions.hasError(res.error));

      dispatch(slice.actions.getCampaignSuccess(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeCampaign(_id: string, showToast: boolean = true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await deleteApi({ url: `${IndividualCampaignsApi.base}/${_id}`, showToast });

      if (res.error) return dispatch(slice.actions.hasError(res.error));

      dispatch(slice.actions.remove(_id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeCampaigns(_ids: string[], showToast: boolean = true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await deleteApi({
        url: IndividualCampaignsApi.removeMany,
        values: { _ids },
        showToast,
      });

      if (res.error) return dispatch(slice.actions.hasError(res.error));

      dispatch(slice.actions.removeMany(_ids));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createCampaign(values: any, showToast: boolean = true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await postApi({ url: IndividualCampaignsApi.base, values, showToast });

      if (res.error) return dispatch(slice.actions.hasError(res.error));

      dispatch(slice.actions.add(res.data.individualCampaignGroups));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCampaign(_id: string, values: any, showToast: boolean = true) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await patchApi({
        url: `${IndividualCampaignsApi.base}/${_id}`,
        values: values,
        showToast,
      });

      if (res.error) return dispatch(slice.actions.hasError(res.error));

      dispatch(slice.actions.updateOne({ _id, values }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
