import { useState } from 'react';

// material
import { Typography, Box, TextField, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function TwoFAQRCodeInput({
  onVerify = (code: any) => {},
  goBack = () => {},
  loading = false,
}) {
  const [code, setCode] = useState('');

  const handleChange = (e: any) => setCode(e.target.value);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', py: 3, margin: '0 auto' }}>
      <Typography variant="h5">Please enter the 2FA code</Typography>
      <Typography variant="body2" mb={2}>
        Enter code from your authenticator app to verify a device
      </Typography>

      <TextField
        fullWidth
        autoComplete="on"
        type="text"
        label="Code"
        value={code}
        onChange={handleChange}
        size="small"
        required
      />

      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 5, sm: 4 }} mt={3}>
        <LoadingButton variant="outlined" onClick={goBack} fullWidth color="info">
          Back
        </LoadingButton>

        <LoadingButton
          variant="contained"
          onClick={() => onVerify(code)}
          fullWidth
          loading={loading}
        >
          Verify
        </LoadingButton>
      </Stack>
    </Box>
  );
}
