import { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// @mui
import { Container, Grid } from '@mui/material';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
// hooks
import useSettings from 'src/hooks/useSettings';

// components
import { Page, HeaderBreadcrumbs, NotesTimeline, CardLayout } from 'src/components';

import { ContactsApi, getApi } from 'src/common/apis';
import { CPageTitles } from 'src/common/constants';

// constants
import { Box } from '@mui/material';
import { ENotesType } from 'src/common/enums';
import ContactInfo from './widgets/ContactInfo';
import { ContactSchema } from 'src/common/schemas/contacts.schema';
import { IContacts } from 'src/common/interfaces';

// ----------------------------------------------------------------------

export default function ContactView() {
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const contactSchema = new ContactSchema();
  const [contactData, setContactData] = useState<IContacts>(contactSchema.initialValues);

  const { themeStretch } = useSettings();

  useEffect(() => {
    if (params.id) {
      getCareer(params.id);
    }
  }, [params]);

  const getCareer = async (id: string) => {
    setIsLoading(true);
    const res = await getApi({ url: `${ContactsApi.base}/${id}` });
    setIsLoading(false);

    if (res.error) return;

    // const { campaignGroup: tempCampaign } = res.data;

    setContactData(res.data.contact);
  };

  return (
    <Page title={CPageTitles.campaignGroups.view}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="Contact"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Contact List',
              href: PATH_DASHBOARD.contacts.list,
            },
            { name: 'Contact View' },
          ]}
          // action={
          //   <Button
          //     variant="contained"
          //     startIcon={<Iconify icon="eva:plus-fill" />}
          //     component={RouterLink}
          //     to={PATH_DASHBOARD.campaignGroups.create}
          //   >
          //     New Campaign Group
          //   </Button>
          // }
        />

        <Grid container spacing={3}>
          <Grid item md={6} sm={12} xs={12}>
            <CardLayout title="Contact"linkTo={`${PATH_DASHBOARD.contacts.root}/${contactData?._id}/edit`}
              >
              <ContactInfo contactData={contactData} />
            </CardLayout>
          </Grid>

          <Grid item md={6} sm={12} xs={12}>
            <Box sx={{ height: '100%' }}>
              <NotesTimeline type={ENotesType.CAREER} categoryId={contactData._id} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
