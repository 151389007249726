import * as Yup from 'yup';
import { EGender, EContentType, EContactType, EContactJobFrequency } from '../enums';
import { IContactAddress, IContactEmail, IContactPhoneNumber } from '../interfaces';
import { ICountryObject } from '../constants';

interface ICreateContactInitialValues {
  firstName: string;
  lastName?: string;
  age: number | null;
  gender: EGender;
  phoneNumbers: IContactPhoneNumber[];
  emails: IContactEmail[];
  address: IContactAddress;
  rating: number;
  reference: string;
  websites?: string[];
  socialLinks?: string[];
  technologies?: string[];
  countries?: string[];
  type: EContactType | string;
  expectedBillingAmount: number;
  jobFrequency: EContactJobFrequency | string | null;
}

interface IUpdateContactInitialValues extends ICreateContactInitialValues {
  _id: string;
}

export class CreateContactSchema {
  schema;

  initialValues: ICreateContactInitialValues;

  updateValues: IUpdateContactInitialValues;

  constructor() {
    this.schema = Yup.object({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().optional(),
      age: Yup.number().optional(),
      gender: Yup.string().oneOf(Object.values(EGender)).optional(),
      phoneNumbers: Yup.array()
        .of(
          Yup.object().shape({
            phoneNumber: Yup.string().required('Phone number is required'),
            type: Yup.string().required('Phone number type is required'),
          })
        )
        .required('Phone numbers are required')
        .min(0, 'Please add atleast 1 phone number'),
      emails: Yup.array()
        .of(
          Yup.object().shape({
            email: Yup.string().required('Email is required').email('Invalid email format'),
            type: Yup.string().required('Email type is required'),
          })
        )
        .required('Emails are required')
        .min(1, 'Please add atleast 1 email'),
      address: Yup.object().shape({
        street: Yup.string().optional(),
        city: Yup.string().optional(),
        state: Yup.string().optional(),
        zipCode: Yup.number().optional(),
        country: Yup.mixed<ICountryObject | string>().nullable().default(''),
      }),
      rating: Yup.number().optional(),
      reference: Yup.string().optional(),
      websites: Yup.array().of(Yup.string()).optional(),
      socialLinks: Yup.array().of(Yup.string()).optional(),
      technologies: Yup.array().of(Yup.string()).optional(),
      type: Yup.string().oneOf(Object.values(EContactType)).required('Contact type is required'),
      expectedBillingAmount: Yup.number().optional(),
      jobFrequency: Yup.string().nullable(),
      // countries: Yup.array().of(Yup.string()),
    });

    this.initialValues = {
      firstName: '',
      lastName: '',
      age: 0,
      gender: EGender.UNKNOWN,
      phoneNumbers: [],
      emails: [],
      address: {
        street: '',
        state: '',
        city: '',
        country: '',
        zipCode: null,
      },
      rating: 0,
      reference: '',
      websites: [],
      socialLinks: [],
      technologies: [],
      countries: [],
      type: '',
      expectedBillingAmount: 0,
      jobFrequency: EContactJobFrequency.NO_JOB_YET,
    };

    this.updateValues = {
      ...this.initialValues,
      _id: '',
    };
  }
}
