import * as Yup from 'yup';
import { EWorkStatus } from '../enums/contactForm.enum';
import { IContacts, IContactEmail, IContactPhoneNumber, IContactAddress } from '../interfaces';

export class ContactSchema {
  schema;

  initialValues: IContacts;

  updateValues: IContacts;

  constructor() {
    this.schema = Yup.object({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      age: Yup.number().required('Age is required'),
      gender: Yup.string().required('Gender is required'),
      phoneNumbers: Yup.array().of(
        Yup.object().shape({
          phoneNumber: Yup.string().required('Phone number is required'),
          type: Yup.string().required('Phone type is required'),
          isVerified: Yup.boolean(),
        })
      ),
      emails: Yup.array().of(
        Yup.object().shape({
          email: Yup.string().email('Invalid email format').required('Email is required'),
          type: Yup.string().required('Email type is required'),
          isVerified: Yup.boolean(),
        })
      ),
      address: Yup.object().shape({
        street: Yup.string().required('Street is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        country: Yup.string().required('Country is required'),
        zipCode: Yup.number().nullable(),
      }),
      rating: Yup.number().required('Rating is required'),
      reference: Yup.string().required('Reference is required'),
      websites: Yup.array().of(Yup.string()),
      socialLinks: Yup.array().of(Yup.string()),
      technologies: Yup.array(),
      status: Yup.string().required('Status is required'),
      type: Yup.string().required('Type is required'),
      countries: Yup.array().of(Yup.string()),
      createdBy: Yup.mixed(),
      updateBy: Yup.mixed(),
    });

    this.initialValues = {
      firstName: '',
      lastName: '',
      age: 0,
      gender: '',
      phoneNumbers: [],
      emails: [],
      address: {
        street: '',
        city: '',
        state: '',
        country: '',
        zipCode: null,
      },
      rating: 0,
      reference: '',
      websites: [],
      socialLinks: [],
      technologies: [],
      status: '',
      type: '',
      countries: [],
      createdBy: undefined,
      updateBy: undefined,
      _id: '',
    };

    this.updateValues = {
      ...this.initialValues,
      _id: '',
    };
  }
}
