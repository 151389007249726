import * as Yup from 'yup';

import { EBookmarkType } from '../enums';

interface IAddToBookmark {
  type: EBookmarkType;
  bookmark: string;
}

export class AddToBookmarkSchema {
  schema;

  initialValues: IAddToBookmark;

  constructor(type: EBookmarkType) {
    this.schema = Yup.object({
      type: Yup.string().required('Type is required'),
      bookmark: Yup.string().required('Please select a bookmark'),
    });

    this.initialValues = {
      type,
      bookmark: '',
    };
  }
}
