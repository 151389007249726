import { Card, Grid, TextField, Box, Stack, MenuItem, Typography } from '@mui/material';
import {
  TECHBEHEMOTH_HOURLY_RATE_FILTERS,
  TECHBEHEMOTH_TEAM_SIZE_FILTERS,
} from 'src/common/constants';
import { ETableFilter } from 'src/common/enums';
import { useTableFilter } from 'src/hooks';

const TechbehemothTableFilter = () => {
  const { tableFilter, onParamChange } = useTableFilter();

  const onChange = (e: any) => {
    onParamChange(ETableFilter.TECHBEHEMOTH, e);
  };

  const getFieldProps = (name: string) => {
    return { name, onChange };
  };

  return (
    <Box sx={{ px: 2, pb: 2 }}>
      <Card variant="outlined" sx={{ p: 2 }}>
        <Grid container spacing={3}>
          <Grid item md={12} lg={12}>
            <Typography sx={{ mb: 1 }}>Filter Techbehemoth Data</Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <TextField
                size="small"
                fullWidth
                label="Company name"
                {...getFieldProps('company_name')}
                value={tableFilter.techbehemoth.params.company_name || ''}
              />
              <TextField
                size="small"
                fullWidth
                label="Location"
                {...getFieldProps('location')}
                value={tableFilter.techbehemoth.params.location || ''}
              />

              {/**Team size */}
              <TextField
                size="small"
                select
                fullWidth
                label="Team Size"
                {...getFieldProps('team_size')}
                value={tableFilter.techbehemoth.params.team_size || ''}
              >
                <MenuItem value="" sx={{ height: 30 }}></MenuItem>
                {TECHBEHEMOTH_TEAM_SIZE_FILTERS.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>

              {/**Hourly Rate */}
              <TextField
                size="small"
                select
                fullWidth
                label="Hourly Rate"
                {...getFieldProps('hourly_rate')}
                value={tableFilter.techbehemoth.params.hourly_rate || ''}
              >
                <MenuItem value="" sx={{ height: 30 }}></MenuItem>
                {TECHBEHEMOTH_HOURLY_RATE_FILTERS.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default TechbehemothTableFilter;
