export enum ECampaignStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum ECampaignTimelinesReplyType {
  'POSITIVE' = 'positive',
  'NEGATIVE' = 'negative',
  'NEUTRAL' = 'neutral',
  'NO_REPLY' = 'no-reply',
}
