import {
  Card,
  Grid,
  TextField,
  Box,
  Stack,
  MenuItem,
  Divider,
  Autocomplete,
  Chip,
  FormHelperText,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SmtpAccountsApi, patchApi, postApi } from 'src/common/apis';
import { CreateSmtpAccountSchema } from 'src/common/schemas';
import { useFormik, Form, FormikProvider, FormikTouched, FormikErrors } from 'formik';
import { ESmtpAccountsStatus } from 'src/common/enums';
import { sentenceCase } from 'change-case';
import Editor from 'src/components/editor';
import ImapAccountsApi from 'src/common/apis/imap-accounts.api';
import { CreateImapAccountSchema } from 'src/common/schemas/create-imap-account.schema';

interface IProps {
  isEdit: boolean;
  currentImapAccount: any;
}

const ImapAccountsForm = ({ isEdit, currentImapAccount }: IProps) => {
  const theme = useTheme();
  const createImapAccountSchema = new CreateImapAccountSchema();

  const initialValues = isEdit ? currentImapAccount : createImapAccountSchema.initialValues;
  console.log('editData:', currentImapAccount);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: createImapAccountSchema.schema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        await _handleImapSubmit(values);

        if (!isEdit) resetForm();
      } catch (error) {
        setErrors(error);
      }
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } =
    formik;

  const _handleImapSubmit = async (values: any) => {
    let res = null;

    if (isEdit) {
      // patch api
      res = await patchApi({
        url: `${ImapAccountsApi.base}/${currentImapAccount._id}`,
        values: { ...values, secure: Boolean(values.secure) || false },
        showToast: true,
      });
    } else {
      res = await postApi({ url: ImapAccountsApi.base, values, showToast: true });
    }

    if (!res || res.error) throw new Error(res.message);
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item md={8} sm={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Label"
                    {...getFieldProps('label')}
                    error={Boolean(touched.label && errors.label)}
                    helperText={touched.label && errors.label}
                  />

                  <TextField
                    fullWidth
                    label="Description"
                    {...getFieldProps('description')}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="User"
                    {...getFieldProps('username')}
                    error={Boolean(touched.username && errors.username)}
                    helperText={touched.username && errors.username}
                  />

                  <TextField
                    fullWidth
                    label="Password"
                    {...getFieldProps('password')}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />

                  <TextField size="small" value={false} select fullWidth label="Synced">
                    <MenuItem value="true" sx={{ height: 30 }}>
                      False
                    </MenuItem>
                  </TextField>

                  {/* <TextField name="synced" fullWidth label="Synced" value={false} hidden={true} /> */}
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Server"
                    {...getFieldProps('host')}
                    error={Boolean(touched.host && errors.host)}
                    helperText={touched.host && errors.host}
                  />

                  <TextField
                    fullWidth
                    type="number"
                    label="Port"
                    {...getFieldProps('port')}
                    error={Boolean(touched.port && errors.port)}
                    helperText={touched.port && errors.port}
                  />
                </Stack>

                <Divider />
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    {!isEdit ? 'Create IMAP Account' : 'Save Changes'}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default ImapAccountsForm;
