import { useContext } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import { BookmarksApi } from 'src/common/apis';
import { IBookmarkParams } from 'src/common/interfaces';
import { bookmarkReducer } from 'src/redux/slices';

// ----------------------------------------------------------------------

const useIndividualCampaign = () => {
  const dispatch = useDispatch();
  const individualCampaign = useSelector((state) => state.individualCampaign);

  return { dispatch, individualCampaign };
};

export default useIndividualCampaign;
