import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// slices
import {
  bookmarkReducer,
  chatReducer,
  mailReducer,
  calendarReducer,
  kanbanReducer,
  productReducer,
  tableFilterReducer,
  individualCampaignReducer,
} from './slices';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const tableFilterPersistConfig = {
  key: 'tableFilter',
  storage,
  keyPrefix: 'redux-',
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  bookmark: bookmarkReducer,
  tableFilter: persistReducer(tableFilterPersistConfig, tableFilterReducer),
  individualCampaign: individualCampaignReducer,
});

export { rootPersistConfig, rootReducer };
