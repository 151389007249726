import { IBookmark } from 'src/common/interfaces';
import { Stack } from '@mui/material';
import { sentenceCase } from 'change-case';
import { fDate } from 'src/utils/formatTime';
import BookmarkItem from './BookmarkItem';

interface IProps {
  bookmark: IBookmark | null;
}

export default function BookmarkInfoCard({ bookmark }: IProps) {
  return (
    <Stack>
      {bookmark && (
        <>
          <BookmarkItem title="Title" value={bookmark.title} />

          <BookmarkItem title="Description" value={bookmark.description} />

          <BookmarkItem
            title="Type"
            value={sentenceCase(bookmark.type)}
            chip={true}
            chipColor="primary"
          />

          <BookmarkItem
            title="Created At"
            value={bookmark?.createdAt ? fDate(bookmark?.createdAt) : 'N/A'}
            chip={true}
          />

          <BookmarkItem
            title="Updated At"
            value={bookmark?.updatedAt ? fDate(bookmark?.updatedAt) : 'N/A'}
            chip={true}
          />
        </>
      )}
    </Stack>
  );
}
