import { Card, Grid, TextField, Box, Stack, MenuItem, Typography } from '@mui/material';
import { ETableFilter } from 'src/common/enums';
import { useTableFilter } from 'src/hooks';

const LinkedInDataTableFilter = () => {
  const { tableFilter, onParamChange } = useTableFilter();

  const onChange = (e: any) => {
    onParamChange(ETableFilter.LINKEDIN, e);
  };

  const getFieldProps = (name: string) => {
    return { name, onChange };
  };

  return (
    <Box sx={{ px: 2, pb: 2 }}>
      <Card variant="outlined" sx={{ p: 2 }}>
        <Grid container spacing={3}>
          <Grid item md={12} lg={12}>
            <Typography sx={{ mb: 1 }}>Filter LinkedIn Data</Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <TextField
                size="small"
                fullWidth
                label="Name"
                {...getFieldProps('name')}
                value={tableFilter.linkedin.params.name || ''}
              />
              <TextField
                size="small"
                fullWidth
                label="Email"
                {...getFieldProps('email')}
                value={tableFilter.linkedin.params.email || ''}
              />

              <TextField
                size="small"
                fullWidth
                label="Contact"
                {...getFieldProps('phone')}
                value={tableFilter.linkedin.params.phone || ''}
              />
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default LinkedInDataTableFilter;
