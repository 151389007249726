import * as Yup from 'yup';
import { Button, Box, Divider, Typography, Chip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { EmailTemplatesApi, NotesApi, deleteApi, postFormData } from 'src/common/apis';
import { IEmailTemplate, IEmailTemplateAttachment, INotes } from 'src/common/interfaces';
import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

// form
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

// components
import { FormProvider, RHFUploadMultiFile } from 'src/components/hook-form';
import { FilePreviewCard } from 'src/components';

const callback = () => {};

interface IProps {
  currentNote: INotes;
  onClose: any;
  setNewNotes: Dispatch<SetStateAction<any>>;
}

interface FormValuesProps extends Partial<INotes> {
  attachments: any[];
}

const NotesMediaForm = ({ currentNote, onClose = callback, setNewNotes }: IProps) => {
  const navigate = useNavigate();
  console.log('currentNote', currentNote);
  console.log('setNewNotes', setNewNotes);

  const AttachmentSchema = Yup.object().shape({
    attachments: Yup.array().min(1, 'Images is required'),
  });

  const defaultValues = useMemo(
    () => ({
      attachments: [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentNote]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(AttachmentSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      // Create a FormData object and append the files
      const formData = new FormData();

      // Append each file to the 'files' key in the FormData
      data.attachments.forEach((file) => {
        formData.append('files', file, file?.name);
      });

      // patch api
      const res = await postFormData({
        url: `${NotesApi.base}/${currentNote._id}/uploads`,
        values: formData,
        showToast: true,
      });

      if (!res || res.error) return;

      setNewNotes({
        ...currentNote,
        attachments: [...currentNote.attachments, ...res.data.attachments],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const images = values.attachments || [];

      setValue('attachments', [
        ...images,
        ...acceptedFiles.map((file: Blob | MediaSource) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
    [setValue, values.attachments]
  );

  const handleRemoveAll = () => {
    setValue('attachments', []);
  };

  const handleRemove = (file: File | string) => {
    const filteredItems = values.attachments?.filter((_file: any) => _file !== file);
    setValue('attachments', filteredItems);
  };

  const deleteAttachment = async (_id: string, filename: string) => {
    if (_id === '' || filename === '') return;

    const res = await deleteApi({
      url: `${NotesApi.attachment}/${_id}/${filename}`,
      showToast: true,
    });

    if (!res || res.error) return;

    // filter data from currentNote state
    setNewNotes({
      ...currentNote,
      attachments: currentNote.attachments.filter((attachment) => attachment.filename !== filename),
    });
  };

  return (
    <Box>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <RHFUploadMultiFile
          showPreview
          name="attachments"
          accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.png,.jpg,.jpeg,.csv,.txt"
          maxSize={3145728}
          onDrop={handleDrop}
          onRemove={handleRemove}
          onRemoveAll={handleRemoveAll}
          onUpload={() => console.log('ON UPLOAD')}
          settings={{ showButtons: false }}
        />

        <Box sx={{ py: 2 }}>
          <Typography>Uploaded Files: </Typography>
          <Box sx={{ py: 2, display: 'flex', gap: 5, flexWrap: 'wrap' }}>
            {currentNote.attachments.map((attachment: IEmailTemplateAttachment) => (
              <FilePreviewCard
                url={`${NotesApi.attachment}/${currentNote._id}/${attachment.filename}`}
                _id={currentNote._id || ''}
                mimeType={attachment.mimeType}
                name={attachment.originalname}
                filename={attachment.filename}
                onDelete={() => deleteAttachment(currentNote._id || '', attachment.filename)}
                width={230}
              />
            ))}
          </Box>
        </Box>

        <Divider />

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
            Close
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Upload Documents
          </LoadingButton>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default NotesMediaForm;
