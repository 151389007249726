import jwtDecode from 'jwt-decode';
// routes
import { PATH_AUTH } from '../routes/paths';
//
// import axios from './axios';
import axiosInstance from './axiosInstance';
import { ELocalStorageKeys } from 'src/common/enums';

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp: number) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');

    localStorage.removeItem(ELocalStorageKeys.ACCESS_TOKEN);

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

const setSession = (accessToken: string | null, user: any) => {
  if (user) {
    localStorage.setItem(ELocalStorageKeys.USER, JSON.stringify(user));
  } else {
    localStorage.removeItem(ELocalStorageKeys.USER);
  }

  if (accessToken) {
    localStorage.setItem(ELocalStorageKeys.ACCESS_TOKEN, accessToken);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode<{ exp: number }>(accessToken); // ~5 days by minimals server
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem(ELocalStorageKeys.ACCESS_TOKEN);
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

const setLocalUser = (user: any) => {
  if (user) {
    localStorage.setItem(ELocalStorageKeys.USER, JSON.stringify(user));
  }
};

const getSession = (): { user: any; accessToken: string | null } => {
  let user = localStorage.getItem(ELocalStorageKeys.USER);
  const accessToken = localStorage.getItem(ELocalStorageKeys.ACCESS_TOKEN);

  if (user) {
    user = JSON.parse(user);
  }

  return { user, accessToken };
};

const generateAuthUserData = (user: any) => {
  return {
    displayName: user.firstName,
    email: user.username,
    photoURL: '/static/mock-images/avatars/avatar_default.jpg',
    phoneNumber: '+40 777666555',
    country: 'India',
    address: 'Vibhuti Khand, Lucknow',
    state: 'Uttar Pradesh',
    city: 'Lucknow',
    zipCode: '226016',
    about: 'ByteScrum Technologies Pvt. Ltd.',
    role: user?.roles[0] || 'admin',
    ...user,
  };
};

export { isValidToken, setSession, getSession, setLocalUser, generateAuthUserData };
