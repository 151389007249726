import { Card, Grid, TextField, Box, Stack, MenuItem, Typography } from '@mui/material';
import {
  CLUTCH_EMPLOYEES_COUNT_FILTERS,
  CLUTCH_HOURLY_RATE_FILTERS,
  RATING_FILTERS,
} from 'src/common/constants';
import { ETableFilter } from 'src/common/enums';
import { useTableFilter } from 'src/hooks';

const ClutchTableFilter = () => {
  const { tableFilter, onParamChange } = useTableFilter();

  const onChange = (e: any) => {
    onParamChange(ETableFilter.CLUTCH, e);
  };

  const getFieldProps = (name: string) => {
    return { name, onChange };
  };

  return (
    <Box sx={{ px: 2, pb: 2 }}>
      <Card variant="outlined" sx={{ p: 2 }}>
        <Grid container spacing={3}>
          <Grid item md={12} lg={12}>
            <Typography sx={{ mb: 1 }}>Filter Clutch Data</Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <TextField
                size="small"
                fullWidth
                label="Company name"
                {...getFieldProps('company_name')}
                value={tableFilter.clutch.params.company_name || ''}
              />
              <TextField
                size="small"
                fullWidth
                label="Location"
                {...getFieldProps('location')}
                value={tableFilter.clutch.params.location || ''}
              />

              {/**Rating */}
              <TextField
                select
                size="small"
                fullWidth
                label="Rating"
                {...getFieldProps('rating')}
                value={tableFilter.clutch.params.rating || ''}
              >
                <MenuItem value="" sx={{ height: 30 }}></MenuItem>
                {RATING_FILTERS.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>

              {/**Employees */}
              <TextField
                size="small"
                select
                fullWidth
                label="Employees"
                {...getFieldProps('employees')}
                value={tableFilter.clutch.params.employees || ''}
              >
                <MenuItem value="" sx={{ height: 30 }}></MenuItem>
                {CLUTCH_EMPLOYEES_COUNT_FILTERS.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>

              {/**Hourly Rate */}
              <TextField
                size="small"
                select
                fullWidth
                label="Hourly Rate"
                {...getFieldProps('avg_hourly_rate')}
                value={tableFilter.clutch.params.avg_hourly_rate || ''}
              >
                <MenuItem value="" sx={{ height: 30 }}></MenuItem>
                {CLUTCH_HOURLY_RATE_FILTERS.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default ClutchTableFilter;
