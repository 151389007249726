// @mui
import {
  Chip,
  Rating,
  TableCell,
  TableRow,
  Link,
  useTheme,
  Tooltip,
  IconButton,
  Box,
} from '@mui/material';
import { Label, LinkButton, Iconify } from 'src/components';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { sentenceCase } from 'change-case';
import { EContactStatus } from 'src/common/enums';
import { getContactTypeColor } from 'src/common/functions';
import { ICommonRow } from 'src/common/interfaces';
import { getSearchResultsSocialLinks } from 'src/common/functions';
import { fShortDate } from 'src/utils/formatTime';
import { ContactFormSubject } from 'src/@types/contactFroms';

interface IRemoveBtn {
  onClick: any;
}

const RemoveBtnCell = ({ onClick }: IRemoveBtn) => {
  return (
    <TableCell align="left">
      <Tooltip title="Remove member">
        <IconButton onClick={onClick}>
          <Iconify icon="clarity:remove-line" color="red" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
};

export const SearchResultRow = ({ row, index, removeMember }: ICommonRow) => {
  const theme = useTheme();
  console.log('row:', row);

  return (
    <TableRow hover selected={row?.selected} key={row._id}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <LinkButton to={`${PATH_DASHBOARD.searchResults.root}/${row?._id}/view`}>
          {sentenceCase(row.title)}
        </LinkButton>
      </TableCell>

      <TableCell sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
        <Link href={row?.url} target="_blank">
          {row?.url?.length > 15 ? `${row?.url?.slice(0, 15)}...` : row?.url || 'N/A'}
        </Link>
      </TableCell>

      <TableCell>{row?.phone?.length > 0 ? row?.phone?.join(', ') : 'N/A'}</TableCell>

      <TableCell>{row?.email || 'N/A'}</TableCell>

      <TableCell sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {row?.socialLinks?.length > 0
          ? getSearchResultsSocialLinks(row?.socialLinks).map((link, index) => (
              <span>
                <Link href={link.full} target="_blank">
                  {link.short}
                </Link>
                {index !== row?.socialLinks.length - 1 && <br />}
              </span>
            ))
          : 'N/A'}
      </TableCell>

      <TableCell>
        {row?.country?.countries?.length > 0 ? row.country.countries.join(', ') : 'N/A'}
      </TableCell>

      <TableCell>
        <Rating name="rating" value={row.rating} readOnly size="small" />
      </TableCell>

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={row?.emailVerified ? 'success' : 'error'}
          sx={{ textTransform: 'capitalize' }}
        >
          {row?.emailVerified ? row?.emailVerified.toString() : 'N/A'}
        </Label>
      </TableCell>

      <RemoveBtnCell onClick={() => removeMember(row._id)} />
    </TableRow>
  );
};

export const ContactsRow = ({ row, index, removeMember }: ICommonRow) => {
  return (
    <TableRow hover selected={row?.selected} key={row._id}>
      <TableCell>{index + 1}</TableCell>

      <TableCell>
        <LinkButton to={`${PATH_DASHBOARD.contacts.root}/${row?._id}/view`}>
          {sentenceCase(row.firstName)}
        </LinkButton>
      </TableCell>

      <TableCell>{sentenceCase(row.lastName)}</TableCell>

      <TableCell>{row.age}</TableCell>

      <TableCell>{sentenceCase(row.gender)}</TableCell>

      <TableCell>{row?.emails[0]?.email || 'N/A'}</TableCell>

      <TableCell>{row?.phoneNumbers[0]?.phoneNumber || 'N/A'}</TableCell>

      <TableCell>
        <Chip
          label={sentenceCase(row.status)}
          color={row.status === EContactStatus.OPEN ? 'primary' : 'error'}
          size="small"
        />
      </TableCell>

      <TableCell>
        <Chip label={sentenceCase(row.type)} color={getContactTypeColor(row.type)} size="small" />
      </TableCell>

      <TableCell>
        <Chip label={sentenceCase(row.jobFrequency || 'N/A')} size="small" />
      </TableCell>

      <TableCell>{row.expectedBillingAmount} $</TableCell>

      <TableCell>
        <Rating name="rating" value={row.rating} readOnly size="small" />
      </TableCell>

      <RemoveBtnCell onClick={() => removeMember(row._id)} />
    </TableRow>
  );
};

export const LinkedinDataRow = ({ row, index, removeMember }: ICommonRow) => {
  return (
    <TableRow hover selected={row?.selected} key={row._id}>
      <TableCell>{index + 1}</TableCell>

      <TableCell> {row?.name ? sentenceCase(row?.name) : 'N/A'}</TableCell>

      <TableCell>{row?.email?.length > 0 ? row.email.join(', ') : 'N/A'}</TableCell>

      <TableCell>{row?.phone?.length > 0 ? row.phone.join(', ') : 'N/A'}</TableCell>

      <TableCell sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
        <Link href={row?.url} target="_blank">
          {row?.url?.length > 15 ? `${row?.url?.slice(0, 15)}...` : row?.url || 'N/A'}
        </Link>
      </TableCell>

      <TableCell>{row?.about || 'N/A'}</TableCell>

      <RemoveBtnCell onClick={() => removeMember(row._id)} />
    </TableRow>
  );
};

export const ClutchRow = ({ row, index, removeMember }: ICommonRow) => {
  return (
    <TableRow hover selected={row?.selected} key={row._id}>
      <TableCell>{index + 1}</TableCell>

      <TableCell>
        <LinkButton to={`${PATH_DASHBOARD.clutch.root}/${row?._id}/view`}>
          {row.company_name ? sentenceCase(row.company_name) : 'N/A'}
        </LinkButton>
      </TableCell>

      <TableCell>{row.designation}</TableCell>

      <TableCell>
        <Box sx={{ textAlign: 'center' }}>
          {row.rating}
          <Rating
            name="rating"
            value={row.rating ? parseInt(row.rating) : 0}
            readOnly
            size="small"
            precision={0.5}
          />
        </Box>
      </TableCell>

      <TableCell>
        <Chip label={row.minimum_budget} size="small" />
      </TableCell>

      <TableCell>
        <Chip label={row.avg_hourly_rate} size="small" />
      </TableCell>

      <TableCell>
        <Chip label={row.employees} size="small" />
      </TableCell>

      <TableCell>{row.location}</TableCell>

      <TableCell>
        <Link href={row.scrap_url} target="_blank">
          {row.scrap_url}
        </Link>
      </TableCell>

      <TableCell>
        <Link href={row.clutch_profile_url} target="_blank">
          {row.clutch_profile_url}
        </Link>
      </TableCell>

      <TableCell>{row?.services?.length ? row.services.join(', ') : 'N/A'}</TableCell>

      <TableCell>
        <Link href={row.main_url} target="_blank">
          {row.main_url}
        </Link>
      </TableCell>

      <TableCell>{row.description}</TableCell>

      <TableCell>{row.createdAt ? fShortDate(row.createdAt) : 'N/A'}</TableCell>

      <TableCell>{row.updatedAt ? fShortDate(row.updatedAt) : 'N/A'}</TableCell>

      <RemoveBtnCell onClick={() => removeMember(row._id)} />
    </TableRow>
  );
};

export const TechbehemothRow = ({ row, index, removeMember }: ICommonRow) => {
  return (
    <TableRow hover selected={row?.selected} key={row._id}>
      <TableCell>{index + 1}</TableCell>

      <TableCell>
        <LinkButton to={`${PATH_DASHBOARD.techbehemoth.root}/${row?._id}/view`}>
          {row.company_name ? sentenceCase(row.company_name) : 'N/A'}
        </LinkButton>
      </TableCell>

      <TableCell>
        <Chip label={row.hourly_rate} size="small" />
      </TableCell>

      <TableCell>{row?.location?.length ? row.location.join(', ') : 'N/A'}</TableCell>

      <TableCell>
        <Chip label={row.team_size} size="small" />
      </TableCell>

      <TableCell>{row?.services?.length ? row.services.join(', ') : 'N/A'}</TableCell>

      <TableCell>{row?.type?.length ? row.type.join(', ') : 'N/A'}</TableCell>

      <TableCell>{row.main_phone_number}</TableCell>

      <TableCell>
        <Link href={row.local_url} target="_blank">
          {row.local_url}
        </Link>
      </TableCell>

      <TableCell>
        <Link href={row.url} target="_blank">
          {row.url}
        </Link>
      </TableCell>

      <TableCell>{row.description}</TableCell>

      <TableCell>{row.createdAt ? fShortDate(row.createdAt) : 'N/A'}</TableCell>

      <TableCell>{row.updatedAt ? fShortDate(row.updatedAt) : 'N/A'}</TableCell>

      <RemoveBtnCell onClick={() => removeMember(row._id)} />
    </TableRow>
  );
};

export const CareerRow = ({ row, index, removeMember }: ICommonRow) => {
  return (
    <TableRow hover selected={row?.selected} key={row._id}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <LinkButton to={`${PATH_DASHBOARD.searchResults.root}/${row?._id}/view`}>
          {sentenceCase(row?.job_title)}
        </LinkButton>
      </TableCell>
      <TableCell>{row?.company_name}</TableCell>
      <TableCell>{row?.job_type}</TableCell>

      <TableCell>{row?.salary || 'n/a'}</TableCell>
      <TableCell>
        <Link href={row?.job_link} target="_blank">
          {row?.job_link ? `${row?.job_link.slice(0, 10)}...` : 'n/a'}
        </Link>
      </TableCell>

      <TableCell>{row?.job_description || 'n/a'}</TableCell>

      <TableCell>
        <Link href={row?.original_url} target="_blank">
          {row?.original_url ? `${row?.original_url.slice(0, 10)}...` : 'n/a'}
        </Link>
      </TableCell>

      <TableCell>{row?.company_location || 'n/a'}</TableCell>
      <TableCell>{row?.rating || 'n/a'}</TableCell>

      <RemoveBtnCell onClick={() => removeMember(row._id)} />
    </TableRow>
  );
};

export const ContactFormRow = ({ row, index, removeMember }: ICommonRow) => {
  return (
    <TableRow hover selected={row?.selected} key={row._id}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>{row?.firstName ? sentenceCase(row?.firstName) : 'N/A'}</TableCell>
      <TableCell>{row?.lastName ? sentenceCase(row.lastName) : 'N/A'}</TableCell>
      <TableCell>
        <Link href={`mailto:${row?.email}`} target="_blank">
          {row.email}
        </Link>
      </TableCell>
      <TableCell>{row?.phoneNumber}</TableCell>

      <TableCell>
        {row?.subject?.map((type: ContactFormSubject) => (
          <Chip variant="outlined" label={type.title} />
        ))}
      </TableCell>

      <TableCell>
        {row.formTypes.map((formType: string) => (
          <Chip
            variant="outlined"
            label={formType}
            sx={{ marginBottom: row.formTypes.length > 1 ? 1 : 0 }}
          />
        ))}
      </TableCell>
      <TableCell>
        {row?.createdAt ? new Intl.DateTimeFormat('en-GB').format(new Date(row.createdAt)) : 'N/A'}
      </TableCell>

      <TableCell>{row?.dob ? fShortDate(new Date(row?.dob)) : 'N/A'}</TableCell>

      <TableCell>{row?.city}</TableCell>

      <TableCell>{row?.position}</TableCell>

      <TableCell>{row?.workStatus}</TableCell>

      <TableCell>{row?.lastWorkingCompany}</TableCell>

      <TableCell>{row?.yearsOfExperience} Years</TableCell>

      <TableCell>{row?.currentCTC} </TableCell>

      <TableCell>{row?.expectedSalary} </TableCell>

      <TableCell>{row?.hearAboutPosition} </TableCell>

      <TableCell>
        <Link href={row?.resume} target="_blank">
          {row?.resume}
        </Link>
      </TableCell>
      <RemoveBtnCell onClick={() => removeMember(row._id)} />
    </TableRow>
  );
};
