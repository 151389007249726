import * as Yup from 'yup';

import { ICampaignGroup } from '../interfaces';
import { ECampaignGroupStatus, ECampaignGroupTypes, ECampaignStatus } from '../enums';
import moment from 'moment-timezone';

export class CreateCampaignGroupSchema {
  schema;

  initialValues: ICampaignGroup;

  updateValues: ICampaignGroup;

  constructor() {
    this.schema = Yup.object({
      name: Yup.string().required('Campaign group name is required'),
      description: Yup.string().required('Campaign group description is required'),
      type: Yup.string().required('Type is required'),
      status: Yup.string().required('Status is required'),
      campaign: Yup.string().required('Campaign is required'),
    });

    this.initialValues = {
      name: '',
      description: '',
      campaign: '',
      type: ECampaignGroupTypes.SEARCH_RESULTS,
      status: ECampaignGroupStatus.INACTIVE,
      contacts: [],
      searchResults: [],
      contactForms: [],
      linkedInData: [],
      members: [],
      scheduleDate: '',
      timezone: moment.tz.guess(),
    };

    this.updateValues = {
      ...this.initialValues,
      _id: '',
    };
  }
}
