import { useEffect, useState } from 'react';
import { Card, CardActions, CardContent, CardMedia, Button, Typography } from '@mui/material';
import { axiosInstance } from 'src/utils';

interface IProps {
  url: string;
  mimeType: string;
  _id: string;
  name: string;
  filename: string;
  onDelete: any;
  width?: number;
}

export default function FilePreviewCard({
  url,
  _id,
  mimeType,
  name,
  filename,
  onDelete,
  width = 250,
}: IProps) {
  const [imageSrc, setImageSrc] = useState('');
  const [file, setFile] = useState('');

  useEffect(() => {
    if (_id !== '') {
      // Fetch the image from the API
      axiosInstance
        .get(url, {
          responseType: 'arraybuffer',
        })
        .then((response) => {
          // Create a Blob from the received data
          const blob = new Blob([response.data], { type: mimeType }); // Update the MIME type based on the actual file type

          // Create a URL from the Blob to use as the image source
          const fileURL = URL.createObjectURL(blob);

          setFile(fileURL);

          // Set the image source in the component state
          if (mimeType.includes('image')) setImageSrc(fileURL);
          else if (mimeType.includes('pdf')) setImageSrc('/assets/preview-icons/pdf-icon.webp');
          else setImageSrc('/assets/preview-icons/office.jpg');
        })
        .catch((error) => {
          console.error('Error fetching image:', error);
        });
    }

    // Clean up the URL when the component unmounts
    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [_id, filename]);

  const handleDownload = () => {
    // Create an anchor element with the image URL and click it programmatically to trigger the download
    const downloadLink = document.createElement('a');
    downloadLink.href = file;
    downloadLink.download = filename;
    downloadLink.click();
  };

  return (
    <Card sx={{ width }}>
      <CardMedia component="img" alt="green iguana" height="180" image={imageSrc} />
      <CardContent>
        <Typography
          gutterBottom
          component="div"
          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {name}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" color="error" onClick={onDelete} variant="outlined">
          Remove
        </Button>
        <Button size="small" onClick={handleDownload} variant="contained">
          Download
        </Button>
      </CardActions>
    </Card>
  );
}
