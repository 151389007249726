import { lazy } from 'react';
import Loadable from './Loadable';
import { AuthGuard } from 'src/guards';
import { DashboardLayout } from 'src/layouts';
import { Navigate } from 'react-router';
import { PATH_AFTER_LOGIN } from 'src/config';

// MAIN
// GENERAL
const GeneralApp = Loadable(lazy(() => import('src/pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('src/pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('src/pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('src/pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('src/pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('src/pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(
  lazy(() => import('src/pages/dashboard/EcommerceProductDetails'))
);
const EcommerceProductList = Loadable(
  lazy(() => import('src/pages/dashboard/EcommerceProductList'))
);
const EcommerceProductCreate = Loadable(
  lazy(() => import('src/pages/dashboard/EcommerceProductCreate'))
);
const EcommerceCheckout = Loadable(lazy(() => import('src/pages/dashboard/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('src/pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('src/pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('src/pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('src/pages/dashboard/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('src/pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('src/pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('src/pages/dashboard/BlogNewPost')));

// OLD Static Componets USER
const UserProfile = Loadable(lazy(() => import('src/pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('src/pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('src/pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('src/pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('src/pages/dashboard/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('src/pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('src/pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('src/pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('src/pages/dashboard/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('src/pages/dashboard/PermissionDenied')));

const SampleRoutes = [
  // Dashboard Routes
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      // NOTE: Remove these
      { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
      { path: 'app', element: <GeneralApp /> },

      { path: 'ecommerce', element: <GeneralEcommerce /> },
      { path: 'analytics', element: <GeneralAnalytics /> },
      { path: 'banking', element: <GeneralBanking /> },
      { path: 'booking', element: <GeneralBooking /> },

      {
        path: 'e-commerce',
        children: [
          { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
          { path: 'app', element: <GeneralEcommerce /> },
          { path: 'shop', element: <EcommerceShop /> },
          { path: 'product/:name', element: <EcommerceProductDetails /> },
          { path: 'list', element: <EcommerceProductList /> },
          { path: 'product/new', element: <EcommerceProductCreate /> },
          { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
          { path: 'checkout', element: <EcommerceCheckout /> },
        ],
      },

      // Other paths
      {
        path: 'user',
        children: [
          { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
          { path: 'profile', element: <UserProfile /> },
          { path: 'cards', element: <UserCards /> },
          { path: 'list', element: <UserList /> },
          { path: 'new', element: <UserCreate /> },
          { path: ':name/edit', element: <UserCreate /> },
          { path: 'account', element: <UserAccount /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
          { path: 'list', element: <InvoiceList /> },
          { path: ':id', element: <InvoiceDetails /> },
          { path: ':id/edit', element: <InvoiceEdit /> },
          { path: 'new', element: <InvoiceCreate /> },
        ],
      },
      {
        path: 'blog',
        children: [
          { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
          { path: 'posts', element: <BlogPosts /> },
          { path: 'post/:title', element: <BlogPost /> },
          { path: 'new', element: <BlogNewPost /> },
        ],
      },
      {
        path: 'products',
        children: [
          { element: <Navigate to="/dashboard/products/list" replace />, index: true },
          { path: 'list', element: <InvoiceList /> },
          { path: ':id', element: <InvoiceDetails /> },
          { path: ':id/edit', element: <InvoiceEdit /> },
          { path: 'new', element: <InvoiceCreate /> },
        ],
      },
      {
        path: 'mail',
        children: [
          { element: <Navigate to="/dashboard/customMail/inbox" replace />, index: true }, //previous routes:/dashboard/mail/all
          { path: 'label/:customLabel', element: <Mail /> },
          { path: 'label/:customLabel/:mailId', element: <Mail /> },
          { path: ':systemLabel', element: <Mail /> },
          { path: ':systemLabel/:mailId', element: <Mail /> },
        ],
      },
      {
        path: 'chat',
        children: [
          { element: <Chat />, index: true },
          { path: 'new', element: <Chat /> },
          { path: ':conversationKey', element: <Chat /> },
        ],
      },
      { path: 'kanban', element: <Kanban /> },
      { path: 'permission-denied', element: <PermissionDenied /> },
      { path: 'calendar', element: <Calendar /> },
    ],
  },
];

export default SampleRoutes;
