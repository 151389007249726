import { ReactEventHandler, ReactNode } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Box,
  CardActions,
  Button,
  Link,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Iconify, LinkButton, Scrollbar } from 'src/components';
import { PATH_DASHBOARD } from 'src/routes/paths';

interface IProps {
  title: string;
  children: ReactNode;
  height?: string;
  linkText?: string;
  linkTo?: any;
}

const CardLayout = ({ title, children, height = '100%', linkTo }: IProps) => {
  return (
    <Card sx={{ height }}>
  
    <CardHeader sx={{paddingBottom: '10px' }}
      title={title}
      action={
        linkTo && (
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            component={RouterLink}
            to={linkTo}
          >
            Edit
          </Button>
        )
      }
      
    />
  
      <Divider />
      <CardContent>
        <Scrollbar>{children}</Scrollbar>
      </CardContent>
    </Card>
  );
};

export default CardLayout;
