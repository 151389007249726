// MUI
import { Box, Typography, Button } from '@mui/material';

// Components
import CustomModal from '../CustomModal';

interface IProps {
  open: boolean;
  onClose: () => void;
  onYes: any;
  children?: any;
  title?: string;
}

const AlertModal = ({ title = 'Alert', open, onClose, onYes, children }: IProps) => {
  return (
    <CustomModal
      title={title}
      open={open}
      onClose={onClose}
      maxWidth="sm"
      minHeight={100}
      dialogActions={
        <Box width="100%" display="flex" justifyContent="space-between" sx={{ px: 2 }}>
          <Button variant="outlined" onClick={onYes} color="error">
            Yes
          </Button>
          <Button variant="contained" onClick={onClose}>
            No
          </Button>
        </Box>
      }
    >
      <Box display="flex" justifyContent="center" alignItems="center" height={70}>
        {children ? (
          children
        ) : (
          <Typography color="error" variant="h5">
            Are you sure. You want to delete this contact ?
          </Typography>
        )}
      </Box>
    </CustomModal>
  );
};

export default AlertModal;
