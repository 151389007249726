import GetIcon from 'src/components/GetIcon';

const ICONS = {
  blog: GetIcon('ic_blog'),
  cart: GetIcon('ic_cart'),
  chat: GetIcon('ic_chat'),
  mail: GetIcon('ic_mail'),
  user: GetIcon('ic_user'),
  kanban: GetIcon('ic_kanban'),
  banking: GetIcon('ic_banking'),
  booking: GetIcon('ic_booking'),
  invoice: GetIcon('ic_invoice'),
  calendar: GetIcon('ic_calendar'),
  ecommerce: GetIcon('ic_ecommerce'),
  analytics: GetIcon('ic_analytics'),
  dashboard: GetIcon('ic_dashboard'),
  menuItem: GetIcon('ic_menu_item'),
  stop: GetIcon('icons8-stop'),
  search: GetIcon('search-sort'),
  contacts: GetIcon('contacts'),
  projects: GetIcon('project'),
  credentials: GetIcon('credentials'),
  campaign: GetIcon('campaign'),
  campaignGroups: GetIcon('campaign-groups'),
  technologies: GetIcon('technology'),
  link: GetIcon('link'),
  query: GetIcon('query'),
  industry: GetIcon('industry'),
  settings: GetIcon('settings'),
  offer: GetIcon('offer'),
  emails: GetIcon('emails'),
  objectTypes: GetIcon('object-types'),
  users: GetIcon('users'),
  clutch: GetIcon('clutch'),
  techbehemoth: GetIcon('techbehemoth'),
  bookmarks: GetIcon('bookmarks'),
  smtpAccounts: GetIcon('smtp-accounts'),
  imapAccounts: GetIcon('imap-accounts'),
  reminders: GetIcon('reminder'),
  notes: GetIcon('notes'),
  compaignABTesting: GetIcon('compaign-ab_testing'),
};

export default ICONS;
