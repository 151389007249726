import { ECampaignGroupTypes } from '../enums';

export const getCampaignGroupKey = (type: ECampaignGroupTypes) => {
  switch (type) {
    case ECampaignGroupTypes.CONTACTS:
      return 'contacts';
    case ECampaignGroupTypes.CONTACT_FORMS:
      return 'contactForms';
    case ECampaignGroupTypes.SEARCH_RESULTS:
      return 'searchResults';
    case ECampaignGroupTypes.LINKEDIN:
      return 'linkedInData';
    default:
      return 'contacts';
  }
};
